<div class="h-full flex flex-col justify-between">
  <drdp-filter-select
    #genderSelect
    [label]="label"
    [multiSelect]="multiSelect"
    [options]="genderOptions"
    [selectType]="select.Gender"
    [required]="required"
    [isValid]="isValid"
    (onSelected)="onGenderSelect($event)"
    [initialValues]="initialGenderId || initialGenderIds"
  >
  </drdp-filter-select>
</div>

