import { Component, OnInit, ViewChild } from '@angular/core';
import { Permission } from '@core/enums/permissions';
import {
  IMenuLinks,
  IDropMenu,
  IEvent,
  IMenuItem,
} from '@core/interfaces/side-menu';
import { AuthService } from '@core/services/auth.service';
import { SideMenuService } from '@core/services/sidemenu.service';
import { UserDropMenuService } from '@core/services/user-dropmenu.service';
import { Subscription, take } from 'rxjs';


@Component({
  selector: 'drdp-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  isAdmin = true;
  showSideMenu = false;
  menuItems: IMenuItem[] = [];
  menuLinks: IMenuLinks[] = [
    {
      label: '',
      routeLink: '',
      icon: '',
    },
  ];

  public get Permission() {
    return Permission;
  }


  user = this.authService.getCurrentUser();


  selectedItem?: number;
  showDrop: boolean = false;
  // @ViewChild('dropMenu') showValue!:DropMenuComponent

  private subscriptions = new Subscription();

  constructor(
    public sideMenuService: SideMenuService,
    private userDropMenuService: UserDropMenuService,
    public authService: AuthService,

  ) {}

  headerBtns = this.userDropMenuService.headerBtns;
  dropMenuItems = this.user.sites;


  userDropMenu: IDropMenu = {
    label: this.user.userName,
    labelTwo: this.user.agencyName,

  };

  ngOnInit(): void {
    this.subscriptions.add(
      this.sideMenuService.getFormatted().subscribe((res: IMenuItem[]) => {
        if (res && res.length > 0) {
          this.menuItems = res;
        }
      })
    );
  }

  checkSelectedItem(eventValues: IEvent) {
    this.selectedItem = eventValues.indexValue;
    this.showDrop = eventValues.showValue;
  }

  toggle(i: number) {
    if (i == this.selectedItem) {
      if (this.showDrop) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  showMenu() {
    this.showSideMenu = !this.showSideMenu;
  }

  logout(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser) this.authService.logout().pipe(take(1)).subscribe();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
