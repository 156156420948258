<div>
    <div class="flex justify-between border-b mb-3">
      <h1 class="text-[25px] font-bold mr-12">Edit Lock Rating View</h1>
      <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
    </div>
  
    <div mat-dialog-content>
        <form class="grid sm:grid-cols-2 gap-x-4" [formGroup]="lockingStatusForm">
            <div class="items-center w-[100%] pl-3">
              <drdp-date-picker label="Lock Date: " 
                            [initialDate]="lockingStatusForm.get('lockDate').value"
                            (emitDate)="handleDate($event)">
              </drdp-date-picker>
            </div>
            <div class="md:pl-8 pl-3">
                <label class="font-normal" for="active">Active: </label>
                <div class="pt-6">
                  <mat-checkbox [color]="'primary'" class="example-margin font-normal" formControlName="active">
                  </mat-checkbox>
                </div>
            </div>
          </form>
    </div>  
    <div mat-dialog-actions class="flex justify-end bottom-0">
      <button mat-button mat-dialog-close>Cancel</button>
      <drdp-btn *ngIf="lockingStatusForm.valid" mat-button [mat-dialog-close]="onSubmit()" [disabled]="false" 
        cdkFocusInitial>
            Save
      </drdp-btn>
      <drdp-btn *ngIf="!lockingStatusForm.valid" mat-button [disabled]="true" cdkFocusInitial>Save</drdp-btn> 
    </div>
</div>
