import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'drdp-header-logo-and-image',
  templateUrl: './header-logo-and-image.component.html',
  styleUrls: ['./header-logo-and-image.component.scss']
})
export class HeaderLogoAndImageComponent implements OnInit {
  @Input() logoSrc?: any;
  @Input() ImgSrc?: any;
  @Input() logoAlt?: any;
  @Input() ImgAlt?: any;

  constructor() { }

  ngOnInit(): void {
  }

}
