import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Permission } from '@core/enums/permissions';
import { SelectType } from '@core/enums/select';
import { IState } from '@core/interfaces/istate';
import { AuthService } from '@core/services/auth.service';
import { PermissionService } from '@core/services/permission.service';
import { StateService } from '@core/services/state.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';

@Component({
  selector: 'drdp-select-state',
  templateUrl: './select-state.component.html',
  styleUrls: ['./select-state.component.scss']
})
export class SelectStateComponent implements OnInit, OnChanges {
  @ViewChild('stateSelect') stateSelect: FilterSelectComponent | undefined;
  @Output() state = new EventEmitter<any>();
  @Input() initialStateId?: number | null;
  @Input() clear: any;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() required: boolean = false;
  stateOptions?: IState[];
  selectedState?: number;
  hasAccessToAll = this.permissionService.checkPermission(Permission.AccessAllAgencies);
  public get select() { return SelectType }
  constructor(private stateService: StateService, private authService: AuthService, private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.getStateOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear && !this.disabled) {
      this.stateSelect?.clear();
    }
    this.getStateOptions();
  }

  clearSelected() {
    if (!this.disabled)
      this.stateSelect?.clearSelected();
  }

  getActiveStates(): void {
    this.stateService.getAllStates()
    .pipe(take(1))
    .subscribe((states: IState[]) => {
      this.stateOptions = states.filter((s) => s.isSelectable);
      this.disabled = this.stateOptions.length <= 1 || this.disabled;
    });
  }

  getLinkedStates(userId: number): void {
    this.stateService.getLinkedStates(userId)
      .pipe(take(1))
      .subscribe((states: IState[]) => {
        this.stateOptions = states.filter((s) => s.isSelectable);
        this.disabled = this.stateOptions.length <= 1 || this.disabled;
      });
  }

  getStateOptions(): void {
    if (!this.hasAccessToAll) {
      const user = this.authService.getCurrentUser();
      this.getLinkedStates(user.userId);
    } else {
      this.getActiveStates();
    }
  }

  onStateSelect(event: IState): void {
    this.state.emit(event);
  }

}
