<div class="h-full flex flex-col justify-between">
  <drdp-filter-select 
      #classroomSelect
      [label]="label"
      [multiSelect]="multiSelect" 
      [options]="classroomOptions" 
      [selectType]="select.Class"
      [required]="required" 
      [disabled]="disabled"
      [isValid]="isValid"
      (onSelected)="onClassroomSelect($event)"
      [initialValues]="initialClassroomIds">
  </drdp-filter-select>
</div>
