import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { ISchoolYear } from '@core/interfaces/iratingperiod';
import { LookupService } from '@core/services/lookup.service';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'drdp-select-school-year',
  templateUrl: './select-school-year.component.html',
  styleUrls: ['./select-school-year.component.scss']
})
export class SelectSchoolYearComponent implements OnInit {
  @ViewChild('schoolYearSelect') schoolYearSelect: FilterSelectComponent | undefined;
  @Output() schoolYear = new EventEmitter<any>();
  @Input() initialYearId?: number | null;
  @Input() clear: any;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() required: boolean = false;
  @Input() currentSchoolYear: boolean = false;
  @Input() startingSchoolYear?: string | null;
  @Input() editConfigs: boolean = false;
  schoolYearOptions?: ISchoolYear[];

  public get select() {return SelectType }
  constructor(private lookupService: LookupService, private _ratingPeriods: RatingPeriodService,) { }

  ngOnInit(): void {
    if (!this.currentSchoolYear) this.getActiveStates();
    this.getCurrentSchoolYear();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      this.schoolYearSelect?.clear();
    }
  }

  clearSelected() {
    if (!this.disabled)
      this.schoolYearSelect?.clearSelected();
  }

  getCurrentSchoolYear() {
      this._ratingPeriods.getActiveSchoolYear().pipe(take(1)).subscribe((res) => {
        if (this.currentSchoolYear) this.schoolYearOptions = [res];
        if (!this.editConfigs) {
          this.initialYearId = res.id;
          this.schoolYear.emit(res);
        } 
      })
  }

  getActiveStates(): void {
    if (this.startingSchoolYear) {
      this.lookupService.getSchoolYearFromStartingYear(this.startingSchoolYear)
        .pipe(take(1))
        .subscribe((years: ISchoolYear[]) => {
          this.schoolYearOptions = years;
        })
    } else {
      this.lookupService.getAllSchoolYears()
        .pipe(take(1))
        .subscribe((years: ISchoolYear[]) => {
          this.schoolYearOptions = years;
        });
    }
  }

  onYearSelect(event: ISchoolYear): void {
    this.schoolYear.emit(event);
  }
}
