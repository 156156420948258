import { Injectable } from '@angular/core';
import {
  IClassroom,
  IClassroomAssignment,
  IClassroomSearchRequest,
  IClassSearchRequest,
} from '@core/interfaces/iclasses';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { getSearchUrl } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class ClassroomService {
  constructor(private dataService: DataService) {}

  getClassroomById(classroomId?: number, message?: string): Observable<any> {
    return this.dataService.get(`classroom/${classroomId}`, message);
  }

  checkClassroomHasEnolledStudents(classroomId: number): Observable<boolean> {
    return this.dataService.get(`classroom/check-classroom-has-enrolled-students/${classroomId}`);
  }

  getClassroomsByAgency(
    agencyId: number,
    message?: string
  ): Observable<IClassroom[]> {
    return this.dataService.get(`classroom/agency/${agencyId}`, message);
  }

  getClassroomsBySite(siteId: number, message?: string): Observable<any> {
    return this.dataService.get(`classroom/site/${siteId}`);
  }

  search(params: IClassroomSearchRequest, message?: string): Observable<any> {
    return this.dataService.post('classroom/search', params, message);
  }

  getClassroomByUserAccess(params: IClassroomSearchRequest, message?: string): Observable<any> {
    return this.dataService.post('classroom/user-access', params, message);
  }

  deleteClassroom(id: number, message?: string | any) {
    return this.dataService.delete(`classroom?id=${id}`, message);
  }

  saveClassroom(payload: IClassroom, message?: string) {
    return this.dataService.post(`classroom`, payload, message);
  }

  getClassroomsBySites(payload: IClassSearchRequest, message?: string): Observable<IClassroomAssignment[]> {
    return this.dataService.post(`classroom/search/sites`, payload, message);
  }
}
