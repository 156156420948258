import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";

@Component({
    selector: 'drdp-upload-table',
    templateUrl: './upload-table.component.html',
    styleUrls: ['./upload-table.component.scss']
  })
  export class UploadTableComponent implements OnChanges, OnInit, AfterViewInit {
    @ViewChildren("input") private input!: QueryList<ElementRef>;
    @Input() dataSource!: MatTableDataSource<any>;
    @Input() columns?: any;
    @Output() onDataChange = new EventEmitter();
    displayedColumns: string[] = []
    subscription!: Subscription 
    constructor() { }
  
    ngOnInit(): void {

    } 

    ngAfterViewInit(): void {
        // Subscribe to the changes of the input elements and focus them
        this.subscription = this.input.changes.subscribe(_ => this.focusTaskInput());
    }
    
    focusTaskInput() {
      if (this.input.length > 0) {
        this.input.first.nativeElement.focus();
      }
    }

    ngOnChanges(): void {
        if (this.columns)
            this.displayedColumns = this.columns.map((x: { columnDef: string; }) => x.columnDef);       
    }

    setEditableCell(index: number,row: any): void {
        row.cellValues[index].isEditable = !row.cellValues[index].isEditable;
        this.onDataChange.emit(null);
    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
    }
}
