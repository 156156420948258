import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DrdpLayoutComponent } from '../drdp-layout/drdp-layout.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { PermissionGuard } from '@core/guards/permission.guard';
import { Permission } from '@core/enums/permissions';
import { DownloadDemographicsComponent } from './download-demographics/download-demographics.component';
import { DownloadDemographicsRatingsComponent } from './download-demographics-ratings/download-demographics-ratings.component';
import { DownloadDemographicsScoresComponent } from './download-demographics-scores/download-demographics-scores.component';
import { DownloadDemographicsAllComponent } from './download-demographics-all/download-demographics-all.component';
import { DownloadDemographicsAllSPHSComponent } from './download-demographics-all-sp-hs/download-demographics-all-sp-hs.component';
import { DownloadDemographicsRatings2025Component } from './download-demographics-ratings2025/download-demographics-ratings2025.component';

const routes: Routes = [
  {
    path: 'downloads',
    canActivateChild: [AuthGuard],
    component: DrdpLayoutComponent,
    children: [
      {
        path: 'demographics',
        canActivate: [PermissionGuard],
        data: { permission: Permission.DownloadDemographics },
        component: DownloadDemographicsComponent,
      },
      {
        path: 'demographics-ratings',
        canActivate: [PermissionGuard],
        data: { permission: Permission.DownloadDemographicsInclRatings },
        component: DownloadDemographicsRatingsComponent,
      },
      {
        path: '2025-demographics-ratings',
        canActivate: [PermissionGuard],
        data: { permission: Permission.PilotIncludeRatings },
        component: DownloadDemographicsRatings2025Component,
      },
      {
        path: 'demographics-scores',
        canActivate: [PermissionGuard],
        data: { permission: Permission.DownloadDemographicsInclScores },
        component: DownloadDemographicsScoresComponent,
      },
      {
        path: 'demographics-all',
        canActivate: [PermissionGuard],
        data: { permission: Permission.DownloadDemographicsInclRatingsandScores },
        component: DownloadDemographicsAllComponent,
      },
      {
        path: 'demographics-all-sp-hs',
        canActivate: [PermissionGuard],
        data: { permission: Permission.SharedChildAccessDemographicsReport },
        component: DownloadDemographicsAllSPHSComponent,
      }
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DownloadsRoutingModule {}
