import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@core/services/auth.service';
import { ToastService } from '@core/services/toast.service';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'drdp-user-session',
  templateUrl: './user-session.component.html',
  styleUrls: ['./user-session.component.scss']
})
export class UserSessionComponent implements OnInit {
  private subscriptions = new Subscription();
  userInfo: any;

  constructor(
    private auth: AuthService,
    private toast: ToastService,
    public dialogRef: MatDialogRef<UserSessionComponent>
  ) {
    dialogRef.disableClose = true;
   }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('drdp-user') ?? '{}');
  }

  removeOtherSession(): void {
    const payload = {
      userId: this.userInfo?.userId,
      loginAuditId: this.userInfo?.loginAuditId
    }

    this.subscriptions.add(this.auth.deleteOtherSession(payload).subscribe(() => {
      this.userInfo.sessionCount = 1;
      localStorage.setItem('drdp-user', JSON.stringify(this.userInfo));
    }));
  }

  logout(): void {
    if (this.userInfo) this.auth.logout().pipe(take(1)).subscribe();
  }

  onDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
