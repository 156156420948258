import { Injectable } from '@angular/core';
import { IPermission, IPermissionGroupAssignmentRequest } from '@core/interfaces/ipermission';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private dataService: DataService, private authService: AuthService) { }

  getPermission(): Observable<any> {
    return this.dataService.get(`feature/permission/all`);
  }

  getUncategorizedPermission(): Observable<any> {
    return this.dataService.get(`feature/permission/all-uncategorized`);
  }

  getCurrentPermission(groupId: number): Observable<any> {
    return this.dataService.get(`feature/permission/get-current-features/${groupId}`);
  }

  getSuperPermissionGroups(): Observable<any> {
    return this.dataService.get(`feature/permission/get-super-feature-group`);
  }

  getPermissionAssignments(): Observable<any> {
    return this.dataService.get(`feature/role-permission-assignment/all`);
  }

  addPermission(permission: any, message?: string): Observable<any> {
    return this.dataService.post(`feature/permission`, permission, message);
  }

  addPermissionGroup(group: any, message?: string): Observable<any> {
    return this.dataService.post(`feature/permission-group`, group, message);
  }

  addPermissionGroupAssignment(assignment: IPermissionGroupAssignmentRequest, message?: string): Observable<any> {
    return this.dataService.post(`feature/permission-group-assignment`, assignment, message);
  }

  updatePermission(permission: IPermission, message?: string): Observable<any> {
    return this.dataService.put(`feature/permission`, permission, message);
  }

  updatePermissionGroupAssignment(assignment: any, message?: string): Observable<any> {
    return this.dataService.put(`feature/permission-group-assignment`, assignment, message);
  }

  deletePermissionGroupAssignment(assignment: any, message?: string): Observable<any> {
    return this.dataService.put(`feature/permission-group-assignment/delete`, assignment, message);
  }

  updatePermissionAssignment(assignment: any, message?: string): Observable<any> {
    return this.dataService.put(`feature/role-permission-assignment`, assignment, message);
  }

  deletePermission(permissionId: number, message?: string): Observable<any> {
    return this.dataService.delete(`feature/permission?id=${permissionId}`, message);
  }

  checkPermission(permissionId: number): boolean {
    const userPermissions = new Set(this.authService.getUserPermissions());

    return userPermissions.has(permissionId);
  }
}
