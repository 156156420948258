import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Modal } from '@core/enums/modal';
import { Permission } from '@core/enums/permissions';
import { SelectType } from '@core/enums/select';
import { TableColumnType } from '@core/enums/table';
import { IAgeGradeInstrument } from '@core/interfaces/iage-grade-instrument';
import { IClassroomAssignment } from '@core/interfaces/iclasses';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { AuthService } from '@core/services/auth.service';
import { ClassService } from '@core/services/class.service';
import { LookupService } from '@core/services/lookup.service';
import { PermissionService } from '@core/services/permission.service';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { RatingService } from '@core/services/rating.service';
import { ToastService } from '@core/services/toast.service';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { take } from 'rxjs';
import { AssessmentDateModalComponent } from '../components/assessment-date-modal/assessment-date-modal.component';
import { AgencyService } from '@core/services/agency.service';
import { IAgency } from '@core/interfaces/iagency';

@Component({
  selector: 'drdp-search-rating',
  templateUrl: './search-rating.component.html',
  styleUrls: ['./search-rating.component.scss'],
})
export class SearchRatingComponent implements OnInit {
  @Output() formValues = new EventEmitter();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageSize: number = 10;
  totalData: number = 0;
  pageIndex: number = 0;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  tableColumns: any[] = [];
  tableData?: any;

  @ViewChild('classroomSelect') classroomSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('agencySelect') agencySelect: FilterSelectComponent | undefined;
  @ViewChild('sitesSelect') sitesSelect: FilterSelectComponent | undefined;
  @ViewChild('ratingPeriodSelect') ratingPeriodSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('stateSelect') stateSelect: FilterSelectComponent | undefined;
  @ViewChild('ageGroupInstrumentSelect') ageGroupInstrumentSelect:
    | FilterSelectComponent
    | undefined;

  user = this.auth.getCurrentUser();
  initStateId: number | null = this.user.stateId;
  initAgencyId: number | null = this.user.agencyId;
  searchForm: FormGroup | any;
  classroomList: IClassroomAssignment[] = [];
  ratingPeriodList: IRatingPeriod[] = [];
  select = SelectType;
  clearDropdown = false;
  isSuperUser = this.permissionService.checkPermission(
    Permission.AccessAllAgencies
  );
  canLock = false;
  canUnlock = false;
  isAgencyLocked = true;
  classAssessmentDate: any = null;
  minRatingPeriodDate = new Date();
  maxRatingPeriodDate = new Date();
  isPilot: boolean = false;

  canLockRatings = this.permissionService.checkPermission(
    Permission.LockRatings
  );

  canUnlockRatings = this.permissionService.checkPermission(
    Permission.UnlockRatings
  );

  get stateId() {
    return this.searchForm.get('stateId');
  }

  get agencyId() {
    return this.searchForm.get('agencyId');
  }
  get siteId() {
    return this.searchForm.get('siteId');
  }
  get ratingPeriodId() {
    return this.searchForm.get('ratingPeriodId');
  }
  get classId() {
    return this.searchForm.get('classId');
  }
  get ageGradeInstrumentId() {
    return this.searchForm.get('ageGradeInstrumentId');
  }

  constructor(
    private auth: AuthService,
    private ratingPeriodService: RatingPeriodService,
    private classService: ClassService,
    private lookup: LookupService,
    private toastService: ToastService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private permissionService: PermissionService,
    private ratingService: RatingService,
    private agencyService: AgencyService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.initializeForm();

    if (this.user.agencyId) {
      this.getRatingPeriod();
    }

    const classId = this.route.snapshot.paramMap.get('classId');
    const instrumentId = this.route.snapshot.paramMap.get('instrumentId');

    if (classId != null && instrumentId != null) {
      this.classService.getStudentsByClass(+classId).subscribe( res => {
        this.initStateId = res.classroom?.site?.stateId;
        this.initAgencyId = res.classroom?.agencyId;

        this.handleState(res.classroom?.site?.stateId);
        this.handleChosenAgency(res.classroom?.agencyId);
        this.handleChosenRatingPeriod(res.ratingPeriodId);
        this.handleChosenSite(res.siteId);
        this.handleChosenClassroom(res.id);
        this.handleAgeGroupInstrument(instrumentId);
        this.isPilot = res.classroom?.isPilot;
        this.search()
      });
    }
  }

  initializeForm(): void {
    this.searchForm = this.fb.group({
      stateId: [this.user.stateId ?? null, [Validators.required]],
      agencyId: [this.user.agencyId ?? null, [Validators.required]],
      ratingPeriodId: [null, [Validators.required]],
      siteId: [null, [Validators.required]],
      classId: [null, [Validators.required]],
      ageGradeInstrumentId: [null, [Validators.required]],
    });
  }

  getRatingPeriod(): void {
    this.ratingPeriodService
      .getByAgency(this.agencyId.value)
      .pipe(take(1))
      .subscribe((req) => {
        this.ratingPeriodList = req;
      });
  }

  handleState(state: any) {
    this.stateId.setValue(state instanceof Object ? state?.id : state);
    this.agencyId.setValue(null);
    this.ageGradeInstrumentId.setValue(null);
    this.classId.setValue(null);
    this.isPilot = false;
    this.formValues.emit(this.searchForm.value);
  }

  handleChosenAgency(agency: any): void {
    const selectedAgencyId = agency instanceof Object ? agency?.id : agency;
    if (selectedAgencyId != this.agencyId.value) {
      this.agencyId.setValue(selectedAgencyId);
      this.classId.setValue(null);
      this.ratingPeriodId.setValue(null);
      this.classroomList = [];
      if (agency) {
        this.getRatingPeriod();
      } else {
        this.ratingPeriodList = [];
        this.classroomList = [];
      }

      this.formValues.emit(this.searchForm.value);
    }
  }

  handleChosenSite(site: any): void {
    this.siteId.setValue(site instanceof Object ? site?.id ?? site[0]?.id : site);
    this.updateClassroomDropdown();
    this.formValues.emit(this.searchForm.value);
  }

  handleChosenClassroom(classroom: any): void {
    this.classId.setValue(classroom instanceof Object ? classroom?.id : classroom);
    this.isPilot = this.classroomList.find(c => c.id == this.classId.value)?.isPilot ?? false;
    this.formValues.emit(this.searchForm.value);
  }

  handleAgeGroupInstrument(ageGroupInstrument: any): void {
    this.ageGradeInstrumentId.setValue(ageGroupInstrument instanceof Object ? ageGroupInstrument?.id : ageGroupInstrument);
  }

  handleChosenRatingPeriod(ratingPeriod: any): void {
    this.ratingPeriodId.setValue(ratingPeriod instanceof Object ? ratingPeriod?.id : ratingPeriod);
    this.updateClassroomDropdown();
    this.formValues.emit(this.searchForm.value);
  }

  updateClassroomDropdown(): void {
    this.classroomList = [];
    const payload: any = {};
    payload.siteIds = this.siteId.value ?? null;
    payload.siteIds = this.siteId.value ? [this.siteId.value] : null;

    payload.ratingPeriodId = this.ratingPeriodId.value ?? '';
    payload.itemsPerPage = 10;
    payload.useClassId = true;

    if (payload.siteIds?.length > 0 && payload.ratingPeriodId > 0) {
      this.classService
        .getClassesBySitesAndRatingPeriod(payload)
        .pipe(take(1))
        .subscribe((res: any) => {
          this.classroomList = res.filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.id === value.id)
          );
        });
    }
  }

  search() {
    if (this.searchForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }
    this.paginateRatings(true);
  }

  paginateRatings(resetPage?: boolean) {
    const payload = {
      classId: this.classId.value,
      agencyId: this.agencyId.value,
      ageGradeInstrumentId: this.ageGradeInstrumentId.value,
      pageIndex: resetPage ? 0 : this.paginator.pageIndex + 1,
      itemsPerPage: resetPage ? 10 : this.paginator.pageSize,
    };

    this.ratingService.getClassRatings(payload).subscribe((res: any) => {
      if (res) {
        if (resetPage) this.paginator.firstPage();
        this.ratingService.setStudentResultIds(res.allItemIds);
        this.checkLockAndUnlock(res.items);
        this.initializeTableData(res);
        if (res.totalData > 25) this.pageSizeOptions.push(res.totalData);
      }
    });
  }

  checkLockAndUnlock(data: any[]) {
    if (data.length > 0) {
      const firstRecord = data[0];
      this.canLock =
        data.every((d) => d.totalDomainCount == d.totalDomainRatings) &&
        data.some((d) => !d.isLocked);
      this.canUnlock = !this.canLock && data.every((d) => d.isLocked);
      this.isAgencyLocked = firstRecord.isRatingViewLocked;
      this.minRatingPeriodDate = new Date(firstRecord.minStartDate);
      this.maxRatingPeriodDate = new Date(firstRecord.maxEndDate);

      if (this.canLock) {
        const withAssessmentDate = data.find((d) => d.assessmentDate);
        if (withAssessmentDate)
          this.classAssessmentDate = new Date(
            withAssessmentDate.assessmentDate
          );
      }
    }
  }

  initializeTableData(res: any): void {
    this.initializeTableColumns(res.items);
    this.dataSource = new MatTableDataSource(res.items);
    this.totalData = res.totalData;
    this.tableData = res.items;
  }

  initializeTableColumns(data: any[]) {
    let domainRows: any[] = data.length > 0 ? data[0].displayDomains : [];

    this.tableColumns = [
      {
        columnDef: 'enrollmentId',
        header: 'Ratings',
        icon: 'file-alt',
        type: TableColumnType.actionIcon,
        action: 'view-rating',
        colorClass: 'text-drdpblue-300',
        cursorClass: 'cursor-pointer',
      },
      {
        columnDef: 'studentName',
        header: 'Child Name',
        type: TableColumnType.text,
        isSortable: true,
      },
      {
        columnDef: 'studentId',
        header: 'Child ID',
        type: TableColumnType.text,
        isSortable: true,
      },
    ];

    domainRows.forEach((domain: string) => {
      const measureCode = domain
        .replace('-', '')
        .replace(':', '')
        .toLowerCase();
      this.tableColumns.push({
        columnDef: measureCode,
        header: domain,
        type: TableColumnType.text,
      });
    });

    this.tableColumns.push(
      {
        columnDef: 'assessmentDate',
        header: 'Assessment Date',
        type: TableColumnType.date,
        isSortable: true,
      },
      {
        columnDef: 'formattedLockingDate',
        header: 'Lock Date',
        type: TableColumnType.date,
        isSortable: true,
      },
      {
        columnDef: 'ratingCompletion',
        header: 'Rating Completion',
        type: TableColumnType.text,
      },
      {
        columnDef: 'isLocked',
        header: 'Locking Status',
        type: TableColumnType.boolIcon,
        truthyClass: 'text-drdpGreen-300',
        truthyIconUrl: 'assets/images/misc/lock-alt.svg',
        falsyClass: 'text-drdpgrey-200',
        falsyIconUrl: 'assets/images/misc/padlock.svg',
        isSortable: true,
      },
      {
        columnDef: 'teacherName',
        header: 'Teacher',
        type: TableColumnType.text,
        isSortable: true,
      },
      {
        columnDef: 'siteName',
        header: 'Site',
        type: TableColumnType.text,
        isSortable: true,
      }
    );
  }

  onIconClick(event: any): void {
    if (event.action == 'view-rating') {
      this.router.navigate([`input-ratings/${event.data.enrollmentId}`]);
    }
  }

  clear(): void {
    this.classroomSelect?.clear();
    this.ratingPeriodSelect?.clear();
    this.isPilot = false;
    this.clearDropdown = true;
    this.classroomList = [];

    if (!this.stateSelect?.disabled) {
      this.initStateId = 0;
      this.stateId.setValue(null);
    }

    if (!this.agencySelect?.disabled) {
      this.agencyId.setValue(null);
    }
  }

  lockClass() {
    if (!this.isAgencyLocked) {
      let event = { data: { modalInfo: {} } };
      event.data.modalInfo = {
        title: `Error`,
        message: `Unable to lock Child Rating(s) until Agency has locked its Ratings View.`,
        primaryBtnClass: 'blue',
        primaryBtnText: Modal.OK,
      };
      this.dialog.open(ConfirmationModalComponent, {
        data: event,
      });
      return;
    }

    if (this.classId.value) {
      const data = {
        assessmentDate: this.classAssessmentDate,
        minDate: this.minRatingPeriodDate,
        maxDate: this.maxRatingPeriodDate,
      };
      const modalRef = this.dialog.open(AssessmentDateModalComponent, {
        data: data,
      });

      modalRef.afterClosed().subscribe((result: any) => {
        if (result) {
          const payload = {
            assessmentDate: result.assessmentDate,
            ageGradeInstrumentId: this.ageGradeInstrumentId.value,
            classId: this.classId.value,
          };
          this.ratingService
            .lockClass(payload)
            .pipe(take(1))
            .subscribe((_) => {
              this.paginateRatings(true);
            });
        }
      });
    }
  }

  unlockClass() {
    let event = { data: { modalInfo: {} } };
    event.data.modalInfo = {
      title: `Unlock Rating`,
      message: `Are you sure you want to Unlock the ratings?`,
      primaryBtnClass: 'blue',
      primaryBtnText: Modal.Confirm,
      showCancel: true,
    };
    const modalRef = this.dialog.open(ConfirmationModalComponent, {
      data: event,
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res && this.classId.value) {
        const payload = {
          ageGradeInstrumentId: this.ageGradeInstrumentId.value,
          classId: this.classId.value,
        };

        this.ratingService
          .unlockClass(payload)
          .pipe(take(1))
          .subscribe((_) => {
            this.paginateRatings(true);
          });
      }
    });
  }
}
