import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '@core/core.module';

import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserRoutingModule } from './user-routing.module';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { DrdpLayoutComponent } from './drdp-layout/drdp-layout.component';
import { MyClassesComponent } from './manage-enrollment/my-classes/my-classes.component';
import { StudentsComponent } from './manage-enrollment/my-classes/students/students.component';
import { LatestNewsComponent } from './dashboard/latest-news/latest-news.component';
import { NewsDescriptionComponent } from './dashboard/latest-news/news-description/news-description.component';
import { EditUserProfileComponent } from './user-profile/edit-user-profile/edit-user-profile.component';
import { UpdatePasswordComponent } from './user-profile/update-password/update-password.component';
import { AddNewChildComponent } from './add-new-child/add-new-child.component';
import { AssignChildComponent } from './add-new-child/assign-child/assign-child.component';
import { UserSessionComponent } from './dashboard/user-session/user-session.component';
import { ViewChildComponent } from './manage-enrollment/view-child/view-child.component';
import { ViewChildTabsComponent } from './manage-enrollment/view-child/view-child-tabs/view-child-tabs.component';
import { AddViewEditChildComponent } from './manage-enrollment/add-view-edit-child/add-view-edit-child.component';
import { ViewClassEnrollmentsComponent } from './manage-enrollment/view-child/view-class-enrollments/view-class-enrollments.component';
import { PipesModule } from '@core/pipes/pipes.module';
import { TransferChildComponent } from './manage-enrollment/transfer-child/transfer-child.component';
import { ReEnrollClassroomComponent } from './manage-enrollment/my-classes/re-enroll-classroom/re-enroll-classroom.component';
import { PendingTransfersComponent } from './dashboard/pending-requests/pending-transfers/pending-transfers.component';
import { ReEnrollmentRequestsComponent } from './dashboard/pending-requests/re-enrollment-requests/re-enrollment-requests.component';
import { DropChildComponent } from './manage-enrollment/drop-child/drop-child.component';
import { PendingWithdrawsComponent } from './dashboard/pending-requests/pending-withdraws/pending-withdraws.component';
import { SearchDroppedChildrenComponent } from './search-dropped-children/search-dropped-children.component';
import { EnrollDroppedChildrenComponent } from './search-dropped-children/enroll-dropped-children/enroll-dropped-children.component';
import { SelectDroppedChildComponent } from './add-new-child/select-dropped-child/select-dropped-child.component';
import { InputRatingComponent } from './input-rating/input-rating.component';
import { SelectRatingModalComponent } from './input-rating/components/select-rating-modal/select-rating-modal.component';
import { SearchRatingComponent } from './input-rating/search-rating/search-rating.component';
import { ScantronComponent } from './input-rating/components/scantron/scantron.component';
import { StudentInfoComponent } from './input-rating/components/student-info/student-info.component';
import { PortfolioComponent } from './dashboard/portfolio/portfolio.component';
import { PortfolioClassComponent } from './dashboard/portfolio/portfolio-class/portfolio-class.component';
import { PortfolioAddEvidence } from './dashboard/portfolio/portfolio-class/portfolio-add-evidence/portfolio-add-evidence.component';
import { PortfolioReviewEvidenceComponent } from './dashboard/portfolio/portfolio-class/portfolio-review-evidence/portfolio-review-evidence.component';
import { PortfolioInputRatingModalComponent } from './dashboard/portfolio/portfolio-class/portfolio-input-rating-modal/portfolio-input-rating-modal.component';
import { ViewChildSharedProvidersComponent } from './manage-enrollment/view-child/view-child-shared-providers/view-child-shared-providers.component';
import { AddChildSharedProviderComponent } from './manage-enrollment/view-child/view-child-shared-providers/add-child-shared-provider/add-child-shared-provider.component';
import { DeleteChildSharedProviderComponent } from './manage-enrollment/view-child/view-child-shared-providers/delete-child-shared-provider/delete-child-shared-provider.component';
import { AddSearchChildComponent } from './add-new-child/add-search-child/add-search-child.component';
import { ReleaseStudentComponent } from './add-new-child/add-search-child/request-release-student/request-release-student.component';
import { PendingReleasesComponent } from './dashboard/pending-requests/pending-releases/pending-releases.component';
import { AssessmentDateModalComponent } from './input-rating/components/assessment-date-modal/assessment-date-modal.component';
import { RatingCardComponent } from './input-rating/components/rating-card/rating-card.component';
import { ViewChildAssessmentsComponent } from './manage-enrollment/view-child/view-child-assessments/view-child-assessments.component';
import { DeclineReleaseStudentComponent } from './dashboard/pending-requests/pending-releases/decline-release-student/decline-release-student.component';
import { StudentBulkUnEnrollmentComponent } from './manage-enrollment/my-classes/students/students-bulk-un-enrollment/students-bulk-un-enrollment.component';
import { PilotTestChildrenComponent } from './pilot-test-children/pilot-test-children.component';
import { ChildrenComponent } from './children/children.component';
import { SearchManageChildComponent } from './manage-enrollment/search-manage-child/search-manage-child.component';
import { BatchReEnrollChildComponent } from './manage-enrollment/batch-re-enroll-child/batch-re-enroll-child.component';
import { PendingChildReEnrollmentComponent } from './dashboard/pending-requests/pending-child-re-enrollment/pending-child-re-enrollment.component';
import { ReportsModule } from "@views/user/reports/reports.module";
import { ChildRequestComponent } from './manage-enrollment/child-request/child-request.component';
import { EditClassEnrollmentDate } from './manage-enrollment/view-child/view-class-enrollments/edit-class-enrollment/edit-class-enrollment-date.component';
import { PortfolioMessageBoxComponent } from './dashboard/portfolio/portfolio-class/portfolio-message-box/portfolio-message-box.component';
import { PortfolioMessagesComponent } from './dashboard/portfolio/portfolio-messages/portfolio-messages.component';
import { MessageTileComponent } from './dashboard/portfolio/portfolio-messages/message-tile/message-tile.component';
import { MessageViewComponent } from './dashboard/portfolio/portfolio-messages/message-view/message-view.component';
import { MessageBoardTileComponent } from './dashboard/portfolio/portfolio-messages/message-board-tile/message-board-tile.component';
import { MessageBoardComponent } from './dashboard/portfolio/portfolio-messages/message-board/message-board.component';
import { TotalLockedChildrenComponent } from './dashboard/total-locked-children/total-locked-children.component';
import { ViewChildEvidenceComponent } from './manage-enrollment/view-child/view-child-evidence/view-child-evidence.component';
import { FutureEvidenceStudentComponent } from './dashboard/portfolio/portfolio-class/modals/future-evidence-student/future-evidence-student.component';
import { AssignEnrollmentComponent } from './dashboard/portfolio/portfolio-class/modals/assign-enrollment/assign-enrollment.component';
import { DualEnrollChildComponent } from './add-new-child/dual-enroll-child/dual-enroll-child.component';
import { SpedExitDateComponent } from './manage-enrollment/my-classes/students/sped-exit-date/sped-exit-date.component';
import { BatchSpedExitDateComponent } from './manage-enrollment/my-classes/students/batch-sped-exit-date/batch-sped-exit-date.component';
import { ConfirmationSpedExitDateComponent } from './manage-enrollment/my-classes/students/confirmation-sped-exit-date/confirmation-sped-exit-date.component';
import { TeacherSurveyModalComponent } from './input-rating/components/teacher-survey-modal/teacher-survey-modal.component';
@NgModule({
  declarations: [
    DrdpLayoutComponent,
    DashboardComponent,
    UserProfileComponent,
    MyClassesComponent,
    StudentsComponent,
    LatestNewsComponent,
    NewsDescriptionComponent,
    EditUserProfileComponent,
    UpdatePasswordComponent,
    AddNewChildComponent,
    AssignChildComponent,
    UserSessionComponent,
    ViewChildComponent,
    ViewChildTabsComponent,
    AddViewEditChildComponent,
    ViewClassEnrollmentsComponent,
    TransferChildComponent,
    ReEnrollClassroomComponent,
    PendingTransfersComponent,
    ReEnrollmentRequestsComponent,
    DropChildComponent,
    PendingWithdrawsComponent,
    SearchDroppedChildrenComponent,
    EnrollDroppedChildrenComponent,
    SelectDroppedChildComponent,
    InputRatingComponent,
    SelectRatingModalComponent,
    SearchRatingComponent,
    ScantronComponent,
    StudentInfoComponent,
    PortfolioComponent,
    PortfolioClassComponent,
    PortfolioAddEvidence,
    PortfolioReviewEvidenceComponent,
    PortfolioInputRatingModalComponent,
    ViewChildSharedProvidersComponent,
    AddChildSharedProviderComponent,
    DeleteChildSharedProviderComponent,
    AddSearchChildComponent,
    ReleaseStudentComponent,
    PendingReleasesComponent,
    AssessmentDateModalComponent,
    RatingCardComponent,
    ViewChildAssessmentsComponent,
    DeclineReleaseStudentComponent,
    StudentBulkUnEnrollmentComponent,
    PilotTestChildrenComponent,
    ChildrenComponent,
    SearchManageChildComponent,
    BatchReEnrollChildComponent,
    PendingChildReEnrollmentComponent,
    ChildRequestComponent,
    EditClassEnrollmentDate,
    PortfolioMessageBoxComponent,
    PortfolioMessagesComponent,
    MessageTileComponent,
    PortfolioMessageBoxComponent,
    PortfolioMessagesComponent,
    MessageTileComponent,
    MessageViewComponent,
    MessageBoardTileComponent,
    MessageBoardComponent,
    TotalLockedChildrenComponent,
    ViewChildEvidenceComponent,
    FutureEvidenceStudentComponent,
    AssignEnrollmentComponent,
    DualEnrollChildComponent,
    SpedExitDateComponent,
    BatchSpedExitDateComponent,
    ConfirmationSpedExitDateComponent,
    TeacherSurveyModalComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    SharedModule,
    FormsModule,
    CoreModule,
    PipesModule.forRoot(),
    ReportsModule
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserModule { }
