import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items?: any[], property?: string, searchTerm?: any): any[] {
    if (!items || !searchTerm) {
      return items || [];
    }
    return items.filter(item => {
      return item[property!] == searchTerm;
    });
  }
}

