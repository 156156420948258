import { Injectable } from '@angular/core';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { environment } from 'src/environments/environment';
import {
  Observable,
  from,
  throwError,
  catchError,
  map,
  EMPTY,
  of,
  forkJoin,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlobStorageService {
  private accountName = environment.storageName;

  constructor() {}

  uploadFile(
    file: File,
    fileName: string,
    container: string,
    sasToken: string,
    onProgress: (ev: any) => void
  ): Observable<any> {
    const containerClient = this.containerClient(sasToken, container);
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    const blobOptions = {
      blobHTTPHeaders: {
        blobContentType: file.type,
      },
    };

    // Use the uploadData method to upload the file as a buffer
    return from(
      blockBlobClient.uploadData(file, {
        blobHTTPHeaders: {
          blobContentType: file.type,
        },
        onProgress,
      })
    ).pipe(
      map(() => {
        return {
          displayUrl: `https://${this.accountName}.blob.core.windows.net/${container}/${fileName}${sasToken}`,
          url: `https://${this.accountName}.blob.core.windows.net/${container}/${fileName}`,
        };
      })
    );
  }

  uploadMultipleFiles(
    files: File[],
    container: string,
    sasToken: string,
    onProgress: (ev: any) => void,
    studentEnrollementId?: number
  ): Observable<any> {
    const containerClient = this.containerClient(sasToken, container);

    const uploadObservables = files.map((file) => {
      let fileName = '';
      if (studentEnrollementId) {
        fileName = `${studentEnrollementId}/${
          file.name
        }-${new Date().toISOString()}`;
      } else {
        fileName = `${file.name}-${new Date(new Date()).toLocaleDateString(
          'en-US'
        )}`;
      }
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);

      const blobOptions = {
        blobHTTPHeaders: {
          blobContentType: file.type,
        },
      };

      return from(
        blockBlobClient.uploadData(file, {
          blobHTTPHeaders: {
            blobContentType: file.type,
          },
          onProgress,
        })
      ).pipe(
        map(() => ({
          displayUrl: `https://${this.accountName}.blob.core.windows.net/${container}/${fileName}${sasToken}`,
          url: `https://${this.accountName}.blob.core.windows.net/${container}/${fileName}`,
          fileName: fileName
        }))
      );
    });

    return forkJoin(uploadObservables);
  }

  deleteFile(
    fileName: string,
    container: string,
    sasToken: string
  ): Observable<boolean> {
    const containerClient = this.containerClient(sasToken, container);

    return from(containerClient.deleteBlob(fileName)).pipe(
      map(() => true),
      catchError((error) => {
        return of(false); // Return false to indicate deletion failure
      })
    );
  }

  private containerClient(
    sasToken: string,
    container: string
  ): ContainerClient {
    const blobUrl = `https://${this.accountName}.blob.core.windows.net/${sasToken}`;
    return new BlobServiceClient(blobUrl).getContainerClient(container);
  }
}
