import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { ReportsRoutingModule } from './reports-routing.module';
import { ParentProgressComponent } from './parent-progress/parent-progress.component';
import { ReportSearchComponent } from './report-search/report-search.component';
import { ViewStudentByClassModalComponent } from './view-student-by-class-modal/view-student-by-class-modal.component';
import { ClassPlanningComponent } from './class-planning/class-planning.component';
import { GroupDetailComponent } from './group-detail/group-detail.component';
import { FormsModule } from '@angular/forms';
import { CohortProgressComponent } from './cohort-progress/cohort-progress.component';
import { ChildProgressComponent } from './child-progress/child-progress.component';
import { GroupComponent } from './group/group.component';
import { RatingCompletionComponent } from './rating-completion/rating-completion.component';
import { ViewChildrenModalComponent } from './view-children-modal/view-children-modal.component';
import { ReportTrackingComponent } from './report-tracking/report-tracking.component';
import { ReportUsageComponent } from './report-tracking/report-usage/report-usage.component';
import { AgencyRatingViewsComponent } from './report-tracking/agency-rating-views/agency-rating-views.component';
import { ChildRatingsComponent } from './report-tracking/child-ratings/child-ratings.component';
import { DistrictOsepComponent } from './district-osep/district-osep.component';
import { StateOsepComponent } from './state-osep/state-osep.component';
import { BarChartComponent } from './district-osep/charts/bar-chart/bar-chart.component';
import { LineChartComponent } from './district-osep/charts/line-chart/line-chart.component';
import { TeacherSurveyComponent } from './teacher-survey/teacher-survey.component';


@NgModule({
  declarations: [
    ParentProgressComponent,
    ReportSearchComponent,
    ViewStudentByClassModalComponent,
    ClassPlanningComponent,
    GroupDetailComponent,
    CohortProgressComponent,
    ChildProgressComponent,
    GroupComponent,
    RatingCompletionComponent,
    ViewChildrenModalComponent,
    ReportTrackingComponent,
    ReportUsageComponent,
    AgencyRatingViewsComponent,
    ChildRatingsComponent,
    DistrictOsepComponent,
    StateOsepComponent,
    BarChartComponent,
    LineChartComponent,
    TeacherSurveyComponent
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    SharedModule,
    CoreModule,
    FormsModule,
    PipesModule.forRoot()
  ]
})
export class ReportsModule { }
