import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { Observable } from 'rxjs';

type CanDeactivateType = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

export interface CanComponentDeactivate {
  canDeactivate: () => CanDeactivateType;
}


@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard  implements CanDeactivate<CanComponentDeactivate> {

  constructor(public dialog: MatDialog) {}
  canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): CanDeactivateType {
      const canDeactivate = component.canDeactivate ? component.canDeactivate() : true;
      if (!canDeactivate) {
           return this.confirmNoSave();
      }
      return true;
  }

  confirmNoSave(): Observable<boolean> {
    const modal = {
      data: {
        modalInfo: {
          title: 'You have unsaved changes',
          message: 'Are you sure you want to navigate away from this page? Please choose one of the options below.',
          primaryBtnClass: 'blue',
          primaryBtnText: 'Exit Page Without Saving',
          cancelText: 'Return to Page',
          showCancel: true,
        },
      },
    };

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: modal,
    });

    return dialogRef.afterClosed();
  }
}
