export enum Modal {
    Confirm = 'Confirm',
    Delete = 'Delete',
    OK = 'OK',
    Close = 'Close',
    Copy = 'Copy to Clipboard',
    Archive = 'Archive Message',
}

export enum ModalType {
    Agency = 'agency',
}