import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { IUser } from '@core/interfaces/iuser';
import { SelectType } from '@core/enums/select';
import { StudentService } from '@core/services/student.service';
import { take } from 'rxjs';
import { IUserSpecialEdPowerDto } from '@core/interfaces/istudent';

@Component({
  selector: 'drdp-select-providers',
  templateUrl: './select-providers.component.html',
  styleUrls: ['./select-providers.component.scss']
})
export class SelectProvidersComponent implements OnInit {
  @ViewChild('providerSelect') providerSelect: FilterSelectComponent | undefined;
  @Output() provider = new EventEmitter<any>();
  @Input() initialProviderId?: number | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  providerOptions?: any[] = [];
  public get select() {return SelectType }
  constructor(private studentService: StudentService) { }

  ngOnInit(): void {
    this.getStudentSharedProviderUsers();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      this.providerSelect?.clearSearch(true);
    }
  }

  clearSelected() {
    this.providerSelect?.clearSelected();
  }

  getStudentSharedProviderUsers() {
    this.studentService
        .getStudentSharedProviderUsers()
        .pipe(take(1))
        .subscribe((res: IUserSpecialEdPowerDto[]) => {
            if (res) {
                this.providerOptions = res.map((user: IUserSpecialEdPowerDto) => {
                    return { name: user.emailAddress, teacherName: user.teacherName, id: user.id }
                });
            }
        });
}

  onProviderSelect(event: any): void {
    const provider = this.providerOptions?.find((p) => p.id === event.id);
    event.teacherName = provider.teacherName;
    this.provider.emit(event);
  }
}
