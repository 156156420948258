import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDate',
})
export class LocalDatePipe implements PipeTransform {
  transform(utcDate: string | Date | undefined | null): string {
    if (!utcDate) return '';

    if (typeof utcDate === 'string' && !utcDate.endsWith('Z')) {
      utcDate += 'Z';
    }

    const date = new Date(utcDate);
    
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };

    return date.toLocaleString(undefined, options);
  }
}