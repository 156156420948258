import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FieldType, RegionType } from '@core/enums/data-type';
import { StudentDemographic } from '@core/enums/student-demographic-form';
import { IState } from '@core/interfaces/istate';
import { StateService } from '@core/services/state.service';
import { take, tap } from 'rxjs';

@Component({
  selector: 'drdp-student-demographics',
  templateUrl: './student-demographics.component.html',
  styleUrls: ['./student-demographics.component.scss']
})
export class StudentDemographicsComponent implements OnInit {
  @Input() state?: IState;
  demographicForm: FormGroup | any;
  configurations: any;
  public get fieldTypeConst() {return FieldType };
  public get regionTypeConst() {return RegionType };
  public get studentDemograpic() {return StudentDemographic };

  constructor(private fb: FormBuilder, public stateService: StateService, private router:  Router) { }

  ngOnInit(): void {}

  ngOnChanges() {
    this.initializeForm();
  }

  initializeForm(): void {
    this.demographicForm = this.fb.group({
      'Statewide Student Identifier': [null],
      'maxLength': [10],
      'Agency/District Student Identifier': [null],
      'Region Label': [RegionType.Region],
      'Primary Disability': [false],
      'Special Education Entry Date': [false],
      'District of Liability': [false],
      'Anticipated Date of Exit from Preschool Education to Kindergarten': [false],
      'Date of Exit from Preschool Special Education to Kindergarten': [false],
      'Selpa': [false],
      'initialAssessmentToolId': [false],
      'outcome1Id': [false],
      'outcome2Id': [false],
      'outcome3Id': [false],
    });
    this.initializeValues();
  }

  initializeValues(): void {
    if (this.state) {
      this.stateService.getStateConfigurations(this.state?.id).pipe(
        take(1),
        tap((configs: any) => {
          if (configs) {
            this.configurations = configs.filter((config: any) => !config.iscustom);

            for(let config of this.configurations) {
              if (config.columnName == StudentDemographic.SSID) {
                this.demographicForm.controls[StudentDemographic.SSID].setValue(config.label);
                this.demographicForm.controls[StudentDemographic.maxLength].setValue(config.maxLength == 0 ? 10 : config.maxLength)
              } else if (config.columnName == StudentDemographic.AgencySID) {
                this.demographicForm.controls[StudentDemographic.AgencySID].setValue(config.label);
              } else if (config.columnName === StudentDemographic.RegionLabel) {
                this.demographicForm.controls[StudentDemographic.RegionLabel].setValue(config.label ?? RegionType.Region);
              } else if (!config.isCustom){
                this.demographicForm.controls[config.columnName].setValue(config.isActive);
              }
            }
          }
        })
      ).subscribe();
    }
  }

  updateConfigurations(): void {
    for(let config of this.configurations) {
      if (config.columnName == StudentDemographic.SSID) {
        config.label = this.demographicForm.controls[StudentDemographic.SSID].value;
        config.maxLength = this.demographicForm.controls[StudentDemographic.maxLength].value;
      } else if (config.columnName == StudentDemographic.AgencySID) {
        config.label = this.demographicForm.controls[StudentDemographic.AgencySID].value;
      } else if (config.columnName == StudentDemographic.RegionLabel) {
        config.label = this.demographicForm.controls[StudentDemographic.RegionLabel].value;
      } else if (!config.isCustom){
        config.isActive = this.demographicForm.controls[config.columnName]?.value;
      }
    }
  }

  addConfiguration(): void {
    Object.keys(this.demographicForm.controls).forEach(key => {
      const configExist = this.configurations.find((obj: any) => (obj.columnName === key));
      const isValidFormControl =  key !== StudentDemographic.maxLength;

      if (!configExist && isValidFormControl) {
        this.configurations.push({
          Id: 0,
          StateID: this.state?.id,
          ColumnName: key,
          SectionID: this.getSection(key),
          Label: this.getLabel(key),
          IsActive: this.checkIsActive(key),
          MaxLength: this.getMaxLength(key)
        });
      }

    });
  }

  getSection(key: string): number | null{
    switch(key) {
      case StudentDemographic.SSID:
      case StudentDemographic.AgencySID:
        return 1;
      case StudentDemographic.PrimaryDisability:
      case StudentDemographic.SpecialEdEntryDate:
      case StudentDemographic.DistrictLiability:
      case StudentDemographic.AnticipatedDateExit:
      case StudentDemographic.DateExit:
      case StudentDemographic.Selpa:
        return 5;
      default:
        return null;
    }
  }

  getLabel(key: string): string | null {
    if (key === StudentDemographic.SSID || key === StudentDemographic.AgencySID || key === StudentDemographic.RegionLabel) {
      return this.demographicForm.controls[key].value;
    }
    return null;
  }


  getMaxLength(key: string): number | null {
    if (key === StudentDemographic.SSID) {
      const maxLength = this.demographicForm.controls[StudentDemographic.maxLength].value;
      return maxLength > 0 ? maxLength : 10;
    }
    return null;
  }

  checkIsActive(key: string): boolean | null {
    if (key !== StudentDemographic.SSID && key !== StudentDemographic.AgencySID && key !== this.studentDemograpic.RegionLabel) {
      return this.demographicForm.controls[key].value;
    }
    return null;
  }

  backToStatePage(): void {
    this.router.navigate(['config/states']);
  }

  onSubmit(): void {
    if (this.demographicForm.value) {
      this.updateConfigurations();
      this.addConfiguration();

      if (this.configurations) {
        let payload = {
          StateID:  this.state?.id,
          StateConfigList: this.configurations
        }

        this.stateService.updateStateConfigurations(payload).pipe(
          take(1)
        ).subscribe()
      }
    }
  }

}
