import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDropMenu, IEvent, IMenuItem } from '@core/interfaces/side-menu';

@Component({
  selector: 'drdp-drop-menu',
  templateUrl: './drop-menu.component.html',
  styleUrls: ['./drop-menu.component.scss'],
})
export class DropMenuComponent implements OnInit {
  @Input() dropdownItems: IMenuItem | undefined;
  @Input() agencyItems: IDropMenu | undefined;
  @Input() sites: any;

  @Input() show: boolean = false;
  @Input() menuIndex?: number;
  @Output() selectedItemEvent = new EventEmitter<IEvent>();
  @Output() close = new EventEmitter<boolean>(false);

  constructor() {}

  ngOnInit(): void {}

  dropMenuToggle() {
    const event: IEvent = {
      indexValue: this.menuIndex,
      showValue: this.show,
    };
    this.selectedItemEvent.emit(event);
  }

  closeMenu() {
    this.close.emit(true);
  }
}
