import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { PermissionService } from '@core/services/permission.service';
import { ToastService } from '@core/services/toast.service';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(private router: Router, private permissionService: PermissionService, private authService: AuthService, private toast: ToastService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let isValid = true;

    if (route.data) {

      var permissions = route.data['permission'];

      if (Array.isArray(permissions)) {
        for (let permission of permissions)
        {
          isValid = this.permissionService.checkPermission(permission);
          if(isValid) break;
        }
      } else {
        isValid = this.permissionService.checkPermission(permissions);
      }


      if (!isValid) {
        this.router.navigateByUrl('dashboard');
        this.toast.error('You are not authorized to access this page. Please login with the correct credentials.');
      }
    }
    return isValid;
  }

}
