import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { IStatePreschool } from '@core/interfaces/ihead-start';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';

@Component({
  selector: 'drdp-select-state-preschool',
  templateUrl: './select-state-preschool.component.html',
  styleUrls: ['./select-state-preschool.component.scss']
})
export class SelectStatePreschoolComponent implements OnInit {
  @ViewChild('statePreschoolSelect') statePreschoolSelect: FilterSelectComponent | undefined;
  @Output() statePreschool = new EventEmitter<any>();
  @Input() initialStatePreschoolId?: number | null;
  @Input() initialStatePreschoolIds?: number | null;
  @Input() multiSelectInitVals: boolean = false;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  statePreschoolOptions?: IStatePreschool[] = [];
  public get select() { return SelectType }
  constructor(private lookupService: LookupService) { }

  ngOnInit(): void {
    this.getStatePreschool();
  }

  getStatePreschool(): void {
    this.lookupService.getStatePreschool()
      .pipe(take(1))
      .subscribe((statePreschool: IStatePreschool[]) => {
        this.statePreschoolOptions = statePreschool;
      });
  }

  onStatePreschoolSelect(event: IStatePreschool): void {
    this.statePreschool.emit(event);
  }

}
