import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { Permission } from '@core/enums/permissions'
import { AuthGuard } from '@core/guards/auth.guard'
import { PermissionGuard } from '@core/guards/permission.guard'
import { DrdpLayoutComponent } from '../drdp-layout/drdp-layout.component'
import { UploadCenterAllInstrumentChildComponent } from './upload-center/upload-center-all-instrument-child/upload-center-all-instrument-child.component'
import { UploadCenterComponent } from './upload-center/upload-center.component'
import { UploadCenterClassComponent } from './upload-center/upload-center-class/upload-center-class.component'
import { UploadCenterUserComponent } from './upload-center/upload-center-user/upload-center-user.component'

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    component: DrdpLayoutComponent,
    children: [ 
      { 
        path: 'upload-center', 
        canActivate: [PermissionGuard],
        data: { permission: Permission.UploadFile },
        component: UploadCenterComponent 
      },
      { 
        path: 'upload-center/child', 
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewChildTemplates },
        component: UploadCenterAllInstrumentChildComponent 
      },
      { 
        path: 'upload-center/class', 
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewChildTemplates },
        component: UploadCenterClassComponent 
      },
      { 
        path: 'upload-center/user', 
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewChildTemplates },
        component: UploadCenterUserComponent 
      }
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UploadRoutingModule {}
