<div class="w-max">
    <div class="flex justify-between border-b">
        <h1 class="text-[25px] font-bold">{{title}}</h1>
        <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
    </div>

    <div mat-dialog-content>
        <div class="content" [ngClass]="{'wide-content': wideContent}">
            <p [innerHTML]="message" class="pt-5 w-full"></p>
        </div>
    </div>
    <div mat-dialog-actions class="flex justify-end">
        <drdp-btn type="button" *ngIf="showCancel" class="mr-2" color="cancel" mat-button mat-dialog-close>{{cancelText}}</drdp-btn>
        <drdp-btn type="submit" [color]="buttonColor" mat-button [mat-dialog-close]="onSubmit(true)" cdkFocusInitial>{{buttonLabel}}</drdp-btn>
    </div>
</div>
