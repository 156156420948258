import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Components, Modules } from './module-helper';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '@core/pipes/pipes.module';
import { CoreModule } from '@core/core.module';

@NgModule({
  declarations: [Components],
  imports: [
    CommonModule,
    Modules,
    RouterModule,
    FormsModule,
    PipesModule.forRoot(),
    CoreModule,
  ],
  exports: [Components, Modules],
})
export class SharedModule {}
