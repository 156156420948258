<div class="h-full flex flex-col justify-between">
  <drdp-filter-select
    #reportActionSelect
    [label]="label"
    [multiSelect]="true"
    [options]="reportActionOptions"
    [selectType]="select.ReportAction"
    [required]="false"
    [disabled]="disabled"
    [isValid]="isValid"
    (onSelected)="emitSelectedReportAction($event)">
  </drdp-filter-select>
</div>
