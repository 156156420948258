import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUser } from '@core/interfaces/iuser';
import { AddEditSitesComponent } from '@views/user/configuration/sites/add-edit-sites/add-edit-sites.component';

@Component({
  selector: 'drdp-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})

export class ViewUserComponent implements OnInit {
  user: any;

  constructor(
    public dialogRef: MatDialogRef<AddEditSitesComponent>,
    @Inject(MAT_DIALOG_DATA) public viewData: any) {
    dialogRef.disableClose = true
  }

  ngOnInit(): void {
    this.parseViewData()
  }

  parseViewData(){
    this.user = this.viewData.data;
    this.user.roles = this.user?.roleName?.split(',');
  }

}
