import { Injectable } from '@angular/core';
import { IClassSearchRequest } from '@core/interfaces/iclasses';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { getSearchUrl } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class MyClassesService {

  constructor(private data: DataService) { }

  getUserClasses(params: any, message?: string):Observable<any>{
    const url = getSearchUrl('class/get-class?', params)
    return this.data.get(url, message);
  }
}
