import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'drdp-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

  @Input() texts:string[] =[]
  @Input() image?:string
  @Input() additionClasses?:string;
  @Input() color?: string;

  classes: any = {
    white: 'bg-white shadow-lg shadow-drdpblue-100 rounded-[20px] p-5',
    lightBlue: 'bg-drdpblue-200 rounded-[10px] p-2',
    whiteDash: 'bg-white rounded-[30px] drop-shadow-md flex items-start shadow-inner shadow-drdpblue-200',
  }

  constructor() {  }

  ngOnInit(): void {
  }

  getColor(type?: any){
    return this.classes[type] ? this.classes[type] : this.classes['white'];
  }

}
