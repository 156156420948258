import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { ILanguage } from '@core/interfaces/ilanguage';
import { SelectType } from '@core/enums/select';
import { LookupService } from '@core/services/lookup.service';
import { PermissionService } from '@core/services/permission.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent implements OnInit {
  @ViewChild('languageSelect') languageSelect: FilterSelectComponent | undefined;
  @Output() language = new EventEmitter<any>();
  @Input() initialLanguageId?: number | null;
  @Input() initialLanguageIds?: number[] | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() multiSelectInitVals: boolean = false;
  @Input() isValid = true;
  @Input() required = false;
  @Input() label = 'Language';
  languageOptions?: ILanguage[] = [];
  public get select() {
    return SelectType;
  }

  constructor(
    private lookupService: LookupService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.getLanguage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      if (this.multiSelect) {
        this.languageSelect?.clearAll();
      } else {
        this.languageSelect?.clearSearch(true);
      }
    }
  }

  clearSelected() {
    this.languageSelect?.clearSearch(true);
  }

  getLanguage(): void {

    this.lookupService.getLanguages().pipe(take(1)).subscribe((languages: ILanguage[]) => {
      this.languageOptions = languages
      if (this.initialLanguageIds) {
        const selected = this.languageOptions!.filter((language: ILanguage) =>
          this.initialLanguageIds?.includes(language.id)
        );
        this.language.emit(selected);
      }
    });
  }

  onLanguageSelect(event: ILanguage): void {
    this.language.emit(event);
  }
}
