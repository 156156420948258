<div class="h-full flex flex-col justify-between">
    <drdp-filter-select 
        #teacherSelect
        [label]="label" 
        [multiSelect]="multiSelect" 
        [options]="teacherOptions" 
        [selectType]="selectType"
        [required]="required" 
        [disabled]="disabled"
        [isValid]="isValid"
        [tooltipActive]="isTooltipActive"
        (onSelected)="onTeacherSelect($event)"
        [initialValues]="initialTeacherId || initialTeachersId">
    </drdp-filter-select>
</div>