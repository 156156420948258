import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IRoles } from '@core/interfaces/iroles';
import { RoleService } from '@core/services/role.service';

@Component({
  selector: 'drdp-permissions-filter',
  templateUrl: './permissions-filter.component.html',
  styleUrls: ['./permissions-filter.component.scss']
})
export class PermissionsFilterComponent implements OnInit {
  originalRolesList?: IRoles[];
  roleSet = new Set();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.buildRoleSet();
  }

  buildRoleSet(): void {
    const roleIds = this.data.roleId;
    this.originalRolesList = this.data.orignalRolesList;

    if (!roleIds.length && this.originalRolesList) {
      for(let role of this.originalRolesList) {
        this.roleSet.add(role.id);
      }
    } else {
      for(let id of roleIds) {
        this.roleSet.add(id);
      }
    }
  }

  toggleRoles(showAll: boolean): void {
    if (showAll && this.originalRolesList) {
      for(let role of this.originalRolesList) {
        this.roleSet.add(role.id);
      }
    } else {
      this.roleSet.clear();
    }
  }

  updateRoleSet(id: number): void {
   if (this.roleSet.has(id)) {
    this.roleSet.delete(id);
   } else {
    this.roleSet.add(id);
   }
  }

  closeModal(): any {
    return [...this.roleSet];
  }

}
