import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import {
  IAllStudentsByPermissionRequest,
  IClass,
  IClassSearchRequest,
  IClassroomAssignment,
  IUpdateClass,
  IViewClassStudentsRequest,
} from '@core/interfaces/iclasses';
import { getSearchUrl } from './helper.service';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';

@Injectable({
  providedIn: 'root',
})
export class ClassService {
  constructor(private dataService: DataService) {}

  getClassesByAgency(agencyId: number, message?: string): Observable<any> {
    return this.dataService.get(`class/agency/${agencyId}`);
  }

  search(params: IClassSearchRequest, message?: string): Observable<any> {
    const url = getSearchUrl('class/search?', params);
    return this.dataService.get(url, message);
  }

  saveAll(payload: IUpdateClass, message?: string) {
    return this.dataService.post(`class/saveAll`, payload, message);
  }

  deleteAll(classId: number, message?: string) {
    return this.dataService.delete(
      `class/deleteAll?classroomId=${classId}`,
      message
    );
  }

  getClassesBySitesAndRatingPeriod(
    payload: IClassSearchRequest,
    message?: string
  ): Observable<IClassroomAssignment[]> {
    return this.dataService.post('class/search/sites-rating-period', payload, message);
  }

  getClassesBySitesAndGlobalRatingPeriods(
    payload: IClassSearchRequest,
    message?: string
  ): Observable<IClassroomAssignment[]> {
    return this.dataService.post('class/search/sites-global-rating-period', payload, message);
  }

  getRatingPeriodsByClassroomId(
    classroomId: number
  ): Observable<IRatingPeriod[]> {
    return this.dataService.get(`class/rating-period/classroom/${classroomId}`);
  }

  getClassStudents(params: IViewClassStudentsRequest): Observable<any> {
    const url = getSearchUrl('class/students?', params);
    return this.dataService.get(url);
  }

  getActiveClassStudentsByPermissions(
    params: IViewClassStudentsRequest
  ): Observable<any> {
    const url = getSearchUrl('class/active-students/permissions?', params);
    return this.dataService.get(url);
  }

  getStudentsByClass(classId: number): Observable<any> {
    return this.dataService.get(`class/details/${classId}`);
  }

  getClassesByClassroom(classroomId: number): Observable<IClass[]> {
    return this.dataService.get(`class/classroomId/${classroomId}`);
  }

  getAllClassStudentsByPermissions(payload: IAllStudentsByPermissionRequest): Observable<any> {
    return this.dataService.post('class/all-students/permissions', payload);
  }
}
