import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'drdp-menu-link',
  templateUrl: './menu-link.component.html',
  styleUrls: ['./menu-link.component.scss']
})
export class MenuLinkComponent {

  @Input() label: string =''
  @Input() link: string =''
  @Input() icon!: string
  @Input() blue!:boolean


  constructor(private router:Router) { }

  

}
