import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { IGlobalRatingPeriod, IRatingPeriod, ISchoolYear } from '@core/interfaces/iratingperiod';
import { getSearchUrl } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class RatingPeriodService {
  schoolYearOptions: any[] = []

  constructor(private data: DataService) { }

  getRatingPeriod(startIndex: number, itemsPerPage: number): Observable<any> {
    return this.data.get(`ratingperiod/search?pageIndex=${startIndex}&itemsPerPage=${itemsPerPage}`)
  }

  getRatingPeriodWithParam (params:any) {
    const url = getSearchUrl('ratingperiod/search?', params);
    return this.data.get(url)
  };

  addRatingPeriod(payload: IRatingPeriod, message? :string):Observable<boolean>{
    return this.data.post('ratingperiod', payload, message)
  }

  updateRatingPeriod(payload: IRatingPeriod, message?: string): Observable<boolean> {
    return this.data.put('ratingperiod', payload, message)
  }

  getAllSchoolYears(): Observable<any>{
    return this.data.get(`schoolyear/all`)
  }

  getRecentSchoolYears(): Observable<any> {
    return this.data.get(`schoolyear/recent`);
  }

  getActiveSchoolYear(): Observable<any> {
    return this.data.get('schoolyear/active');
  }

  addSchoolYear(payload: ISchoolYear, message?: string): Observable<boolean> {
    return this.data.post(`schoolyear`, payload, message)
  }

  setActiveSchoolYear(year: string, message?: string): Observable<boolean> {
    return this.data.put(`schoolyear/active?year=${year}`, message)
  }

  getBySchoolYearAndAgency(schoolYearId: number, agencyId: number, message?: string): Observable<any> {
    return this.data.get(`ratingperiod/schoolyear/${schoolYearId}/agency/${agencyId}`, message)
  }

  getByAgency(agencyId: number, message?: string): Observable<any> {
    return this.data.get(`ratingperiod/agency/${agencyId}`, message)
  }

  getRecentGlobalRatingPeriods(amount: number): Observable<any> {
    return this.data.get(`globalratingperiod/recent/${amount}`);
  }

  addGlobalRatingPeriod(period: IGlobalRatingPeriod[], message?: string): Observable<boolean> {
    return this.data.post(`globalratingperiod`, period, message);
  }

  applyGlobalRatingPeriodsExisting(payload: any, message?: string): Observable<boolean> {
    return this.data.post(`globalratingperiod/apply-existing`, payload, message);
  }

  globalRatingsApplied(schoolYearId: number, message?: string): Observable<boolean> {
    return this.data.get(`globalratingperiod/is-applied/${schoolYearId}`, message)
  }

  getBySchoolYear(schoolYearId?: number, message?: string): Observable<any> {
    return this.data.get(`globalratingperiod/school-year/${schoolYearId}`, message)
  }

  getMinGlobalRatingPeriod(): Observable<IGlobalRatingPeriod> {
    return this.data.get('globalratingperiod/min-rating-period');
  }

  getFutureGlobalRatingPeriods(): Observable<any> {
    return this.data.get(`globalratingperiod/future-global-ratings`);
  }

  getAllGlobalRatingPeriods(): Observable<any> {
    return this.data.get(`globalratingperiod/all`);
  }
}
