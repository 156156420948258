export enum IepPlan {
    Yes = 1,
    No = 2,
    Unknown = 3
}

export enum IepPlanValues {
  Yes = 'Yes',
  No = 'No',
  Unknown = 'Unknown'
}