import { Component, OnInit, inject } from '@angular/core';
import { IReportSearch } from '../interfaces/ireport-search';
import { ReportService } from '@core/services/report.service';
import { take } from 'rxjs';
import { IRequiredReportFields } from '../interfaces/irequired-report-fields';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';

@Component({
  selector: 'drdp-cohort-progress',
  templateUrl: './cohort-progress.component.html',
  styleUrls: ['./cohort-progress.component.scss']
})
export class CohortProgressComponent implements OnInit {
  private reportService = inject(ReportService);
  private permissionService = inject(PermissionService);
  hasAccessToAssignedChildren = this.permissionService.checkPermission(
    Permission.AccessToAssignedChildrenReporting
  );
  reportData:  string | undefined;
  loading: boolean = false;
  requiredFields: IRequiredReportFields = {
    state: true,
    agency: !this.hasAccessToAssignedChildren,
    globalRatingPeriod: true,
    site: false,
    classroom: false,
    ageGroup: true,
    requireMultipleRatingPeriods: true
  }
  constructor() { }

  ngOnInit(): void {
  }
  showReport(searchValues: IReportSearch) {
    this.loading = true;
    if (!searchValues.locale) {
      searchValues.locale = 'en';
    }

    this.reportService
      .generateCohortProgressReport(searchValues)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.reportData = res;
        },
        complete: () => {
          this.loading = false;
        }
      });
  }

  downloadReport(searchValues: IReportSearch) {
    this.loading = true;
    this.reportService
      .generateCohortProgressReportPdf(searchValues)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          const file = new Blob([res], { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileUrl;
          let fileName = `cohort_progress_report`;
          link.download = `${fileName.replace(/ /g, '_')}.pdf`;
          link.click();
        },
        complete: () => {
          this.loading = false;
        }
      });
  }

  handleClear(): void {
    this.reportData = undefined;
  }
}
