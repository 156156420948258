import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ISchoolYear } from '@core/interfaces/iratingperiod';
import { IState } from '@core/interfaces/istate';
import { AuthService } from '@core/services/auth.service';
import { ReportService } from '@core/services/report.service';
import { ToastService } from '@core/services/toast.service';
import { SelectStateComponent } from '@shared/components/dropdowns/select-state/select-state.component';
import { take } from 'rxjs';
import Chart from 'chart.js/auto';
import { States } from '@core/enums/states';
@Component({
  selector: 'app-state-osep',
  templateUrl: './state-osep.component.html',
  styleUrls: ['./state-osep.component.css']
})
export class StateOsepComponent implements OnInit {
  @ViewChild('stateSelect') stateSelect: SelectStateComponent| undefined;
  loading = false;
  reportData:  string | undefined;
  reportService = inject(ReportService);
  auth = inject(AuthService);
  fb = inject(FormBuilder);
  toastService = inject(ToastService);
  user = this.auth.getCurrentUser();
  initStateId: number | null = this.user.stateId;
  searchForm: FormGroup | any;
  clearDropdown: boolean = false;
  get stateId() {
    return this.searchForm.get('stateId');
  }

  get schoolYearId() {
    return this.searchForm.get('schoolYearId');
  }
  constructor() { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm(): void {
    this.searchForm = this.fb.group({
      stateId: new FormControl<number | null>(this.initStateId, Validators.required),
      schoolYearId: new FormControl<number | null>(null, Validators.required),
    });
  }

  handleState(state: IState | null) {
    this.stateId.setValue(state?.id);
  }

  handleSchoolYear(schoolYear: ISchoolYear | null): void {
    this.schoolYearId.setValue(schoolYear?.id);
  }

  showReport() {
    if (this.searchForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }
    const searchValues = this.searchForm.value;
    if (searchValues.stateId !== States.newHampshire) {
      this.toastService.error('Only supported for New Hampshire state.');
      this.reportData = undefined;
      return;
    }
    this.loading = true;
    if (!searchValues.locale) {
      searchValues.locale = 'en';
    }
    searchValues.printPdf = false;
    this.reportService
      .generateStateOsepReport(searchValues)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.reportData = res.reportData;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  downloadReport() {
    if (this.searchForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }
    const searchValues = this.searchForm.value;
    if (searchValues.stateId !== States.newHampshire) {
      this.toastService.error('OSEP Data is for NH State only.');
      this.reportData = undefined;
      return;
    }
    searchValues.printPdf = true;
    this.loading = true;
    this.reportService
    .generateStateOsepReportPdf(searchValues)
    .pipe(take(1))
    .subscribe({
      next: (res) => {
        const file = new Blob([res], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        let fileName = `state_osep`;
        link.download = `${fileName.replace(/ /g, '_')}.pdf`;
        link.click();
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  downloadDetailReport() {
    if (this.searchForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }
    const searchValues = this.searchForm.value;
    if (searchValues.stateId !== States.newHampshire) {
      this.toastService.error('Only supported for New Hampshire state.');
      this.reportData = undefined;
      return;
    }
    searchValues.printPdf = true;
    this.loading = true;
    this.reportService
    .generateStateOsepReportDetail(searchValues)
    .pipe(take(1))
    .subscribe({
      next: (res) => {
        const fileName = 'OSEPStateDetail.xlsx';
        const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
      },
      complete: () => {
        this.loading = false;
      },
    });
  }


  handleClear(): void {
    this.clearDropdown = true;
    this.reportData = undefined;
    this.searchForm.reset();
    this.searchForm.patchValue({
        stateId: this.user.stateId
    });
    if (!this.stateSelect?.disabled) this.stateId.setValue(null);
  }

}
