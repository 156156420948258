<div class="h-full flex flex-col justify-between">
    <drdp-filter-select
      #requestResponseSelect
      [label]="label"
      [multiSelect]="false"
      [options]="studentRequestOptions"
      [selectType]="select.StudentRequest"
      [required]="false"
      [disabled]="disabled"
      [isValid]="isValid"
      (onSelected)="emitSelectedRequestReason($event)"
      [initialValues]="initialResponseRequestId">
    </drdp-filter-select>
  </div>