import { NgModule } from '@angular/core';
import { ContainsIdPipe } from './containsId.pipe';
import { OptionPipe } from './option.pipe';
import { GroupDemographicSectionPipe } from './group-demographic-section.pipe';
import { LocalDatePipe } from './local-date.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { FilterPipe } from './filter.pipe';

@NgModule({
  imports: [],
  declarations: [
    ContainsIdPipe,
    OptionPipe,
    GroupDemographicSectionPipe,
    LocalDatePipe,
    SafeHtmlPipe,
    FilterPipe,
  ],
  exports: [
    ContainsIdPipe,
    OptionPipe,
    GroupDemographicSectionPipe,
    LocalDatePipe,
    SafeHtmlPipe,
    FilterPipe
  ],
})
export class PipesModule {
  static forRoot() {
    return {
      ngModule: PipesModule,
      providers: [],
    };
  }
}
