<div class="h-full flex flex-col justify-between">
  <drdp-filter-select 
      #globalRatingPeriodSelect
      [label]="label" 
      [multiSelect]="multiSelect" 
      [options]="ratingPeriodOptions" 
      [selectType]="select.GlobalRatingPeriods"
      [required]="required" 
      [disabled]="disabled"
      [isValid]="isValid"
      (onSelected)="onRatingPeriodSelect($event)"
      [initialValues]="initialRatingPeriodId">
  </drdp-filter-select>
</div>
