import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'drdp-btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss']
})
export class BtnComponent implements OnInit, OnChanges {
  @Input() outside?: boolean = false;
  @Input() link?: any;
  @Input() disabled?: boolean = false;
  @Input() color?: string;
  @Input() additionalClasses?:string;
  @Output() eventListener = new EventEmitter<string>();
  @Input() type = "submit";
  routerLink?: any;
  btnClass: string = '';

  // add your specific styles here to be able to use getColor()
  classes: any = {
    outline: 'px-3 text-drdpblue-300 hover:bg-gray-300 hover:border-drdpblue-300 font-semibold cursor-pointer border-[0.1em] border-drdpblue-300',
    outlineRed: 'px-3 border-drdpred-100 text-drdpred-100 font-semibold cursor-not-allowed border-[0.1em]',
    red: 'px-3 bg-drdpred-100 border-drdpred-100 text-white hover:text-drdpred-100 hover:border-drdpred-100 hover:bg-transparent font-semibold cursor-pointer border-[0.1em]',
    cancel: 'px-3 bg-gray-200 text-black hover:bg-gray-300 font-semibold cursor-pointer border-[0.1em] border-gray-200',
    disabled: 'px-3 bg-gray-400 text-white border-0 pointer-events-none',
    default: 'px-3 bg-drdpblue-300 hover:text-drdpblue-300 hover:bg-transparent hover:border-drdpblue-300 text-white cursor-pointer border-[0.1em] border-drdpblue-300',
    update: 'mt-4 pl-5 text-xs text-drdpblue-400 hover:underline',
    defaultNoMargins: 'px-4 bg-drdpblue-300 hover:text-drdpblue-300 hover:bg-transparent hover:border-drdpblue-300 text-white cursor-pointer border-[0.1em] border-drdpblue-300',
    pillblue: 'py-1 px-2 rounded-full cursor-pointer transition duration-300 ease',
    pillgreen: 'py-1 px-2 rounded-full cursor-pointer transition duration-300 ease',
    black: 'px-3 bg-black border-black text-white font-semibold cursor-pointer border-[0.1em]',
    white: 'px-3 bg-white border-black text-black font-semibold cursor-pointer border-[0.1em]',
  }

  constructor() { }

  ngOnInit(): void {
    this.btnClass = this.getColor(this.color);
   }

  ngOnChanges(changes: SimpleChanges) {
    this.btnClass = this.disabled ? this.classes.disabled : this.getColor(this.color);
  }

  buttonClick() {
    if (this.link) {
      this.routerLink = this.link;
    }
  }

  // add your color in the color input, will check if it's in the classes obj
  getColor(type?: any) {
    if (this.disabled) {
      return this.classes['disabled'];
    }
    return this.classes[type] ? this.classes[type] : this.classes['default'];
  }

}
