import { Component, OnInit } from '@angular/core';
import {
  IGlobalRatingPeriod,
  ISchoolYear,
} from '@core/interfaces/iratingperiod';
import { RatingPeriodService } from '@core/services/rating-period.service';

@Component({
  selector: 'drdp-global-rating-periods',
  templateUrl: './global-rating-periods.component.html',
  styleUrls: ['./global-rating-periods.component.scss'],
})
export class GlobalRatingPeriodsComponent implements OnInit {
  years: ISchoolYear[] = [];
  globalRatingPeriods: IGlobalRatingPeriod[] = [];
  currentYear: any;
  currentPeriods: IGlobalRatingPeriod[] = [];
  pastYears: ISchoolYear[] = [];
  pastPeriods: IGlobalRatingPeriod[] = [];
  ratingAmount: number = 16;

  constructor(private ratingPeriodService: RatingPeriodService) {}

  ngOnInit(): void {
    this.ratingPeriodService.getRecentSchoolYears().subscribe((res) => {
      this.years = res;
      this.currentYear = res.filter((e: ISchoolYear) => e.isCurrent)[0];
      this.pastYears = res.filter((e: ISchoolYear) => !e.isCurrent);

      this.ratingPeriodService
        .getRecentGlobalRatingPeriods(this.ratingAmount)
        .subscribe((res) => {
          this.globalRatingPeriods = res;
          this.currentPeriods = res.filter(
            (e: IGlobalRatingPeriod) => e.schoolYearId == this.currentYear.id
          );
          this.pastPeriods = res.filter(
            (e: IGlobalRatingPeriod) => e.schoolYearId != this.currentYear.id
          );
        });
    });
  }
}
