import { Injectable } from '@angular/core';
import { IAdminSearchUsersRequest, IUser } from '@core/interfaces/iuser';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { getSearchUrl } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(public data: DataService) {}

  getUserById(id: number): Observable<IUser> {
    return this.data.get(`user/${id}`);
  }

  search(params: any) {
    const url = getSearchUrl('user/search?', params);
    return this.data.get(url);
  }

  createUser(payload: any, message?: string): Observable<number> {
    return this.data.post('user', payload, message);
  }

  updateUser(payload: any, message?: string): Observable<boolean> {
    return this.data.put('user', payload, message);
  }

  updateUserDemographics(payload: any, message?: string): Observable<boolean> {
    return this.data.put('user/user-update', payload, message);
  }

  globalSearch(request: IAdminSearchUsersRequest) {
    return this.data.post('user/search/admin-search-users', request);
  }

  getAdminUserDetails(userId: number): Observable<any> {
    return this.data.get(`user/search/admin-user-details/${userId}`);
  }

  adminUpdateUser(payload: any, message?: string): Observable<boolean> {
    return this.data.put('user/admin-update', payload, message);
  }

  getUserHighestAccessLevel(id: number): Observable<number> {
    return this.data.get(`user/highest-access-level/${id}`);
  }

  getUsersByClassEnrollment(id: number): Observable<any> {
    return this.data.get(`user/portfolio-message-users/${id}`);
  }

  getPortfolioMessageUsers(sceId: number): Observable<any> {
    return this.data.get(`user/portfolio-message-users/${sceId}`);
  }

  getSignUpStatus(): Observable<boolean> {
    return this.data.get('user/is-signed-up');
  }

  updateSignUpStatus(isSignedUp: boolean): Observable<boolean> {
    return this.data.put('user/sign-up-status', isSignedUp);
  }
}
