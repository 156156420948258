import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { ISuffix } from '@core/interfaces/isuffix';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'drdp-select-suffix',
  templateUrl: './select-suffix.component.html',
  styleUrls: ['./select-suffix.component.scss']
})
export class SelectSuffixComponent implements OnInit {
  @ViewChild('suffixSelect') statusSelect: FilterSelectComponent | undefined;
  @Output() suffix = new EventEmitter<any>();
  @Input() initialSuffix?: string | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  suffixOptions?: ISuffix[] = [];
  suffixId?: number;
  public get select() {return SelectType }
  constructor(private lookupService: LookupService) { }

  ngOnInit(): void {
    this.getSuffix();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) this.statusSelect?.clearSearch(true);
    if (this.initialSuffix) this.getSuffix();
  }

  getSuffix(): void {
    this.lookupService.getSuffix()
      .pipe(
        take(1)
      )
      .subscribe((suffix: ISuffix[]) => {
        this.suffixOptions = suffix;
        if (this.initialSuffix) {
          const findId = this.suffixOptions.find((suffix: ISuffix) => suffix.suffixName == this.initialSuffix);
          this.suffixId = findId?.id;
        }
      });
  }

  onStatusSelect(event: ISuffix): void {
    this.suffix.emit(event);
  }
}
