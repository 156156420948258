import { Injectable } from '@angular/core';
import { INewsItem, INewsItemRequest } from '@core/interfaces/inews-items';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class NewsItemService {
  constructor(private data: DataService) {}

  getAllItems(): Observable<any> {
    return this.data.get('newsitem/all');
  }

  getRecentItems(itemCount: number): Observable<boolean> {
    return this.data.get(`newsitem/recent/${itemCount}`);
  }

  getUserActiveNewsItem(itemCount: number): Observable<boolean> {
    return this.data.get(`newsitem/user/active/${itemCount}`);
  }

  getNewsItemById(id: number): Observable<INewsItemRequest> {
    return this.data.get(`newsitem/${id}`);
  }

  addNewsItem(payload: INewsItem): Observable<boolean> {
    return this.data.post('newsitem', payload);
  }

  updateNewsItem(payload: INewsItem): Observable<boolean> {
    return this.data.put('newsitem', payload);
  }

  deleteNewsItem(id: number, message?: string): Observable<boolean> {
    return this.data.delete(`newsitem/${id}`, message);
  }
}
