import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewsItemService } from '@core/services/news-item.service';
import { NewsDescriptionComponent } from '@views/user/dashboard/latest-news/news-description/news-description.component';
import { Subscription } from 'rxjs';
import { PermissionService } from '@core/services/permission.service';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';

declare interface RouteInfo {
  agency: string;
  title: string;
  description: string;
  btnColor: string;
  name: string;
  url: string;
}

@Component({
  selector: 'drdp-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss'],
})
export class NewsItemComponent implements OnInit {
  newsItems: any[] = [];
  newsItemClass: any = {
    WestEd: 'text-drdpblue-400 bg-drdpblue-500 hover:bg-drdpblue-200',
    Agency: 'text-drdpGreen-300 bg-drdpGreen-200 hover:bg-drdpGreen-100',
    UndefinedType: '',
  };
  canEdit: boolean = false;
  user = this.authService.getCurrentUser();

  constructor(
    public newsItemService: NewsItemService,
    public modal: MatDialog,
    public PermissionService: PermissionService,
    public authService: AuthService,
    private router: Router,
  ) {}

  private subscriptions = new Subscription();

  ngOnInit(): void {
    this.subscriptions.add(
      this.newsItemService.getRecentItems(2).subscribe((res: any) => {
        if (res) {
          this.newsItems = res;
        }
      })
    );
  }

  toggleModal(data: any): void {
    const modalRef = this.modal.open(NewsDescriptionComponent, {
      data: data ? data : undefined,
    });
  }

  editNewsItem(news: any): void {
    this.modal.closeAll();
    this.router.navigate([`/newsitems/${news.id}`])
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
