<div class="h-full flex flex-col justify-between">
    <drdp-filter-select
        #codeSelect
        [label]="label"
        [multiSelect]="multiSelect"
        [options]="codeOptions"
        [selectType]="select.CodeType"
        [required]="required"
        [disabled]="disabled"
        (onSelected)="onCodeSelect($event)"
        [isValid]="isValid"
        [initialValues]="initialCodeId">
    </drdp-filter-select>
</div>
