import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { ReportService } from '@core/services/report.service';
import { AuthService } from '@core/services/auth.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SelectAgencyComponent } from '@shared/components/dropdowns/select-agency/select-agency.component';
import { SelectStateComponent } from '@shared/components/dropdowns/select-state/select-state.component';
import { ToastService } from '@core/services/toast.service';
import { IState } from '@core/interfaces/istate';
import { IAgency } from '@core/interfaces/iagency';
import { ISites } from '@core/interfaces/isites';
import { ISchoolYear } from '@core/interfaces/iratingperiod';
import { take } from 'rxjs';
import Chart from 'chart.js/auto';
import { States } from '@core/enums/states';
@Component({
  selector: 'app-district-osep',
  templateUrl: './district-osep.component.html',
  styleUrls: ['./district-osep.component.css'],
})
export class DistrictOsepComponent implements OnInit {
  @ViewChild('agencySelect') agencySelect: SelectAgencyComponent | undefined;
  @ViewChild('stateSelect') stateSelect: SelectStateComponent | undefined;
  loading = false;
  reportData: string | undefined;
  reportService = inject(ReportService);
  auth = inject(AuthService);
  fb = inject(FormBuilder);
  toastService = inject(ToastService);
  user = this.auth.getCurrentUser();
  initStateId: number | null = this.user.stateId;
  initAgencyId: number | null = this.user.agencyId;
  searchForm: FormGroup | any;
  clearDropdown: boolean = false;
  chartInstances:any[]= [];

  get stateId() {
    return this.searchForm.get('stateId');
  }

  get agencyId() {
    return this.searchForm.get('agencyId');
  }

  get siteIds() {
    return this.searchForm.get('siteIds');
  }

  get schoolYearId() {
    return this.searchForm.get('schoolYearId');
  }

  constructor() {}

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm(): void {
    this.searchForm = this.fb.group({
      stateId: new FormControl<number | null>(
        this.initStateId,
        Validators.required
      ),
      agencyId: new FormControl<number | null>(
        this.initAgencyId,
        Validators.required
      ),
      schoolYearId: new FormControl<number | null>(null, Validators.required),
      siteIds: new FormControl<number[] | null>(null, Validators.required),
    });
  }

  handleState(state: IState | null) {
    this.stateId.setValue(state?.id);
    this.agencyId.setValue(null);
  }

  handleAgency(agency: IAgency | null): void {
    this.agencyId.setValue(agency?.id);
  }

  handleSchoolYear(schoolYear: ISchoolYear | null): void {
    this.schoolYearId.setValue(schoolYear?.id);
  }

  handleSite(sites: ISites | ISites[] | null): void {
    if (!!sites && !('length' in sites)) {
      sites = [sites];
    }
    const selectedSiteIds =
      !!sites && sites?.length > 0
        ? sites?.map((c: { id: number }) => c.id)
        : null;
    this.siteIds.setValue(selectedSiteIds);
  }

  showReport() {
    if (this.searchForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }
    const searchValues = this.searchForm.value;
    if (searchValues.stateId !== States.newHampshire) {
      this.toastService.error('Only supported for New Hampshire state.');
      this.reportData = undefined;
      return;
    }
    this.loading = true;
    if (!searchValues.locale) {
      searchValues.locale = 'en';
    }
    searchValues.printPdf = false;
    this.reportService
      .generateDistrictOsepReport(searchValues)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res) {
            this.reportData = res.reportData;
            setTimeout(() => {
              res.outcomes.forEach((outcome: any) => {
                this.createBarChart(outcome.categoryChartName, outcome.barCount, outcome.outcomeCategoryTotal);
              });
              const barContainers = document.querySelectorAll('.bar-container');
              barContainers.forEach((element: any) => {
                element.style.height = '100%';
                element.style.position = 'relative';
              });
            }, 1000);
        }
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  createBarChart(id: string, chartData: any, maxCount: number) {
    const chartElement = document.getElementById(id) as HTMLCanvasElement;
    if (chartElement === null) return;
    const ctx: CanvasRenderingContext2D | null = chartElement?.getContext('2d');
    if (ctx === null) return;

    const existingChartIndex = this.chartInstances.findIndex(chart => chart.canvas === chartElement);
    if (existingChartIndex !== -1) {
      this.chartInstances[existingChartIndex].destroy();
      this.chartInstances.splice(existingChartIndex, 1);
    }

    const refChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['A', 'B', 'C', 'D', 'E'],
        datasets: [
          {
            label: '',
            data: chartData,
            backgroundColor: 'black',
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            ticks: {
              stepSize: 1,
            },
            suggestedMax: maxCount,
          },
        },
      },
    });

    // Store the new chart instance for later destruction
    if (!this.chartInstances) {
      this.chartInstances = [];
    }
    this.chartInstances.push(refChart);
  }

  downloadReport() {
    if (this.searchForm.invalid) {
      this.toastService.error('Please enter all required fields.');
      return;
    }
    const searchValues = this.searchForm.value;
    if (searchValues.stateId !== States.newHampshire) {
      this.toastService.error('OSEP Data is for NH State only.');
      this.reportData = undefined;
      return;
    }
    this.loading = true;
    searchValues.printPdf = true;
    this.reportService
      .generateDistrictOsepReportPdf(searchValues)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          const file = new Blob([res], { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileUrl;
          let fileName = `district_osep`;
          link.download = `${fileName.replace(/ /g, '_')}.pdf`;
          link.click();
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  handleClear(): void {
    this.clearDropdown = true;
    this.reportData = undefined;
    this.searchForm.reset();
    this.searchForm.patchValue({
      stateId: this.user.stateId,
      agencyId: this.user.agencyId ?? null,
    });
    if (!this.stateSelect?.disabled) this.stateId.setValue(null);
    if (!this.agencySelect?.disabled) this.agencyId.setValue(null);
  }
}
