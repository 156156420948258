<div class="h-full flex flex-col justify-between">
  <drdp-filter-select
    #raceSelect
    [label]="label"
    [multiSelect]="multiSelect"
    [options]="raceOptions"
    [selectType]="select.Ethnicities"
    [required]="required"
    [isValid]="isValid"
    (onSelected)="onRaceSelect($event)"
    [initialValues]="initialRaceId || initialRaceIds"
  >
  </drdp-filter-select>
</div>


