import { Injectable } from '@angular/core';
import { IChildTemplateUpload } from '@core/interfaces/IdownloadTemplate';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadTemplateService {
  constructor(private dataService: DataService) {}

  downloadTemplate(stateId: number, uploadType: number, options?: any): Observable<any> {
    return this.dataService.getWithOptions(`template/${stateId}/${uploadType}`, options);
  }

  uploadTemplate(upload: FormData, message?: string): Observable<any> {
    return this.dataService.post(`template/upload`, upload, message);
  }
}
