import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  IPermission,
  IPermissionAssignment,
  IPermissionGroupAssignmentRes,
} from '@core/interfaces/ipermission';
import { IRoles } from '@core/interfaces/iroles';

@Component({
  selector: 'drdp-permissions-management',
  templateUrl: './permissions-management.component.html',
  styleUrls: ['./permissions-management.component.scss'],
})
export class PermissionsManagmentComponent implements OnInit {
  @Output() permissionAssignment = new EventEmitter<IPermissionAssignment>();
  @Input() assignmentList?: IPermissionAssignment[];
  @Input() group?: IPermissionGroupAssignmentRes;
  @Input() roles?: IRoles[] | null;
  @Input() isUncategorized = false;
  @Input() uncategorizedList?:  IPermission[];
  show = false;

  constructor() {}

  ngOnInit(): void {}

  checkAssignment(permissionId: number, roleId: number): boolean {
    const assignment = this.assignmentList?.find(
      (x) => x.permissionId === permissionId && x.roleId === roleId
    );

    return !!assignment;
  }

  emitPermissionAssignment(permissionId: number, roleId: number): void {
    let assignment = this.assignmentList?.find(
      (x) => x.permissionId === permissionId && x.roleId === roleId
    );

    if (assignment) {
      assignment.isDeleted = !assignment.isDeleted;
      this.permissionAssignment.emit(assignment);
    } else {
      let newAssignment: IPermissionAssignment = {
        id: 0,
        roleId: roleId,
        permissionId: permissionId,
        isDeleted: false,
      };
      this.permissionAssignment.emit(newAssignment);
    }
  }

  changeShow() {
    this.show = !this.show;
  }
}
