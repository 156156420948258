import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectType } from '@core/enums/select';
import { States } from '@core/enums/states';
import { ITeacherSurveyRequest } from '../interfaces/iadmin-report-tracking';
import { ReportService } from '@core/services/report.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-teacher-survey',
  templateUrl: './teacher-survey.component.html',
  styleUrls: ['./teacher-survey.component.scss']
})
export class TeacherSurveyComponent implements OnInit {
  searchForm: FormGroup | any;
  allAgencies: boolean = false;
  selectType = SelectType;
  clearDropdown: boolean = false;

  get stateId() { return this.searchForm.get('stateId'); }
  get agencyId() { return this.searchForm.get('agencyId'); }
  get siteIds() { return this.searchForm.get('siteIds'); }
  get classroomIds() { return this.searchForm.get('classroomIds'); }
  get schoolYearId() { return this.searchForm.get('schoolYearId'); }
  get ratingPeriodId() { return this.searchForm.get('ratingPeriodId'); }

  fb = inject(FormBuilder);
  reportService = inject(ReportService);
  
  constructor() { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.searchForm = this.fb.group({
      stateId: [States.california, Validators.required],
      agencyId: [null, Validators.required],
      schoolYearId: [null, Validators.required],
      ratingPeriodId: [null, Validators.required],
      siteIds: [null, Validators.required],
      classroomIds: [null],
    });
  }

  handleAll(): void {
    this.allAgencies = !this.allAgencies;
    this.agencyId.setValue(null);
    this.siteIds.setValue(null);
    this.classroomIds.setValue(null); 
    this.clearDropdown = true;
    if (this.allAgencies) {
      this.agencyId.clearValidators();
      this.siteIds.clearValidators();
    } else {
      this.agencyId.setValidators([Validators.required]);
      this.siteIds.setValidators([Validators.required]);
    }
    this.agencyId.updateValueAndValidity();
    this.siteIds.updateValueAndValidity();
    this.classroomIds.updateValueAndValidity();
  }

  handleDropdown(event: any, type: string): void {
    switch (type) {
      case SelectType.States:
        this.stateId.setValue(event?.id);
        break;
      case SelectType.Agencies:
        this.agencyId.setValue(event?.id);
        this.siteIds.setValue(null);
        this.classroomIds.setValue(null);
        break;
      case SelectType.SchoolYear:
        this.schoolYearId.setValue(event?.id);
        this.ratingPeriodId.setValue(null);
        break;
      case SelectType.RatingPeriods:
        this.ratingPeriodId.setValue(event?.id);
        this.siteIds.setValue(null);
        this.classroomIds.setValue(null);
        break;
      case SelectType.Sites:
        const siteIds = event?.map((sites: any) => sites.id);
        this.siteIds.setValue(siteIds);
        this.classroomIds.setValue(null);
        break;
      case SelectType.Class:
        const classroomIds = event?.map((classroom: any) => classroom.id);
        this.classroomIds.setValue(classroomIds);
        break;
      default:
        break;
    }
  }

  handleDownload(): void {
    let payload: ITeacherSurveyRequest = this.searchForm.value;
    this.reportService.getTeacherSurveyResults(payload).pipe(take(1)).subscribe(res => {
      const fileName = 'TeacherSurveyResults.xlsx';
      const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    });
  }
}
