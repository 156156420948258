import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export class CustomValidators {
  static isNumber(control: AbstractControl) {
    if (!control.value) return null;

    return String(control.value).match(/^[0-9.]+$/)
      ? null
      : { isNumbers: true };
  }

  static cannotContainWhiteSpace(control: AbstractControl) {
    if (!control.value) return null;

    if ((control.value as string).trim() === '') {
      return { cannotContainSpace: true };
    }

    return null;
  }

  static dateValidator(
    startDateProp: string,
    endDateProp: string
  ): ValidationErrors | null {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const startDateValue = formGroup.get(startDateProp)?.value;
      const endDateValue = formGroup.get(endDateProp)?.value;

      if (startDateValue && endDateValue) {
        // Extract date parts from the date strings and compare
        const startDate = new Date(startDateValue);
        const endDate = new Date(endDateValue);

        const startOnlyDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        );
        const endOnlyDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate()
        );

        if (startOnlyDate > endOnlyDate) {
          return { dateMismatch: true };
        }
      }

      return null;
    };
  }

  static sameDateValidator(
    startDateProp: string,
    endDateProp: string
  ): ValidationErrors | null {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const startDateValue = formGroup.get(startDateProp)?.value;
      const endDateValue = formGroup.get(endDateProp)?.value;

      if (startDateValue && endDateValue) {
        // Extract date parts from the date strings and compare
        const startDate = new Date(startDateValue);
        const endDate = new Date(endDateValue);

        const startOnlyDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        );
        const endOnlyDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate()
        );

        if (startOnlyDate.getTime() == endOnlyDate.getTime()) {
          return { sameDate: true };
        }
      }

      return null;
    };
  }

  static decimalValidator(control: AbstractControl):ValidationErrors | null {
    if (!control.value) return null;

    return String(control.value).match(/^\d+(\.\d{1,2})?$/)
    ? null
    : { invalidDecimal: true };
  }
}
