import { Injectable } from '@angular/core';
import { IMenuItem } from '@core/interfaces/side-menu';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class SideMenuService {
  constructor(private data: DataService) {}

  getAll(): Observable<IMenuItem[]> {
    return this.data.get('menuitem/all');
  }

  getById(id: number): Observable<IMenuItem> {
    return this.data.get(`menuitem/${id}`);
  }

  getFormatted(): Observable<IMenuItem[]> {
    return this.data.get('menuitem/formatted');
  }

  create(payload: IMenuItem): Observable<boolean> {
    return this.data.post('menuitem', payload);
  }

  update(payload: IMenuItem): Observable<boolean> {
    return this.data.put('menuitem', payload);
  }

  delete(id: number): Observable<boolean> {
    return this.data.delete(`menuitem/${id}`);
  }
}
