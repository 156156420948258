import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectType } from '@core/enums/select';
import { IAgeGradeInstrument } from '@core/interfaces/iage-grade-instrument';
import { AuthService } from '@core/services/auth.service';
import { ReportService } from '@core/services/report.service';
import { IChildRatingsRequest } from '../../interfaces/iadmin-report-tracking';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-child-ratings',
  templateUrl: './child-ratings.component.html',
  styleUrls: ['./child-ratings.component.scss']
})
export class ChildRatingsComponent implements OnInit {
  user = this.authService.getCurrentUser();
  childRatingsSearch: FormGroup | any;
  clearDropdowns: boolean = false;
  allAgencies: boolean = false;
  btnClass: string = 'lg:col-span-1';
  selectType = SelectType;
  startingSchoolYear = '2019'

  get stateId() { return this.childRatingsSearch.get('stateId'); }
  get agencyIds() { return this.childRatingsSearch.get('agencyIds'); }
  get schoolYearId() { return this.childRatingsSearch.get('schoolYearId'); }
  get globalRatingPeriodId() { return this.childRatingsSearch.get('globalRatingPeriodId'); }

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private reportService: ReportService) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.childRatingsSearch = this.fb.group({
      stateId: [this.authService.getUserStateId(), Validators.required],
      agencyIds: [this.authService.getCurrentUser().agencyId ?? null],
      schoolYearId: [null],
      globalRatingPeriodId: [null]
    });
  }

  handleDropdown(event: any, type: string): void {
    switch (type) {
      case SelectType.States:
        this.stateId.setValue(event?.id);
        this.agencyIds.setValue(null);
        break;
      case SelectType.Agencies:
        this.agencyIds.setValue(event?.id);
        break;
      case SelectType.SchoolYear:
        this.schoolYearId.setValue(event?.id);
        break;
      case SelectType.RatingPeriods:
        this.globalRatingPeriodId.setValue(event?.id.toString());
        break;
    }
  }

  handleDownload(): void {
    let payload: IChildRatingsRequest = this.childRatingsSearch.value;
    this.reportService.getChildRatings(payload).pipe(take(1)).subscribe((res: any) => {
      const fileName = 'ChildRatings.xlsx';
      const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    });
  }


  handleGlobal(): void {
    this.allAgencies = !this.allAgencies;
    this.btnClass = this.allAgencies ? 'lg:col-span-2 items-end' : 'lg:col-span-1';
    this.agencyIds.setValue(this.allAgencies ? null : this.user.agencyId ?? null);
  }
}
