<div class="h-full flex flex-col justify-between al">
  <drdp-filter-select
    #selpaSelect
    label="SELPAS"
    [multiSelect]="multiSelect"
    [options]="selpaOptions"
    [selectType]="select.Selpa"
    [required]="required"
    [disabled]="disabled"
    (onSelected)="onSelpaSelect($event)"
    [isValid]="isValid"
    [initialValues]="initialSelpaId"
    [additionalClasses]="additionalClasses"
  >
  </drdp-filter-select>
</div>
