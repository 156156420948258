import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectType } from '@core/enums/select';
import { IStudentActiveEnrollmentInfo } from '@views/user/input-rating/interfaces/istudent-enrollment-info';
import { MatDialogRef } from '@angular/material/dialog';
import { PortfolioService } from '@core/services/portfolio.service';
import { take } from 'rxjs';
import { ToastService } from '@core/services/toast.service';
import {  IPortfolioStudentInformation } from '@core/interfaces/iportfolio';

@Component({
  selector: 'app-assign-enrollment',
  templateUrl: './assign-enrollment.component.html',
  styleUrls: ['./assign-enrollment.component.css']
})
export class AssignEnrollmentComponent implements OnInit {
  availableOptions: IStudentActiveEnrollmentInfo[] = [];
  evidenceId?: number;
  selectedEnrollmentId?: number;
  info: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AssignEnrollmentComponent>, private portfolioService: PortfolioService) { }

  public get select() {
    return SelectType;
  }

  ngOnInit() {
    this.availableOptions = this.data.availableClasses;
    this.evidenceId = this.data.evidenceId;
    this.info = this.data.info;
  }

  assignEvidence() {
    let payload : IPortfolioStudentInformation= {
      studentId: this.info.studentId,
      studentClassEnrollmentId: this.selectedEnrollmentId || 0,
      futureEvidenceId: this.info.futureEvidenceId,
      ageGradeInstrumentId: this.info.ageGradeInstrumentId,
      evidenceId: this.evidenceId || 0,
      measures: [],
      domains: []
    };

    this.portfolioService
        .attachFutureEvidence(payload)
        .pipe(take(1))
        .subscribe((res: any) => {
          if (res) {
            this.dialogRef.close(true);
          }
        });
  }

  onSelect(event: any) {
    this.selectedEnrollmentId = event.id;
  }

}
