import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { ISiteType } from '@core/interfaces/isites';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'drdp-select-type',
  templateUrl: './select-type.component.html',
  styleUrls: ['./select-type.component.scss']
})
export class SelectTypeComponent implements OnInit {
  @ViewChild('typeSelect') typeSelect: FilterSelectComponent | undefined;
  @Output() type = new EventEmitter<any>();
  @Input() initialTypeId?: number | null;
  @Input() clear: any;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() required: boolean = false;
  typeOptions?: ISiteType[];

  public get select() {return SelectType }
  constructor(private lookup: LookupService) { }

  ngOnInit(): void {
    this.getSiteType();
  }

  clearSelected() {
    this.typeSelect?.clearSelected();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      this.typeSelect?.clear();
    }
  }

  getSiteType(): void {
    this.lookup.getSiteTypes()
      .pipe(take(1))
      .subscribe((types: ISiteType[]) => this.typeOptions = types);
  }

  onTypeSelect(event: ISiteType): void {
    this.type.emit(event);
  }
}
