import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'drdp-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

  constructor() { }
  @Input() title?: string;
  @Input() additionalClasses?: string = 'text-3xl'
  ngOnInit(): void {}

}
