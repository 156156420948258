import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'drdp-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
    @Input() url!: string | ArrayBuffer | SafeResourceUrl;
    @Input() edit: boolean = false;
  constructor() { }

  ngOnInit() {
  }
}
