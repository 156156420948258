import { Component, OnInit, Inject, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IStudentDetails } from '@core/interfaces/istudent';
import { StudentService } from '@core/services/student.service';
import { ViewChildTabsComponent } from './view-child-tabs/view-child-tabs.component';

@Component({
    selector: 'drdp-view-child',
    templateUrl: './view-child.component.html',
    styleUrls: ['./view-child.component.scss']
})


export class ViewChildComponent implements OnInit {
  @ViewChild('tabs') viewTabs: ViewChildTabsComponent | undefined;
  childData?: any;
  studentId?: number | any = this.getChildIdFromUrl();

  constructor( private route: ActivatedRoute, private studentService: StudentService){}

  ngOnInit(): void {
    this.setChildDataInView();
  }

  getChildIdFromUrl(): number {
    const id = this.route.snapshot.paramMap.get('id');
    return Number(id);
  }

  setChildDataInView() {
    this.studentService.getStudentDetails(this.studentId).subscribe((res: IStudentDetails) => {
      this.childData = res;
    })
  }

}
