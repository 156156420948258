import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';

@Component({
  selector: 'drdp-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements AfterViewChecked {
  isLoading: boolean = false

  constructor(private spinner: SpinnerService, private cdRef: ChangeDetectorRef) { }

  ngAfterViewChecked() {
    this.spinner.getSpinnerCounter().subscribe((counter: number) => {
      if (counter > 0) {
        this.isLoading = true;
        this.cdRef.detectChanges();
      } else {
        this.isLoading = false;
      }
    });
  }
}
