import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DownloadsRoutingModule } from './downloads-routing.module'
import { SharedModule } from '@shared/shared.module'
import { FormsModule,ReactiveFormsModule } from '@angular/forms'
import { CoreModule } from '@core/core.module'
import { PipesModule } from '@core/pipes/pipes.module';
import { DownloadDemographicsAllComponent } from './download-demographics-all/download-demographics-all.component'
import { DownloadDemographicsScoresComponent } from './download-demographics-scores/download-demographics-scores.component'
import { DownloadDemographicsRatingsComponent } from './download-demographics-ratings/download-demographics-ratings.component'
import { DownloadDemographicsComponent } from './download-demographics/download-demographics.component';
import { DownloadSearchComponent } from './download-search/download-search.component';
import { DownloadDemographicsAllSPHSComponent } from './download-demographics-all-sp-hs/download-demographics-all-sp-hs.component';
import { DownloadDemographicsRatings2025Component } from './download-demographics-ratings2025/download-demographics-ratings2025.component';


@NgModule({
  declarations: [
    DownloadDemographicsComponent,
    DownloadDemographicsRatingsComponent,
    DownloadDemographicsScoresComponent,
    DownloadDemographicsAllComponent,
    DownloadSearchComponent,
    DownloadDemographicsAllSPHSComponent,
    DownloadDemographicsRatings2025Component
  ],
  imports: [
    CommonModule,
    DownloadsRoutingModule,
    SharedModule,
    FormsModule,
    CoreModule,
    ReactiveFormsModule,
    PipesModule.forRoot()
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DownloadsModule {}
