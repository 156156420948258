import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { NewsItemService } from '@core/services/news-item.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, take } from 'rxjs';
import { ToastService } from '@core/services/toast.service';
import { SelectType } from '@core/enums/select';
import { LookupService } from '@core/services/lookup.service';
import {
  INewsItem,
  INewsItemRequest,
  INewsItemType,
} from '@core/interfaces/inews-items';
import { AuthService } from '@core/services/auth.service';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { ISites } from '@core/interfaces/isites';
import { SiteService } from '@core/services/site.service';
import { RoleService } from '@core/services/role.service';
import { IRoles } from '@core/interfaces/iroles';
import { ActivatedRoute, Router } from '@angular/router';
import {
  formatDatesToString,
  formatToDate,
} from '@core/services/helper.service';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { Modal } from '@core/enums/modal';
import { CustomValidators } from '@core/validators/custom.validator';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';

@Component({
  selector: 'drdp-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class AddNewsComponent implements OnInit {
  private subscriptions = new Subscription();
  get newsItemDate() {
    return this.newsItemForm.get('newsItemDate');
  }
  get newsItemEndDate() {
    return this.newsItemForm.get('newsItemEndDate');
  }
  get newsItemTitle() {
    return this.newsItemForm.get('newsItemTitle');
  }
  get newsItem() {
    return this.newsItemForm.get('newsItem');
  }
  get newsItemTypeId() {
    return this.newsItemForm.get('newsItemTypeId');
  }
  get agencyIds() {
    return this.newsItemForm.get('agencyIds');
  }
  get siteIds() {
    return this.newsItemForm.get('siteIds');
  }
  get roleIds() {
    return this.newsItemForm.get('roleIds');
  }
  get isGlobal() {
    return this.newsItemForm.get('isGlobal');
  }

  @ViewChild('newsTypeSelect') newsTypeSelect!: FilterSelectComponent;
  @ViewChild('siteSelect') siteSelect!: FilterSelectComponent;

  required: string = 'Field is required.';
  selectType = SelectType;

  selectNewsItemTypes = SelectType.NewsItemTypes;
  newsItemForm: FormGroup | any;
  newItemsList?: INewsItem[];
  newsItemTypes: any[] = [];
  user: ILoginResponse = this.authService.getCurrentUser();
  stateSearchId: number = this.user.stateId;
  clearDropdowns: boolean = false;
  sites!: ISites[];
  roles!: IRoles[];
  newsItemTypeInitialValue: number = 0;
  agencyInitialValues: any = [this.user.agencyId];
  siteInitialValues: number[] = [];
  id = 0;
  router = inject(Router);
  permissionService = inject(PermissionService);
  dateInfo = 'Start date is for the day you want the News Item posted and End Date is for the day you want the News Item to archive.';
  resetDate?: boolean = false;
  canManageNewsItem = this.permissionService.checkPermission(
    Permission.CreateNewsItem
  );
  public get Permission() {
    return Permission;
  }

  constructor(
    private fb: FormBuilder,
    private newsItemService: NewsItemService,
    public toastService: ToastService,
    private lookupService: LookupService,
    private authService: AuthService,
    private sitesService: SiteService,
    private RoleService: RoleService,
    private route: ActivatedRoute,
    public modal: MatDialog
  ) { }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.initializeForms();
    this.getNewsItemsTypes();
    this.getUserRoles();
    this.getNewItems();
  }

  initializeForms(): void {
    this.newsItemForm = this.fb.group(
      {
        newsItemTypeId: [null, [Validators.required]],
        newsItemDate: [formatDatesToString(new Date()), [Validators.required]],
        newsItemEndDate: [null, [Validators.required]],
        newsItemTitle: [null, [Validators.required, Validators.max(100)]],
        newsItem: [null, [Validators.required]],
        agencyIds: [this.agencyInitialValues, [Validators.required]],
        roleIds: [null, [Validators.required]],
        siteIds: [null, [Validators.required]],
        isGlobal: [false, Validators.required],
      },
      {
        validators: [
          CustomValidators.dateValidator('newsItemDate', 'newsItemEndDate'),
          CustomValidators.sameDateValidator('newsItemDate', 'newsItemEndDate'),
        ],
      }
    );

    if (this.id) {
      this.newsItemService
        .getNewsItemById(this.id)
        .pipe(take(1))
        .subscribe((res: INewsItemRequest) => {
          if (res) {
            this.newsItemForm.patchValue({
              newsItemTypeId: res.newsItemTypeId,
              newsItemDate: new Date(res.newsItemDate),
              newsItemEndDate: new Date(res.newsItemEndDate),
              newsItemTitle: res.newsItemTitle,
              newsItem: res.newsItemBody,
              agencyIds: res.agencies,
              isGlobal: res.isGlobal,
              roleIds:
                res.newsItemRoles && res.newsItemRoles.length > 0
                  ? res.newsItemRoles.map((newsItemRole: any) => {
                    return newsItemRole.roleId;
                  })
                  : [],
              siteIds:
                res.newsItemSites && res.newsItemSites.length > 0
                  ? res.newsItemSites.map((newsItemSite: any) => {
                    return newsItemSite.siteId;
                  })
                  : [],
            });

            this.newsItemTypeInitialValue = res.newsItemTypeId;
            this.agencyInitialValues = this.agencyIds.value;
            this.siteInitialValues = this.siteIds.value;
            this.handleGlobal();
            var agencyIdObjectArr: any[] = [];
            var val = this.agencyIds.value;
            if (Array.isArray(val)) {
              val.forEach((item) => agencyIdObjectArr.push({ id: item }));
            }

            if (agencyIdObjectArr.length > 0)
              this.handleAgency(agencyIdObjectArr);
          } else {
            this.toastService.error(
              'Something went wrong posting your news item. Please try again.'
            );
          }
        });
    } else {
      var agencyIdObjectArr: any[] = [];
      var val = this.agencyIds.value;
      if (Array.isArray(val)) {
        val.forEach((item) => agencyIdObjectArr.push({ id: item }));
      }

      if (agencyIdObjectArr.length > 0) this.handleAgency(agencyIdObjectArr);
    }
  }

  getNewsItemsTypes() {
    this.subscriptions.add(
      this.lookupService
        .getNewsItemTypes()
        .subscribe((res: INewsItemType[]) => {
          if (res) {
            this.newsItemTypes = res;
          } else {
            this.toastService.error('No types found!');
          }
        })
    );
  }

  getUserRoles(): void {
    this.RoleService.getRolesByUserAccess()
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          this.roles = res;
        }
      });
  }

  getNewItems(): void {
    this.newsItemService
      .getUserActiveNewsItem(15)
      .pipe(take(1))
      .subscribe((item: any) => (this.newItemsList = item));
  }

  handleType(event: any) {
    if (!Boolean(event)) {
      this.newsItemForm.patchValue({ newsItemTypeId: null });
      return;
    }

    this.newsItemForm.patchValue({ newsItemTypeId: event.id });
  }

  handleAgency(event: any): void {
    this.clearDropdowns = false;
    let agencyIds: number[] = [];

    if (!event) {
      return;
    }

    if (Array.isArray(event) && event.length < 1) {
      this.sites = [];

      if (this.siteSelect) {
        this.siteIds.setValue(null);
      }
      return;
    }

    if (event?.id) {
      agencyIds = [event.id];
    } else {
      agencyIds = event.map((e: any) => e.id);
    }

    this.agencyIds.setValue(agencyIds);
  }

  handleSites(event: any): void {
    if (!event) {
      this.siteIds.setValue(null);
    } else {
      const siteIds = event.map((site: any) => {
        return site.id;
      });

      this.siteIds.setValue(siteIds);
    }
  }

  handleDate(event: any, controlName: string): void {
    if (event === '' || !event) {
      this.newsItemForm.get(controlName).setValue(formatDatesToString(null));
    } else {
      this.newsItemForm.get(controlName).setValue(formatDatesToString(event));
    }
  }

  handleGlobal() {
    if (this.isGlobal.value) {
      this.agencyInitialValues = [];
      this.siteInitialValues = [];
      this.agencyIds.setValue(null);
      this.siteIds.setValue(null);
      this.agencyIds.clearValidators();
      this.siteIds.clearValidators();
    } else {
      this.agencyIds.setValidators([Validators.required]);
      this.siteIds.setValidators([Validators.required]);
    }
    this.agencyIds.updateValueAndValidity();
    this.siteIds.updateValueAndValidity();
  }

  submitForm(): void {
    if (!this.newsItemForm.valid) {
      this.toastService.error('Please complete the form before submitting.');
      return;
    }

    if (
      this.checkDuplicateDate(
        this.newsItemDate.value,
        this.newsItemEndDate.value
      )
    ) {
      var event = { data: { modalInfo: {} } };
      event.data.modalInfo = {
        title: 'Dates Already Selected',
        message: `The dates chosen have already been selected for another news item, please choose different dates.`,
        name: 'No Results',
        primaryBtnClass: 'blue',
        showCancel: false,
        primaryBtnText: Modal.Close,
      };
      const modalRef = this.modal.open(ConfirmationModalComponent, {
        data: event ? event : undefined,
      });
      modalRef.afterClosed().subscribe();
      return;
    }

    const payload: any = this.newsItemForm.value;
    if (payload.isGlobal) {
      payload.agencyIds = null;
      payload.siteIds = null;
    }
    if (this.id) {
      payload.id = this.id;
      this.updateNewsItem(payload);
    } else {
      this.addNewsItem(payload);
    }
  }

  checkDuplicateDate(startDate: any, endDate: any): any {
    if (this.newItemsList?.length) {
      const formattedStartDate = formatToDate(startDate);
      const formattedEndDate = formatToDate(endDate);
      return this.newItemsList.some(
        (item) =>
          formatToDate(item.newsItemDate) === formattedStartDate &&
          formatToDate(item.newsItemEndDate) === formattedEndDate &&
          (this.id == 0 || this.id != item.id)
      );
    }
    return false;
  }

  updateNewsItem(payload: any) {
    this.subscriptions.add(
      this.newsItemService.updateNewsItem(payload).subscribe((res: boolean) => {
        if (res) {
          this.toastService.success('News Item Updated Successfully.');
          this.resetForm();
          this.router.navigateByUrl('newsitems', { replaceUrl: true });
        } else {
          this.toastService.error(
            'Something went wrong updating your news item. Please try again.'
          );
        }
      })
    );
  }

  addNewsItem(payload: any) {
    this.subscriptions.add(
      this.newsItemService.addNewsItem(payload).subscribe((res: boolean) => {
        if (res) {
          this.toastService.success('News Item Posted Successfully.');
          this.resetForm();
        } else {
          this.toastService.error(
            'Something went wrong posting your news item. Please try again.'
          );
        }
      })
    );
  }

  resetForm(): void {
    this.agencyInitialValues = [];
    this.siteInitialValues = [];
    this.newsTypeSelect.clear();

    if ((this.siteSelect)) {
      this.siteIds.setValue(null);
    }

    this.newsItemForm.reset();
    this.newsItemDate.setValue(formatDatesToString(new Date()));
    this.resetDate = !this.resetDate;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
