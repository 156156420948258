import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '@core/services/permission.service';
import { tap } from 'rxjs';

@Directive({
  selector: '[drdpCheckPermission]'
})
export class CheckPermissionDirective implements OnInit {
  @Input('drdpCheckPermission') CheckPermission?: number;

  constructor(private permissionService: PermissionService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {}

  ngOnInit() {
    let hasPermission = false;

    if(this.CheckPermission){
      hasPermission = this.permissionService.checkPermission(this.CheckPermission);

      if(hasPermission) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }

  }
}
