import { Injectable } from '@angular/core';
import { IStudentClassEnrollmentDetails } from '@core/interfaces/istudent';
import { IRatingViewTemplateOption } from '@views/user/configuration/rating-view-setup/interfaces/irating-view-template-option';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { IStudentActiveEnrollmentInfo, IStudentEnrollmentInfo } from '@views/user/input-rating/interfaces/istudent-enrollment-info';
import { getSearchUrl } from './helper.service';
@Injectable({
  providedIn: 'root',
})
export class StudentClassEnrollmentService {
  constructor(private data: DataService) {}

  getStudentClassEnrollment(classEnrollmentId: number): Observable<IStudentClassEnrollmentDetails> {
    return this.data.get(`studentclassenrollment/${classEnrollmentId}`);
  }

  deleteStudentClassEnrollment(id: number, message?: string): Observable<boolean> {
    return this.data.delete(`studentclassenrollment/${id}`, message);
  }

  addStudentEnrollment(
    payload: IStudentClassEnrollmentDetails,
    message?: string
  ): Observable<any> {
    return this.data.post('studentclassenrollment', payload, message);
  }

  updateClassEnrollmentsTable(
    payload: IStudentClassEnrollmentDetails,
    message?: string
  ): Observable<any> {
    return this.data.put('studentclassenrollment', payload, message);
  }

  enrollDroppedChild(payload: any, message?: string): Observable<any> {
    return this.data.post(
      'studentclassenrollment/dropped-child',
      payload,
      message
    );
  }

  checkStudentEnrollment(
    classId: number,
    message?: string
  ): Observable<boolean> {
    return this.data.get(
      `studentclassenrollment/check-student-enrollment/${classId}`,
      message
    );
  }

  getRatingPeriodTemplateOptions(
    ratingPeriodId?: number | null,
    siteId?: number | null,
    classId?: number | null
  ): Observable<IRatingViewTemplateOption[]> {
    return this.data.get(
      `studentclassenrollment/rating-view-template?ratingPeriodId=${ratingPeriodId}&siteId=${
        siteId || 0
      }&classId=${classId || 0}`
    );
  }

  getStudentClassInfo(id: number): Observable<IStudentEnrollmentInfo> {
    return this.data.get(`studentclassenrollment/${id}/info`);
  }

  updateClassEnrollmentDate(payload: any): Observable<boolean> {
    return this.data.put(`studentclassenrollment/class-enrollment-date`, payload);
  }

  getAgencyCurrentStudentEnrollments(params: any): Observable<any> {
    const url = getSearchUrl('studentclassenrollment/agency-current?', params);
    return this.data.get(url);
  }

  getStudentActiveActiveEnrollments(studentId: number): Observable<IStudentActiveEnrollmentInfo[]> {
    return this.data.get(`studentclassenrollment/student/${studentId}/active`);
  }
}
