export enum StudentDemographic {
    SSID = 'Statewide Student Identifier',
    DataType = 'DataType',
    DataTypeAgency = 'DataTypeAgency',
    AgencySID = 'Agency/District Student Identifier',
    PrimaryDisability = 'Primary Disability',
    SpecialEdEntryDate = 'Special Education Entry Date',
    RegionLabel = 'Region Label',
    DistrictLiability = 'District of Liability',
    AnticipatedDateExit = 'Anticipated Date of Exit from Preschool Education to Kindergarten',
    DateExit = 'Date of Exit from Preschool Special Education to Kindergarten',
    customFieldTypeSSID = 'customFieldTypeSSID',
    customFieldTypeAgency = 'customFieldTypeAgency',
    maxLength = 'maxLength',
    Selpa = 'Selpa',
    initialAssessmentToolId = 'initialAssessmentToolId',
    outcome1Id = 'outcome1Id',
    outcome2Id = 'outcome2Id',
    outcome3Id = 'outcome3Id',
}

export enum StudentDemographicEthnicity {
    Intentionally_left_blank = 19,
}