import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private readonly authToken = environment.authToken;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(environment.authToken);
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
    }
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const requestAuthHeader = request.headers.get('authorization');
          const responseAuthHeader = event.headers.get('Authorization');

          if (
            requestAuthHeader &&
            responseAuthHeader &&
            requestAuthHeader !== responseAuthHeader
          ) {
            const token = responseAuthHeader.substring(7);
            localStorage.setItem(this.authToken, JSON.stringify(token));
          }
        }
      })
    );
  }
}
