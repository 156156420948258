<div id="news" class="p-5">
  <div class="flex">
    <drdp-title title="News Item Form" class="pb-5"></drdp-title>
    <a
      *ngIf="canManageNewsItem"
      class="text-drdpblue-300 mt-2 pl-5"
      routerLink="/manage-news"
      >Manage News
      <i class="uil uil-arrow-circle-right"></i>
    </a>
  </div>
  <drdp-container additionClasses="p-10">
    <form (ngSubmit)="submitForm()">
      <ng-container [formGroup]="newsItemForm">
        <div
          class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6"
        >
          <div
            class="lg:col-span-4 md:col-span-3 sm:col-span-2 col-span-1"
            *drdpCheckPermission="Permission.CreateAllAgencyNewsItem"
          >
            <mat-checkbox
              color="primary"
              formControlName="isGlobal"
              (change)="handleGlobal()"
            >
            </mat-checkbox>
            <label class="inline">
              Apply to All Agencies
            </label>
          </div>

          <drdp-filter-select
            #newsTypeSelect
            label="News Item Type"
            [multiSelect]="false"
            [options]="newsItemTypes"
            [selectType]="selectNewsItemTypes"
            [initialValues]="newsItemTypeInitialValue"
            [required]="true"
            (onSelected)="handleType($event)"
          >
          </drdp-filter-select>

          <div id="role">
            <label class="required"> Role </label>
            <mat-form-field class="fixBorder" appearance="fill">
              <mat-select
                formControlName="roleIds"
                placeholder="Select Roles"
                multiple
              >
                <mat-option *ngFor="let role of roles" [value]="role.id">
                  {{ role.roleName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="!roleIds.valid">{{ required }}</mat-error>
            </mat-form-field>
          </div>

          <drdp-select-agency
            [hidden]="isGlobal.value"
            #agencySelect
            (agency)="handleAgency($event)"
            [stateId]="stateSearchId"
            [clear]="clearDropdowns"
            ngClass="h-full z-40"
            [required]="true"
            [multiSelect]="true"
            [multiSelectInitVals]="true"
            [initialAgencyIds]="agencyInitialValues"
          >
          </drdp-select-agency>

          <drdp-select-site
            #siteSelect
            [hidden]="isGlobal.value"
            (site)="handleSites($event)"
            [agencyId]="agencyIds.value"
            [clear]="clearDropdowns"
            [multiSelect]="true"
            [required]="true"
          >
          </drdp-select-site>

          <div class="relative">
            <drdp-date-picker
              label="Start Date"
              [initialDate]="newsItemDate?.value"
              [isRequired]="true"
              (emitDate)="handleDate($event, 'newsItemDate')"
            >
            </drdp-date-picker>
            <div class="absolute top-0 left-full mt-1 ml-[-2px]">
              <i class="uil uil-info-circle text-xs" [matTooltip]=dateInfo></i>
            </div>
          </div>

          <div class="relative">
            <drdp-date-picker
              label="End Date"
              [initialDate]="newsItemEndDate?.value"
              [isRequired]="true"
              [resetDate]="resetDate"
              (emitDate)="handleDate($event, 'newsItemEndDate')"
            >
            </drdp-date-picker>
            <div class="absolute top-0 left-full mt-1 ml-[-2px]">
              <i class="uil uil-info-circle text-xs" [matTooltip]=dateInfo></i>
            </div>
            <mat-error *ngIf="newsItemForm.hasError('dateMismatch')">
              Invalid date range. The End Date cannot be after the Start
              Date.</mat-error
            >
            <mat-error *ngIf="newsItemForm.hasError('sameDate')">
              End Date cannot be the same as the Start Date.
            </mat-error>
          </div>
          <div class="lg:col-span-3 md:col-span-3 sm:col-span-2 col-span-1">
            <label class="font-normal required" for="newsItemTitle"
              >Title</label
            >
            <mat-form-field class="bg-white" appearance="fill">
              <input
                matInput
                placeholder="Title"
                autocomplete="off"
                id="newsItemTitle"
                type="text"
                formControlName="newsItemTitle"
                maxlength="100"
                required
              />
              <mat-error *ngIf="!newsItemTitle.valid">{{ required }}</mat-error>
            </mat-form-field>
          </div>
          <div class="lg:col-span-4 md:col-span-3 sm:col-span-2 col-span-1">
            <label class="pt-3 font-formal required" for="newsItem"
              >Article Body</label
            >
            <mat-form-field class="bg-white" appearance="fill">
              <textarea
                matInput
                placeholder="Article Body"
                autocomplete="off"
                id="newsItem"
                formControlName="newsItem"
                cdkTextareaAutosize
                cdkAutosizeMinRows="10"
                maxlength="376"
                required
                class="border border-solid border-black"
              >
              </textarea>
              <mat-error *ngIf="!newsItem.valid">{{ required }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="mt-10 mb-2 flex justify-end">
          <drdp-btn
            class="pr-4"
            type="button"
            (click)="resetForm()"
            color="cancel"
          >
            Cancel & Reset
          </drdp-btn>
          <drdp-btn
            type="submit"
            [disabled]="!newsItemForm.valid"
            cdkFocusInitial
          >
            Save & Submit
          </drdp-btn>
        </div>
      </ng-container>
    </form>
  </drdp-container>
</div>
