import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IStudentEnrollmentInfo } from '../../interfaces/istudent-enrollment-info';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { InputRatingAction } from '@core/enums/input-rating-action';
import { Router } from '@angular/router';

@Component({
  selector: 'drdp-student-info',
  templateUrl: './student-info.component.html',
  styleUrls: ['./student-info.component.scss'],
})
export class StudentInfoComponent implements OnInit {
  @Input() studentInfo?: IStudentEnrollmentInfo;
  @Input() hasMeasures: boolean = false;
  @Input() canLock = false;
  @Input() isScantron = false;
  @Output() actionClicked = new EventEmitter();
  canLockRatings = this.permissionService.checkPermission(
    Permission.LockRatings
  );
  previousPage = '/ratings';

  canUnlockRatings = this.permissionService.checkPermission(
    Permission.UnlockRatings
  );
  canSaveRatings = this.permissionService.checkPermission(
    Permission.CreateRating
  );

  public get Permission() {
    return Permission;
  }

  public get InputRatingAction() {
    return InputRatingAction;
  }

  constructor(private permissionService: PermissionService, private router: Router) {}

  ngOnInit(): void {}

  onActionClick(type: InputRatingAction) {
    this.actionClicked.emit(type);
  }

  goBack(): void {
    this.router.navigateByUrl(this.previousPage);
  }

  ngOnChanges(): void {
    this.previousPage = `ratings/class/${this.studentInfo?.classId}/ageGradeInstrument/${this.studentInfo?.ageGradeInstrumentId}`;
  }
}
