import { NgModule } from '@angular/core';
import { AuthGuard } from '@core/guards/auth.guard';
import { AgenciesComponent } from './agencies/agencies.component';
import { RouterModule, Routes } from '@angular/router';
import { ClassesComponent } from './classes/classes.component';
import { StatesComponent } from './states/states.component';
import { SitesComponent } from './sites/sites.component';
import { RatingPeriodsComponent } from './rating-periods/rating-periods.component';
import { DrdpLayoutComponent } from '../drdp-layout/drdp-layout.component';
import { SchoolyearsComponent } from './schoolyears/schoolyears.component';
import { GlobalRatingPeriodsComponent } from './global-rating-periods/global-rating-periods.component';
import { LockingStatusComponent } from './locking-status/locking-status.component';
import { ClassroomComponent } from './classroom/classroom.component';
import { Permission } from '@core/enums/permissions';
import { PermissionGuard } from '@core/guards/permission.guard';
import { StateConfigurationComponent } from './state-configuration/state-configuration.component';
import { RatingViewSetupComponent } from './rating-view-setup/rating-view-setup.component';
import { CustomRatingViewFormComponent } from './rating-view-setup/custom-rating-view/custom-rating-view-form/custom-rating-view-form.component';
import { CustomRatingViewAssignmentComponent } from './rating-view-setup/custom-rating-view/custom-rating-view-assignment/custom-rating-view-assignment.component';
import { BaseTemplateComponent } from './rating-view-setup/base-template/base-template.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { AddEditIntegrationComponent } from './integrations/add-edit-integration/add-edit-integration.component';

const routes: Routes = [
  {
    path: 'config',
    canActivateChild: [AuthGuard],
    component: DrdpLayoutComponent,
    children: [
      { path: 'classroom-assignments', component: ClassesComponent },
      {
        path: 'states',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewStates },
        component: StatesComponent,
      },
      {
        path: 'states-configurations/:stateId',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewStates },
        component: StateConfigurationComponent,
      },
      {
        path: 'agencies',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewAgencies },
        component: AgenciesComponent,
      },
      {
        path: 'sites',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewSites },
        component: SitesComponent,
      },
      {
        path: 'ratingperiods',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewRatingPeriods },
        component: RatingPeriodsComponent,
      },
      {
        path: 'globalratingperiods',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewGlobalRatingPeriod },
        component: GlobalRatingPeriodsComponent,
      },
      {
        path: 'schoolyears',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewTerms },
        component: SchoolyearsComponent,
      },
      {
        path: 'lockingstatus',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewLockStatus },
        component: LockingStatusComponent,
      },
      {
        path: 'classroom',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewClassrooms },
        component: ClassroomComponent,
      },
      {
        path: 'rating-views',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewRatingViews },
        component: RatingViewSetupComponent,
      },
      {
        path: 'base-rating-view',
        canActivate: [PermissionGuard],
        data: {
          permission: Permission.CreateBaseTemplates,
          agencyId: 0,
          stateId: 0,
        },
        component: BaseTemplateComponent,
      },
      {
        path: 'custom-rating-view',
        canActivate: [PermissionGuard],
        data: {
          permission: Permission.EditRatingViews,
          agencyId: 0,
          stateId: 0,
        },
        component: CustomRatingViewFormComponent,
      },
      {
        path: 'custom-rating-view/:id',
        canActivate: [PermissionGuard],
        data: {
          permission: Permission.ViewRatingViews,
          agencyId: 0,
          stateId: 0,
        },
        component: CustomRatingViewFormComponent,
      },
      {
        path: 'custom-rating-view/:id/assignment',
        canActivate: [PermissionGuard],
        data: {
          permission: Permission.EditRatingViews,
          agencyId: 0,
          stateId: 0,
        },
        component: CustomRatingViewAssignmentComponent,
      },
      {
        path: 'integrations',
        canActivate: [PermissionGuard],
        data: { permission: Permission.IntegrationManagement },
        component: IntegrationsComponent,
      },
      {
        path: 'integrations/add-vendor',
        canActivate: [PermissionGuard],
        data: { permission: Permission.IntegrationManagement },
        component: AddEditIntegrationComponent,
      },
      {
        path: 'integrations/edit-vendor/:id',
        canActivate: [PermissionGuard],
        data: { permission: Permission.IntegrationManagement },
        component: AddEditIntegrationComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfigurationRoutingModule {}
