import { Component, OnInit } from '@angular/core';
import { ToastService } from '@core/services/toast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'drdp-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  subscription: Subscription | any;
  message?: string;

  constructor(private toastService: ToastService) { }

  ngOnInit() {
    this.subscription = this.toastService.$responseMessage.subscribe({
      next: (msg: string) => {
        if (msg) {
          this.message = msg;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
