<div class="h-full flex flex-col justify-between">
    <drdp-group-select 
        #regionSelect
        [label]="label" 
        [multiSelect]="multiSelect" 
        [options]="regionTypeOptions"
        [required]="required" 
        [disabled]="disabled"
        (onSelected)="onRegionSelect($event)"
        [isValid]="isValid"
        [initialValues]="initialRegionId"
        (onNewPage)="onNewPage($event)">
    </drdp-group-select>
</div>