import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'drdp-news-description',
  templateUrl: './news-description.component.html',
  styleUrls: ['./news-description.component.scss']
})
export class NewsDescriptionComponent implements OnInit {

  canEdit: boolean = false;
  user = this.authService.getCurrentUser();
  constructor(
    @Inject(MAT_DIALOG_DATA) public newsData: any,
    private router: Router,
    public modal: MatDialog,
    private authService: AuthService
    ) { }


  ngOnInit(): void {
    this.canEdit = this.user.userId === this.newsData.createdBy;
  }

  editNewsItem(): void {
    this.modal.closeAll();
    this.router.navigate([`/newsitems/${this.newsData.id}`])
  }

}
