<div class="p-5">
    <div class="flex flex-row">
        <div class="basis-1/2">
            <drdp-title [title]="title"></drdp-title>
        </div>
    </div>

    <drdp-container [additionClasses]="'min-h-[44rem]'">
        <drdp-container color="lightBlue">
            <form [formGroup]="searchForm">
                <div class="p-3 flex grid md:grid-cols-5 grid-cols-2 flex-row content-evenly">
                    <div>
                    <label for="firstName">First Name<span class="required"></span></label>
                    <div>
                        <input class="bg-drdpblue-200 border-b border-black 
                            focus:outline-none focus:border-drdpblue-300"
                            formControlName="firstName">
                    </div>
                    </div>
                    <div>
                    <label for="LastName">Last Name<span class="required"></span></label>
                    <div>
                        <input class="bg-drdpblue-200 border-b border-black 
                            focus:outline-none focus:border-drdpblue-300"
                            formControlName="lastName">
                    </div>
                    </div>
                    <div>
                        <label for="drdpId">DRDP ID</label>
                        <div>
                        <input class="bg-drdpblue-200 border-b border-black 
                            focus:outline-none focus:border-drdpblue-300"
                            formControlName="drdpId">
                        <span *ngIf="!drdpId.valid" class="text-xs text-red-700">
                            Must be a valid Id
                        </span>
                        </div>
                    </div>
                    <div class="ml-6">
                        <drdp-date-picker label="Date of Birth" 
                                          labelClasses="pb-1" 
                                          [isRequired]="true" 
                                          (emitDate)="handleDate($event)">
                        </drdp-date-picker>
                    </div>
                    <div class="mt-6 flex justify-between">
                    <drdp-btn [disabled]="!searchForm.valid" (click)="search()">Display Results</drdp-btn>
                    <drdp-btn (click)="clear()" color="outline">Clear</drdp-btn>
                    </div>
                </div>
            </form>
        </drdp-container>
        <div class="p-5 md:w-1/2">
            <drdp-add-edit-table
                [dataSource]="dataSource"
                [tableData]="tableData"
                [columns]="tableColumns"
                (iconActionItem)="openEnrollModal($event)">
            </drdp-add-edit-table>
        </div>
      </drdp-container>
</div>
