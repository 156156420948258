import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { ResourceRoutingModule } from './resource-routing.module'
import { PermissionAndRolesComponent } from './permission-and-roles/permission-and-roles.component'
import { SharedModule } from '@shared/shared.module';
import { UploadTemplatesComponent } from './upload-templates/upload-templates.component';
import { CompanionDocumentsComponent } from './companion-documents/companion-documents.component'

@NgModule({
  declarations: [PermissionAndRolesComponent, UploadTemplatesComponent, CompanionDocumentsComponent],
  imports: [CommonModule, ResourceRoutingModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ResourceModule {}
