<div class="upload-table-responsive pt-5">
    <mat-table [dataSource]="dataSource" class="w-[100%]" >
        <ng-container *ngFor="let col of columns; let counter = index" [matColumnDef]="col.columnDef">
            <th mat-header-cell *matHeaderCellDef class="text-center">{{col.data}}</th>
            <td mat-cell *matCellDef="let row; let i = index">
                    <div class="w-full inline-block" (click)="setEditableCell(counter,row)" *ngIf="!row.cellValues[counter].isEditable">{{ row.cellValues[counter].data || '&nbsp;'}} </div>
                    <input 
                    [ngClass]="{'danger': row.hasError}"
                    #input 
                    *ngIf="row.cellValues[counter].isEditable"
                    (blur)="setEditableCell(counter,row)" 
                    class="w-full inline-block border-b-2 border-black focus:outline-none focus:border-drdpblue-300" 
                    type="text" 
                    name="position-{{i}}" 
                    [(ngModel)]="row.cellValues[counter].data"/>
                    
                    
                    <mat-icon 
                    matTooltip="{{ '&bullet; ' + row.cellValues[counter].errorMessages.join('\n&bullet; ') }}"
                    matTooltipPosition = "right"
                    *ngIf="row.cellValues[counter].errorMessages.length > 0" 
                    class="w-full inline-block errorIcon">error</mat-icon>

                    <mat-icon 
                    matTooltip="{{ '&bullet; ' + row.cellValues[counter].warningMessages.join('\n&bullet; ') }}"
                    matTooltipPosition = "right"
                    *ngIf="row.cellValues[counter].warningMessages.length > 0" 
                    class="w-full inline-block warningIcon">warning</mat-icon>
            </td>
        </ng-container>   
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row [ngClass]="{'danger': row.hasError}" *matRowDef="let row;let i = index; columns: displayedColumns;"></tr>
    </mat-table>
</div>