import { Injectable } from '@angular/core';
import { ISites } from '@core/interfaces/isites';
import { Observable, Subject } from 'rxjs';
import { DataService } from './data.service';
import { getSearchUrl } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  $Sites = new Subject<any>();

  constructor(public data: DataService) {}

  getSites(message?: string): Observable<boolean> {
    return this.data.get(`site/all`, message);
  }

  getSitesWithParam(params: any) {
    return this.data.post('site/search', params);
  }

  addSite(payload: ISites, message?: string): Observable<boolean> {
    return this.data.post('site', payload, message);
  }

  updateSite(payload: ISites, message?: string): Observable<boolean> {
    return this.data.put('site', payload, message);
  }

  deleteSite(id: number, message?: string | any) {
    return this.data.delete(`site?id=${id}`, message);
  }

  getSitesByAgencyId(agencyId: number, message?: string) {
    return this.data.get(`site/agency/${agencyId}`, message);
  }

  getSitesByAccess(agencyId: number, message?: string) {
    return this.data.get(`site/user-access/${agencyId}`, message);
  }

  getSitesByPermission(agencyIds: number[], isPilot: boolean, message?: string) {
    return this.data.post(`site/user-permissions?isPilot=${isPilot}`, agencyIds, message);
  }

  validateUserSite(): Observable<boolean> {
    return this.data.get(`site/validate-user-site`);
  }
}
