import { Pipe, PipeTransform } from '@angular/core';
import { IStateConfiguration } from '@core/interfaces/istate-configurations';

@Pipe({
  name: 'groupDemographicSection'
})
export class GroupDemographicSectionPipe implements PipeTransform {
  transform(collection: IStateConfiguration[] | null, by: number): any {
    if (collection) return collection.filter(item => item.sectionId === by && item.isActive);
  }
}