<div id="userAccountModal" class="rounded-lg fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 *ngIf="userDetails" class="text-[25px] font-bold">
      Editing {{ userDetails.firstName }} {{ userDetails.lastName }}
    </h1>
    <h1 *ngIf="!userDetails" class="text-[25px] font-bold">Add New User</h1>
    <i
      mat-button
      mat-dialog-close
      class="text-drdpblue-300 cursor-pointer uil uil-times-circle"
    ></i>
  </div>

  <div mat-dialog-content>
    <form
      class="grid grid-cols-2 gap-4 gap-x-10 mt-2 pb-3"
      [formGroup]="userForm"
    >
      <div class="col-span-1">
        <mat-form-field class="bg-white" appearance="fill">
          <label> Work Title </label>
          <input
            matInput
            placeholder="Work Title"
            autocomplete="off"
            type="text"
            formControlName="jobTitle"
            maxlength="50"
          />
        </mat-form-field>
      </div>

      <div class="col-span-1">
        <mat-form-field class="bg-white" appearance="fill">
          <label class="required"> First Name </label>
          <input
            matInput
            placeholder="First Name"
            autocomplete="off"
            type="text"
            formControlName="firstName"
            maxlength="50"
          />
        </mat-form-field>
      </div>

      <div class="col-span-1">
        <mat-form-field class="bg-white" appearance="fill">
          <label> M.I. </label>
          <input
            matInput
            placeholder="M.I."
            autocomplete="off"
            type="text"
            formControlName="middleName"
            maxlength="1"
          />
        </mat-form-field>
      </div>

      <div class="col-span-1">
        <mat-form-field class="bg-white" appearance="fill">
          <label class="required"> Last Name </label>
          <input
            matInput
            placeholder="Last Name"
            autocomplete="off"
            type="text"
            formControlName="lastName"
            maxlength="50"
          />
        </mat-form-field>
      </div>

      <div class="col-span-1">
        <mat-form-field class="bg-white" appearance="fill">
          <label class="required"> Account Email </label>
          <input
            matInput
            class="relative z-50"
            placeholder="Account Email"
            autocomplete="off"
            type="email"
            formControlName="workEmail"
            maxlength="100"
          />
          <mat-error
            *ngIf="workEmail.touched && workEmail.errors?.email"
            class="text-sm text-red-700 pt-4"
          >
            Input a valid email address.
          </mat-error>
          <mat-error
            *ngIf="
              !workEmail.errors?.email && workEmail.duplicateEmail && !editData
            "
            class="text-sm text-red-700 pt-4"
          >
            Email already taken.
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="!editData" class="col-span-1">
        <mat-form-field class="bg-white" appearance="fill">
          <label class="required"> Confirm Email </label>
          <input
            matInput
            class="relative z-50"
            type="email"
            placeholder="Confirm Work Email"
            matInput
            formControlName="checkEmail"
            maxlength="100"
          />
          <mat-error
            *ngIf="
              checkEmail.touched &&
              checkEmail.errors?.emailMismatch &&
              !editData
            "
            class="text-sm text-red-700 pt-4"
          >
            Emails do not match.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-span-1">
        <drdp-select-suffix
          [initialSuffix]="selectedSuffix"
          (suffix)="handleDropdown($event, selectType.Suffix)"
        >
        </drdp-select-suffix>
      </div>

      <div *ngIf="userDetails" class="col-span-1">
        <div class="mt-2">
          <label> Account Created </label>
          <p>{{ userDetails.createdOn | localDate }}</p>
        </div>
      </div>

      <div *ngIf="userDetails" class="col-span-1">
        <div class="mt-2">
          <label> Last Login </label>
          <p>{{ userDetails.lastLogin | localDate }}</p>
        </div>
      </div>

      <div class="col-span-1 select-input flex-col justify-center items-center">
        <drdp-select-status
          [disabled]="!hasStatusEditAccess"
          [initialStatusId]="statusTypeId.value"
          (status)="handleDropdown($event, selectType.Status)"
          [required]="true"
        >
        </drdp-select-status>
      </div>

      <div class="col-span-2" *ngIf="!activeUser">
        <label>Reason for Revoking Access</label>
        <mat-form-field appearance="outline">
          <textarea
            formControlName="statusReason"
            matInput
            placeholder="Ex. No longer employed"
            maxlength="100"
          ></textarea>
        </mat-form-field>
      </div>

      <div *ngIf="userDetails" class="col-span-1">
        <div>
          <label> Account Status Modified Date </label>
          <p>{{ userDetails.modifiedOn | localDate }}</p>
        </div>
      </div>

      <div *ngIf="userDetails" class="col-span-1">
        <div class="mt-2">
          <label> Account Status Modified By </label>
          <p>{{ userDetails.modifiedName }}</p>
        </div>
      </div>

      <div *ngIf="userDetails" class="col-span-1">
        <div class="mt-2">
          <label> Password Status </label>
          <p>
            Last Changed On: {{ userDetails.passwordChangedOn | localDate }}
          </p>
        </div>
      </div>

      <div class="col-span-2 items-center w-[100%]">
        <p>
          Users must be tied to at least one agency, one region, or one county.
        </p>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-state
          [initialStateId]="state.value"
          (state)="handleDropdown($event, selectType.States)"
          [required]="true"
          [isValid]="isDropdownValid.state"
        >
        </drdp-select-state>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-region
          [stateId]="state.value"
          [initialRegionId]="region.value"
          [multiSelectInitVals]="true"
          [multiSelect]="true"
          (region)="handleDropdown($event, selectType.RegionRef)"
        >
        </drdp-select-region>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-county
          [initialCountyIds]="selectedCountyIds"
          (county)="handleDropdown($event, selectType.County)"
          [stateId]="selectedState"
          [clear]="clearDropdown"
        >
        </drdp-select-county>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-agency
          [initialAgencyId]="agencyId.value"
          (agency)="handleDropdown($event, selectType.Agencies)"
          [stateId]="selectedState"
          [clear]="clearDropdown"
          [isEditDropdown]="true"
        >
        </drdp-select-agency>
      </div>

      <div class="col-span-1 items-center w-[100%]">
        <drdp-select-site
          [initialSiteIds]="sites.value"
          (site)="handleDropdown($event, selectType.Sites)"
          [agencyId]="agencyId.value"
          [multiSelect]="true"
          [multiSelectInitVals]="true"
          [clear]="clearDropdown"
        >
        </drdp-select-site>
      </div>

      <div
        *ngIf="loggedInUserAccessLevel >= roleGroupAccessLevel.LeadAdminRoles"
        class="col-span-1 items-center w-[100%]"
      >
        <drdp-select-grantee
          [initialGranteeId]="selectedGranteeAgencyId"
          label="Are you an employee of a Head Start Grantee Agency.  If so, please choose agency below."
          (agency)="handleDropdown($event, selectType.Grantee)"
          [stateId]="state.value"
        >
        </drdp-select-grantee>
      </div>

      <div
        *ngIf="loggedInUserAccessLevel >= roleGroup.LeadAdminRoles"
        class="col-span-1 items-center w-[100%]"
      >
      <drdp-select-contractor
        [initialContractorId]="selectedContractorAgencyId"
        label="Are you an employee of a State Preschool Contractor Agency? If so, please choose agency below."
        (agency)="handleDropdown($event, selectType.StatePreschoolContractorRef)"
        [stateId]="state.value"
      >
      </drdp-select-contractor>
    </div>

      <div class="col-span-2 items-center w-[100%]">
        <label class="required">Roles</label>
        <div class="grid grid-cols-2 gap-4 gap-x-10 mt-2 pb-3">
          <div
            *ngIf="loggedInUserAccessLevel >= roleGroupAccessLevel.UserRoles"
            class="col-span-1 items-center w-[100%]"
          >
            <label>User Roles</label>
            <div class="flex flex-col">
              <div *ngFor="let role of userRoleOptions$ | async">
                <div *ngIf="role.roleGroupId === roleGroup.UserRoles">
                  <p>
                    <mat-checkbox
                      [checked]="initiializeCheckbox(role)"
                      (change)="handleCheckbox(role)"
                      color="primary"
                    >
                    </mat-checkbox>
                    {{ role.roleName }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="loggedInUserAccessLevel >= roleGroupAccessLevel.EnrollmentUser"
            class="col-span-1 items-center w-[100%]"
          >
            <label>Enrollment User</label>
            <div class="flex flex-col">
              <div *ngFor="let role of userRoleOptions$ | async">
                <div *ngIf="role.roleGroupId === roleGroup.EnrollmentUser">
                  <p>
                    <mat-checkbox
                      [checked]="initiializeCheckbox(role)"
                      (change)="handleCheckbox(role)"
                      color="primary"
                    >
                    </mat-checkbox>
                    {{ role.roleName }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="loggedInUserAccessLevel > roleGroupAccessLevel.AdministratorRoles || canEditSameLevelNotPromote"
            class="col-span-1 items-center w-[100%]"
          >
            <label>Administrator Roles</label>
            <div class="flex flex-col">
              <div *ngFor="let role of userRoleOptions$ | async">
                <div *ngIf="role.roleGroupId === roleGroup.AdministratorRoles">
                  <p>
                    <mat-checkbox
                      [checked]="initiializeCheckbox(role)"
                      (change)="handleCheckbox(role)"
                      [disabled]="role.id === roleType.AgencyAdministrator && canEditSameLevelNotPromote && (loggedInUserAccessLevel <= roleGroup.AdministratorRoles)"
                      color="primary"
                    >
                    </mat-checkbox>
                    {{ role.roleName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="loggedInUserAccessLevel > roleGroupAccessLevel.StateUsers"
            class="col-span-1 items-center w-[100%]"
          >
            <label>State Users</label>
            <div class="flex flex-col">
              <div *ngFor="let role of userRoleOptions$ | async">
                <div *ngIf="role.roleGroupId === roleGroup.StateUsers">
                  <p>
                    <mat-checkbox
                      [checked]="initiializeCheckbox(role)"
                      (change)="handleCheckbox(role)"
                      [disabled]="role.id === roleType.AgencyAdministrator && canEditSameLevelNotPromote && (loggedInUserAccessLevel <= roleGroup.AdministratorRoles)"
                      color="primary"
                    >
                    </mat-checkbox>
                    {{ role.roleName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="loggedInUserAccessLevel > roleGroupAccessLevel.LeadAdminRoles"
            class="col-span-1 items-center w-[100%]"
          >
            <label>Lead Admin Roles</label>
            <div class="flex flex-col">
              <div *ngFor="let role of userRoleOptions$ | async">
                <div *ngIf="role.roleGroupId === roleGroup.LeadAdminRoles">
                  <p>
                    <mat-checkbox
                      [checked]="initiializeCheckbox(role)"
                      (change)="handleCheckbox(role)"
                      color="primary"
                    >
                    </mat-checkbox>
                    {{ role.roleName }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="loggedInUserAccessLevel >= roleGroupAccessLevel.PowerUserRoles && canEditSameLevel"
            class="col-span-1 items-center w-[100%]"
          >
            <label>Power User Roles</label>
            <div class="flex flex-col">
              <div *ngFor="let role of userRoleOptions$ | async">
                <div *ngIf="role.roleGroupId === roleGroup.PowerUserRoles">
                  <p>
                    <mat-checkbox
                      [checked]="initiializeCheckbox(role)"
                      (change)="handleCheckbox(role)"
                      color="primary"
                    >
                    </mat-checkbox>
                    {{ role.roleName }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="loggedInUserAccessLevel >= roleGroupAccessLevel.SystemPowerUser && canEditSameLevel"
            class="col-span-1 items-center w-[100%]"
          >
            <label>System Power User</label>
            <div class="flex flex-col">
              <div *ngFor="let role of userRoleOptions$ | async">
                <div *ngIf="role.roleGroupId === roleGroup.SystemPowerUser">
                  <p>
                    <mat-checkbox
                      [checked]="initiializeCheckbox(role)"
                      (change)="handleCheckbox(role)"
                      color="primary"
                    >
                    </mat-checkbox>
                    {{ role.roleName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="flex justify-end">
  <drdp-btn color="cancel" mat-button mat-dialog-close>Cancel</drdp-btn>
  <drdp-btn
    class="ml-3"
    mat-button
    (click)="onSubmit()"
    [disabled]="userForm.invalid"
    cdkFocusInitial
    >Save</drdp-btn
  >
</div>
