<div class="h-full flex flex-col justify-between">
  <drdp-filter-select
    #portfolioUsersSelect
    [label]="label"
    [multiSelect]="multiSelect"
    [options]="portfolioUsersOptions"
    [selectType]="select.PortfolioUser"
    [required]="required"
    [isValid]="isValid"
    (onSelected)="onPortfolioUserSelect($event)"
    overrideLabel= ""
    [initialValues]="initialPortfolioUserId || initialPortfolioUserIds"
  >
  </drdp-filter-select>
</div>
