export enum UploadTypes {
    user = 1,
    class = 2,
    student = 3
}

export enum CompanionDocuments {
    Guidance_For_DRDP_Domain_Scaled_Scores = 1,
    Group_Report_Guidance = 2,
    Domain_Scale_Cut_Points_for_Sharing = 3
}