import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Permission } from '@core/enums/permissions';
import { IPortfolioConversationRequestDto, IPortfolioMessageResponse, IPortfolioMessageSearchRequest } from '@core/interfaces/iportfolio-message';
import { AuthService } from '@core/services/auth.service';
import { PermissionService } from '@core/services/permission.service';
import { PortfolioMessageService } from '@core/services/portfolio-message.service';
import { take, tap } from 'rxjs';

@Component({
  selector: 'drdp-message-view',
  templateUrl: './message-view.component.html',
  styleUrls: ['./message-view.component.scss']
})
export class MessageViewComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public get Permission() { return Permission }
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  portfolioMessages: IPortfolioMessageResponse[] = [];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex: number = 0;
  pageSize: number = 10;
  totalData = 0;
  messageSelected?: IPortfolioConversationRequestDto;
  payload: IPortfolioMessageSearchRequest= {
    itemsPerPage: this.pageSize,
    pageIndex: this.pageIndex
  };
  canSearchPortfolioMessages: boolean = this.permissionService.checkPermission(
    Permission.AccessToAllPortfolioMessageBoards
  );
  constructor(
    private permissionService: PermissionService,
    private portfolioMessageService: PortfolioMessageService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (!this.canSearchPortfolioMessages) {
      this.portfolioMessageService.getPortfolioMessagesByUserId(this.payload).pipe(take(1)).subscribe((res: any) => {
        this.setData(res)
      })
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  handleSearch(event: any): void {
    this.payload = {
      stateId: event.stateId,
      agencyId: event.agencyId,
      siteId: event.siteId,
      classId: event.classroomId,
      ratingPeriodId: event.ratingPeriodId,
      pageIndex: this.pageIndex,
      itemsPerPage: this.pageSize
    };

    this.paginateTable(true);
  }

  paginateTable(resetPage?: boolean): void {
    this.payload.pageIndex = resetPage ? 0 : this.paginator.pageIndex + 1;
    this.payload.itemsPerPage = resetPage ? 10 : this.paginator.pageSize;
    this.payload.userId = this.canSearchPortfolioMessages ? undefined : this.authService.getCurrentUser().userId;

    const observableToUse = this.canSearchPortfolioMessages
    ? this.portfolioMessageService.searchPortfolioMessages(this.payload)
    : this.portfolioMessageService.getPortfolioMessagesByUserId(this.payload);

  observableToUse
    .pipe(
      take(1),
      tap((res: any) => {
        this.setData(res);
        return res;
      })
    )
    .subscribe();
  }

  setData(res: any) {
    this.totalData = res.totalData;
    this.portfolioMessages = res.items;
  }

  handleSelectedMessage(message: IPortfolioConversationRequestDto) {
    this.messageSelected = message;
    this.router.navigateByUrl(
      `portfolio-messages/student-enrollment/${message.sceId}/student/${message.studentId}/sender/${message.createdById}/recipient/${message.recipientId}`
      );
  }

}
