export enum AccountStatusTable {
    Active = 1,
    Inactive = 2,
    RevokedBySystem = 3,
    RevokedByAgency = 4,
    RevokedByWestEd = 5
}

export enum AccountStatusNameTable {
    Active = 'Active',
    Inactive = 'Inactive',
    RevokedBySystem = 'Revoked By System',
    RevokedByAgency = 'Revoked By Agency',
    RevokedByWestEd = 'Revoked By WestEd'
}