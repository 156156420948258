export enum PortfolioEvidenceType {
    Photo = 1,
    Video = 2,
    Audio = 3,
    Note = 4,
    StructuredPrompt = 5
}

export enum PortfolioMeasureType {
    Domain = 1,
    Measure = 2,
}

export enum PortfolioPreRating {
    Domain = 1,
    Measure = 2,
}

export enum InputRatingSelectedLevel {
    Unshaded = 1,
    FullShadedOrHalfShaded = 2,
    FullShaded = 3
}

export enum AddEvidenceFormStep {
    StepOne = 1,
    StepTwo = 2
}