import { Component, OnInit, inject } from '@angular/core';
import { DownloadRequestService } from '@core/services/download-request.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-download-demographics',
  templateUrl: './download-demographics.component.html',
  styleUrls: ['./download-demographics.component.scss'],
})
export class DownloadDemographicsComponent implements OnInit {
  downloadService = inject(DownloadRequestService);
  constructor() {}

  ngOnInit(): void {}

  downloadDemographics(searchValues: any) {
    this.downloadService
      .downloadDemographics(searchValues)
      .pipe(take(1))
      .subscribe((res) => {
        const file = new Blob([res], { type: 'text/csv' });
        const fileUrl = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        let fileName = 'demographics';
        link.download = `${fileName.replace(/ /g, '_')}.csv`;
        link.click();
      });
  }
}
