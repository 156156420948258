import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDifficultyLevels } from '@core/interfaces/idifficulty-levels';
import { LookupService } from '@core/services/lookup.service';
import { ITeacherSurvey, ITeacherSurveyRequest } from '../../interfaces/iteacher-survey';
import { take, tap } from 'rxjs';
import { RatingService } from '@core/services/rating.service';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'drdp-teacher-survey-modal',
  templateUrl: './teacher-survey-modal.component.html',
  styleUrls: ['./teacher-survey-modal.component.scss']
})
export class TeacherSurveyModalComponent implements OnInit {
  difficultyLevels: IDifficultyLevels[] = [];
  difficultyForm!: FormGroup;

  public get id() {
    return this.difficultyForm.get('id');
  }

  public get difficultyLevelId() {
    return this.difficultyForm.get('difficultyLevelId');
  }

  public get difficultyAnswer() {
    return this.difficultyForm.get('difficultyAnswer');
  }
  constructor(public dialog: MatDialog, 
              private lookupService: LookupService, 
              private ratingService: RatingService,
              private toast: ToastService,
              private fb: FormBuilder, 
              public dialogRef: MatDialogRef<TeacherSurveyModalComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any) { }
 
  ngOnInit(): void {
    this.initializeForm();
    this.getDifficultyLevels();
  }

  getDifficultyLevels() { 
    this.lookupService.getDifficultyLevels().pipe(
      take(1),
      tap((data: IDifficultyLevels[]) => {
        this.difficultyLevels = data;
      })
    ).subscribe();
  }

  getTeacherSurvey() {
    const { cardId, measureId } = this.dialogData.teacherSurveyReq ?? this.dialogData;
    if (cardId && measureId) {
      this.ratingService.getTeacherSurvey(cardId, measureId)
        .pipe(
          take(1),
          tap((data: ITeacherSurvey) => {
            if (data) {
              this.id?.setValue(data.id);
              this.difficultyLevelId?.setValue(data.difficultyLevelId);
              this.difficultyAnswer?.setValue(data.difficultyAnswer);
            }
          })
        )
        .subscribe();
    }
  }

  initializeForm() {
    const { cardId, measureId } = this.dialogData.teacherSurveyReq ?? this.dialogData;
    this.difficultyForm = this.fb.group({
      id: [0],
      carId: [cardId],
      measureId: [measureId],
      difficultyLevelId: [null, Validators.required],
      difficultyAnswer: [null]
    });

    if (this.dialogData.viewOnly) {
      this.difficultyLevelId?.disable();
      this.difficultyAnswer?.disable();
    }
    
    this.getTeacherSurvey();
  }

  onSubmit() {
    if (this.difficultyForm.invalid) {
      this.toast.error('Please make a selection before saving.');
      return;
    }

    if (this.difficultyAnswer?.value && this.difficultyAnswer.value.length > 2000) {
      this.toast.error('Maximum 2000 character count.');
      return;
    }

    const payload: ITeacherSurvey = {
      ...this.difficultyForm.value
    }
    this.dialogRef.close({ payload: payload });
  }

}
