import { Component, Input, OnInit } from '@angular/core';
import { IDropMenu } from '@core/interfaces/side-menu';

@Component({
  selector: 'drdp-header-dropmenu',
  templateUrl: './header-dropmenu.component.html',
  styleUrls: ['./header-dropmenu.component.scss'],
})
export class HeaderDropmenuComponent implements OnInit {
  show = false;
  @Input() sites: any;
  
  @Input() vm: IDropMenu | undefined
  
  constructor() {}



  ngOnInit(): void {
  }

  dropMenuToggle() {
    this.show = !this.show;
  }
}
