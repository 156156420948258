import { Component, inject, OnInit } from '@angular/core';
import { IReportSearch } from '@views/user/reports';
import { take } from 'rxjs';

import { ReportService } from '@core/services/report.service';
import { Permission } from '@core/enums/permissions';
import { PermissionService } from '@core/services/permission.service';
import { IRequiredReportFields } from '../interfaces/irequired-report-fields';

@Component({
  selector: 'drdp-child-progress',
  templateUrl: './child-progress.component.html',
  styleUrls: ['./child-progress.component.scss']
})
export class ChildProgressComponent implements OnInit {
  private reportService = inject(ReportService);
  private permissionService = inject(PermissionService);
  reportData:  string | undefined;
  loading: boolean = false;
  hasAccessToAssignedChildren = this.permissionService.checkPermission(
    Permission.AccessToAssignedChildrenReporting
  );
  requiredFields: IRequiredReportFields = {
    state: true,
    agency: !this.hasAccessToAssignedChildren,
    globalRatingPeriod: true,
    site: !this.hasAccessToAssignedChildren,
    classroom: !this.hasAccessToAssignedChildren,
    ageGroup: true,
    requireMultipleRatingPeriods: false
  }
  constructor() { }

  ngOnInit(): void {
  }

  showReport(searchValues: IReportSearch) {
    this.loading = true;
    if (!searchValues.locale) {
      searchValues.locale = 'en';
    }

    this.reportService
      .generateChildProgressReport(searchValues)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.reportData = res;
        },
        complete: () => {
          this.loading = false;
        }
      });
  }

  downloadReport(searchValues: IReportSearch) {
    this.loading = true;
    this.reportService
      .generateChildProgressReportPdf(searchValues)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          const file = new Blob([res], { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileUrl;
          let fileName = `child_progress_report`;
          link.download = `${fileName.replace(/ /g, '_')}.pdf`;
          link.click();
        },
        complete: () => {
          this.loading = false;
        }
      });
  }

  handleClear(): void {
    this.reportData = undefined;
  }
}
