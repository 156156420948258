import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { IVendorRequest, IVendor } from '@core/interfaces/ivendor';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  constructor(public data: DataService) {}

  saveVendor(payload: IVendorRequest, message?: string): Observable<boolean> {
    return this.data.post('vendor', payload, message);
  }

  getVendor(vendorId: number): Observable<IVendor> {
    return this.data.get(`vendor/${vendorId}`);
  }

  generateKey(payload: IVendorRequest, message?: string): Observable<IVendor> {
    return this.data.put('vendor/generate-key', payload, message);
  }
}
