<div class="p-5">
    <div class="basis-1/2">
        <h1 class="pb-3 text-3xl font-semibold">Lock Rating Views</h1>
    </div>
    <drdp-container>
        <div class="md:w-1/2">
            <drdp-add-edit-table [dataSource]="dataSource"
            [tableData]="tableData"
            [columns]="formTableColumns"
            (editActionItem)="openModal($event)">
            </drdp-add-edit-table>
        </div>
    </drdp-container>
</div>
