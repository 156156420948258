import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from '@shared/shared.module'
import { FormsModule } from '@angular/forms'
import { ClassesComponent } from './classes/classes.component'
import { StatesComponent } from './states/states.component'
import { AgenciesComponent } from './agencies/agencies.component'
import { AddEditAgencyComponent } from './agencies/add-edit-agency/add-edit-agency.component'
import { CoreModule } from '@core/core.module'
import { AddEditClassesComponent } from './classes/add-edit-classes/add-edit-classes.component'
import { SitesComponent } from './sites/sites.component'
import { AddEditSitesComponent } from './sites/add-edit-sites/add-edit-sites.component'
import { RatingPeriodsComponent } from './rating-periods/rating-periods.component'
import { AddEditRatingPeriodComponent } from './rating-periods/add-edit-rating-period/add-edit-rating-period.component'
import { ConfigurationRoutingModule } from './configuration-routing.module'
import { SchoolyearsComponent } from './schoolyears/schoolyears.component'
import { AddSchoolyearComponent } from './schoolyears/add-schoolyear/add-schoolyear.component'
import { GlobalRatingPeriodsComponent } from './global-rating-periods/global-rating-periods.component'
import { AddGlobalRatingPeriodComponent } from './global-rating-periods/add-global-rating-period/add-global-rating-period.component'
import { PastGlobalRatingPeriodsComponent } from './global-rating-periods/past-global-rating-periods/past-global-rating-periods.component'
import { LockingStatusComponent } from './locking-status/locking-status.component'
import { AddEditLockingStatusComponent } from './locking-status/add-edit-locking-status/add-edit-locking-status.component'
import { ClassroomComponent } from './classroom/classroom.component'
import { AddClassModalComponent } from './classroom/add-class-modal/add-class-modal.component';
import { AddNewsComponent } from './news/news.component';
import { StateConfigurationComponent } from './state-configuration/state-configuration.component';
import { StudentDemographicsComponent } from './state-configuration/student-demographics/student-demographics.component';
import { CustomConfigurationComponent } from './state-configuration/custom-configuration/custom-configuration.component';
import { ChildUploadComponent } from './state-configuration/child-upload/child-upload.component';
import { RatingViewSetupComponent } from './rating-view-setup/rating-view-setup.component';
import { CustomRatingViewFormComponent } from './rating-view-setup/custom-rating-view/custom-rating-view-form/custom-rating-view-form.component';
import { CustomRatingViewAssignmentComponent } from './rating-view-setup/custom-rating-view/custom-rating-view-assignment/custom-rating-view-assignment.component';
import { BaseTemplateComponent } from './rating-view-setup/base-template/base-template.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { AddEditIntegrationComponent } from './integrations/add-edit-integration/add-edit-integration.component';
import { NgxMaskModule } from 'ngx-mask';
import { ReassignModalComponent } from './rating-view-setup/custom-rating-view/custom-rating-view-form/reassign-modal/reassign-modal.component';
import { SchoolYearTargetComponent } from './state-configuration/school-year-target/school-year-target.component'

@NgModule({
  declarations: [
    ClassesComponent,
    StatesComponent,
    AgenciesComponent,
    AddEditAgencyComponent,
    AddEditClassesComponent,
    SitesComponent,
    AddEditSitesComponent,
    RatingPeriodsComponent,
    AddEditRatingPeriodComponent,
    SchoolyearsComponent,
    AddSchoolyearComponent,
    GlobalRatingPeriodsComponent,
    AddGlobalRatingPeriodComponent,
    PastGlobalRatingPeriodsComponent,
    LockingStatusComponent,
    AddEditLockingStatusComponent,
    ClassroomComponent,
    AddClassModalComponent,
    AddNewsComponent,
    StateConfigurationComponent,
    StudentDemographicsComponent,
    CustomConfigurationComponent,
    ChildUploadComponent,
    RatingViewSetupComponent,
    CustomRatingViewFormComponent,
    CustomRatingViewAssignmentComponent,
    BaseTemplateComponent,
    IntegrationsComponent,
    AddEditIntegrationComponent,
    ReassignModalComponent,
    SchoolYearTargetComponent
  ],
  imports: [
    CommonModule,
    ConfigurationRoutingModule,
    SharedModule,
    FormsModule,
    CoreModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ConfigurationModule {}
