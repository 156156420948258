import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToastComponent } from '@shared/components/toast/toast.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  responseMessage: string = '';
  $responseMessage = new BehaviorSubject<any>(this.responseMessage);
  snackConfig: MatSnackBarConfig<any> = {
      duration: 4000,
      verticalPosition: 'bottom',
      horizontalPosition: 'right'
  }
  
  constructor(private _snackBar: MatSnackBar) { }

  success(message: string) {
    this.$responseMessage.next(message);
    this.snackConfig.panelClass = ['toast-success'];
    this._snackBar.openFromComponent(ToastComponent, this.snackConfig);
  }

  warn(message: string) {
    this.$responseMessage.next(message);
    this.snackConfig.panelClass = ['toast-warning'];
    this._snackBar.openFromComponent(ToastComponent, this.snackConfig);
  }

  error(message: string) {
    this.$responseMessage.next(message);
    this.snackConfig.panelClass = ['toast-error'];
    this._snackBar.openFromComponent(ToastComponent, this.snackConfig);
  }

  info(message: string) {
    this.$responseMessage.next(message);
    this.snackConfig.panelClass = ['toast-info'];
    this._snackBar.openFromComponent(ToastComponent, this.snackConfig);
  }
}
