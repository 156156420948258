<div class="h-full flex flex-col justify-between">
  <drdp-filter-select
    #languageSelect
    [label]="label"
    [multiSelect]="multiSelect"
    [options]="languageOptions"
    [selectType]="select.Language"
    [required]="required"
    [isValid]="isValid"
    (onSelected)="onLanguageSelect($event)"
    overrideLabel= "Language"
    [initialValues]="initialLanguageId || initialLanguageIds"
  >
  </drdp-filter-select>
</div>
