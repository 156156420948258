import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { Permission } from '@core/enums/permissions'
import { AuthGuard } from '@core/guards/auth.guard'
import { PermissionGuard } from '@core/guards/permission.guard'
import { DrdpLayoutComponent } from '@views/user/drdp-layout/drdp-layout.component'

import { PermissionAndRolesComponent } from './permission-and-roles/permission-and-roles.component'
import { UploadTemplatesComponent } from './upload-templates/upload-templates.component'
import { CompanionDocumentsComponent } from './companion-documents/companion-documents.component'

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    component: DrdpLayoutComponent,
    children: [
      { 
        path: 'permissionAndRoles', 
        component: PermissionAndRolesComponent 
      },
      { 
        path: 'uploadTemplates',
        component: UploadTemplatesComponent 
      },
      { 
        path: 'companionDocuments',
        component: CompanionDocumentsComponent 
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResourceRoutingModule {}
