import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { getSearchUrl } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(private dataService: DataService) {}

  getSitesRatingPeriodTeachersData(params: any) {
    const url = getSearchUrl('dropdown/sites-rating-period-teachers?', params);
    return this.dataService.get(url);
  }

  getTeachersDataBySiteId(siteId: number) {
    return this.dataService.get(
      `dropdown/rating-period-teachers/site/${siteId}`
    );
  }

  getSchoolYearsRatingPeriodTeacherData(params: any) {
    const url = getSearchUrl(
      'dropdown/schoolyear-rating-periods-teachers?',
      params
    );
    return this.dataService.get(url);
  }

  getSiteRatingPeriodsByState(stateId: number) {
    return this.dataService.get(
      `dropdown/site-rating-periods/state/${stateId}`
    );
  }

  getTeachers(agencyId: number, siteId: number = 0) {
    return this.dataService.get(`dropdown/teachers/${agencyId}/${siteId}`);
  }
}
