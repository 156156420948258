import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { IAgeGradeEnrollmentProgramFilter } from '@core/interfaces/iage-grade-instrument';
import { SelectType } from '@core/enums/select';
import { LookupService } from '@core/services/lookup.service';
import { PermissionService } from '@core/services/permission.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-select-program-type',
  templateUrl: './select-program-type.component.html',
  styleUrls: ['./select-program-type.component.scss'],
})
export class SelectProgramTypeComponent implements OnInit {
  @ViewChild('programTypeSelect') programTypeSelect:
    | FilterSelectComponent
    | undefined;
  @Output() programType = new EventEmitter<any>();
  @Input() initialProgramTypeId?: number | null;
  @Input() initialProgramTypeIds?: number[] | null;
  @Input() ageGradeId?: number | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() multiSelectInitVals: boolean = false;
  @Input() isValid = true;
  @Input() required = false;
  @Input() label = 'Program Type (based on Age Group)';
  @Input() altLabel = 'Program';
  @Input() reloadProgramType = false;
  programTypeOptions?: IAgeGradeEnrollmentProgramFilter[] = [];
  public get select() {
    return SelectType;
  }

  constructor(
    private lookupService: LookupService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      if (this.multiSelect) {
        this.programTypeSelect?.clearAll();
      } else {
        this.programTypeSelect?.clearSearch(true);
      }
    }

    if (this.reloadProgramType) {
      if (this.ageGradeId) {
        this.getProgramType(this.ageGradeId);
      }
    }

    if (changes['ageGradeId']) {
      if (this.ageGradeId) {
        this.programTypeSelect?.clearSearch(true);
        this.getProgramType(this.ageGradeId);
      } else this.programTypeOptions = [];
    }
  }

  clearSelected() {
    this.programTypeSelect?.clearSearch(true);
  }

  getProgramType(ageGradeId: number): void {
    this.lookupService
      .getAgeGradeEnrollmentFilters()
      .pipe(take(1))
      .subscribe((programTypes: IAgeGradeEnrollmentProgramFilter[]) => {
        this.programTypeOptions = programTypes
          .filter(
            (programType: IAgeGradeEnrollmentProgramFilter) =>
              programType.ageGradeInstrumentId === ageGradeId
          )
          .map((programType: IAgeGradeEnrollmentProgramFilter, index) => {
            return {
              ...programType,
              id: programType.id,
              enrollmentProgramName:
                programType.enrollmentProgramType?.enrollmentProgramName,
            };
          });
      });
  }

  onProgramTypesSelect(event: IAgeGradeEnrollmentProgramFilter): void {
    this.programType.emit(event);
  }
}
