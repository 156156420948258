import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { IStatusType } from '@core/interfaces/iuser';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'drdp-select-status',
  templateUrl: './select-status.component.html',
  styleUrls: ['./select-status.component.scss']
})
export class SelectStatusComponent implements OnInit {
  @ViewChild('statusSelect') statusSelect: FilterSelectComponent | undefined;
  @Output() status = new EventEmitter<any>();
  @Input() initialStatusId?: number | number[] | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  statusOptions?: IStatusType[] = [];
  public get select() {return SelectType }
  constructor(private lookupService: LookupService) { }

  ngOnInit(): void {
    this.getStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      this.statusSelect?.clearSearch(true);
    }
  }

  clearSelected() {
    this.statusSelect?.clearSelected();
  }

  getStatus(): void {
    this.lookupService.getAccountStatusTypes()
      .pipe(
        take(1)
      )
      .subscribe((status: IStatusType[]) => this.statusOptions = status);
  }

  onStatusSelect(event: IStatusType): void {
    this.status.emit(event);
  }
}
