import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SelectType } from '@core/enums/select';
import { AuthService } from '@core/services/auth.service';
import { ClassroomService } from '@core/services/classroom.service';
import { ToastService } from '@core/services/toast.service';
import { take, tap } from 'rxjs';
import { AgencyService } from '@core/services/agency.service';
import { Role } from '@core/enums/roles';
import { IAgency } from '@core/interfaces/iagency';
import { CustomValidators } from '@core/validators/custom.validator';
import { Modal } from '@core/enums/modal';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { replaceWhitespace } from '@core/services/helper.service';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { States } from '@core/enums/states';

@Component({
  selector: 'drdp-add-class-modal',
  templateUrl: './add-class-modal.component.html',
  styleUrls: ['./add-class-modal.component.scss'],
})
export class AddClassModalComponent implements OnInit {
  classroomForm: FormGroup | any;
  user = this.authService.getCurrentUser();
  options: any;
  label?: string;
  superUser?: boolean;
  payload: any = {};
  selectedState?: number | null;
  selectedAgency?: number | null;
  selectedSiteId?: number | null;
  clearDropdown = false;
  modal = inject(MatDialog);
  isProcessing: boolean = false;
  canCreate2025Classroom = this.permission.checkPermission(Permission.Create2025Classroom);
  canViewDropdowns: boolean = this.permission.checkPermission(Permission.AccessAssignedStateAgencies) 
    || this.permission.checkPermission(Permission.AccessAllAgencies);
  states = States;

  get selectType() {
    return SelectType;
  }
  get role() {
    return Role;
  }

  get name() {
    return this.classroomForm.get('name');
  }
  get siteId() {
    return this.classroomForm.get('siteId');
  }
  get agencyId() {
    return this.classroomForm.get('agencyId');
  }
  get stateId() {
    return this.classroomForm.get('stateId');
  }
  get classroomId() {
    return this.classroomForm.get('classroomId');
  }
  get isPilot() {
    return this.classroomForm.get('isPilot');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    public dialogRef: MatDialogRef<AddClassModalComponent>,
    private fb: FormBuilder,
    public toast: ToastService,
    private classroomService: ClassroomService,
    private authService: AuthService,
    private agency: AgencyService,
    private permission: PermissionService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.superUser = this.authService.isSuperUser();
    this.initializeForm();
    if (!this.editData) {
      this.selectedState = this.user.stateId;
      this.selectedAgency = this.user.agencyId;
      this.label = 'Add Classroom';
    } else {
      this.label = 'Edit Classroom';
    }
  }

  initializeForm(): void {
    this.classroomForm = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(100), CustomValidators.cannotContainWhiteSpace]],
      siteId: [null, Validators.required],
      agencyId: [this.user.stateId, Validators.required],
      stateId: [this.user.agencyId, Validators.required],
      isPilot: [false],
    });

    if (this.editData) {
      const edit = this.editData.data;
      this.selectedState = edit.stateId;
      this.selectedAgency = edit.agencyId;
      this.selectedSiteId = edit.siteId;

      this.classroomForm.patchValue({
        name: edit.className,
        siteId: this.selectedSiteId,
        agencyId: this.selectedAgency,
        stateId: this.selectedState,
        isPilot: edit.isPilot,
      });
    }

    this.isPilot.valueChanges.subscribe((isPilot: boolean) => {
      let currentName = this.name?.value || '';
      
      if (isPilot && !currentName.startsWith('2025 ')) {
        this.name?.setValue(`2025 ${currentName}`);
      } else if (!isPilot) {
        if (currentName.startsWith('2025 ')) this.name?.setValue(currentName.replace('2025 ', ''));

        const errors = this.name.errors || {};
        delete errors['invalidPilotName'];
        if (Object.keys(errors).length === 0) this.name.setErrors(null);
        else this.name.setErrors(errors);
      }
    });
  }

  handleDropdown(data: any, type: string | number): void {
    if (type == this.selectType.States) {
      this.selectedState = data ? data.id : null;
      this.stateId.setValue(this.selectedState);

      if (this.selectedState) {
        this.agency
          .getAgenciesByState(this.selectedState)
          .pipe(
            take(1),
            tap((agency: IAgency[]) => {
              this.selectedAgency = agency[0] ? agency[0].id : null;
              this.agencyId.setValue(this.selectedAgency);
            }),
            tap((_) => {
              this.clearDropdown = true;
              this.selectedSiteId = null;
              this.stateId.setValue(this.selectedState);
            })
          )
          .subscribe();
      }
    } else if (type == this.selectType.Agencies) {
      this.selectedAgency = data ? data.id : null;
      this.agencyId.setValue(this.selectedAgency);
    } else if (type == this.selectType.Sites) {
      this.selectedSiteId = data ? data.id ?? data[0].id : null;
      this.siteId.setValue(this.selectedSiteId);
    }
  }

  onSubmit(): any {
    if (this.isProcessing) return;
    if (this.classroomForm.invalid) {
      this.toast.error('Please enter all required fields.');
      return;
    }
    this.name.setValue(replaceWhitespace(this.name.value));
    
    if (this.isPilot.value && !this.name.value.startsWith('2025 ')) {
      this.name.setErrors({ invalidPilotName: true });
      this.name.markAsTouched();
      return;
    }

    this.payload = {
      id: this.editData?.data.classId ?? 0,
      ...this.classroomForm.value,
    };

    this.isProcessing = true;
    this.classroomService
      .saveClassroom(this.payload)
      .pipe(take(1))
      .subscribe((success) => {
        this.isProcessing = false;
        if (success)  {
          this.dialogRef.close({ success: true })
          var event = { data: { modalInfo: {} } };
           event.data.modalInfo = {
              title: 'Success',
              message: `Your classroom name has been saved.  Please go to Teacher Assignments and Rating Periods in the Setup Menu to finish creating your classroom.`,
              primaryBtnClass: 'blue',
              showCancel: false,
              primaryBtnText: Modal.OK
           };
          this.modal.open(ConfirmationModalComponent, { data: event ? event : undefined });
        };
      });
  }
}
