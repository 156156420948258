import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DrdpLayoutComponent } from '../drdp-layout/drdp-layout.component';
import { UserRolesComponent } from './user-roles/user-roles.component';
import { FeaturesComponent } from './features/features.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { LoginAuditsComponent } from './login-audits/login-audits.component';
import { UserAccountComponent } from './user-account/user-account.component';
import { GlobalAdminComponent } from './global-admin/global-admin.component';
import { PermissionGuard } from '@core/guards/permission.guard';
import { Permission } from '@core/enums/permissions';

const routes: Routes = [
  {
    path: 'accounts',
    canActivateChild: [AuthGuard],
    component: DrdpLayoutComponent,
    children: [
      {
        path: 'users',
        canActivate: [PermissionGuard],
        data: { permission: Permission.SearchAccounts },
        component: UserAccountComponent,
      },
      {
        path: 'user-roles',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewUserRoles },
        component: UserRolesComponent,
      },
      {
        path: 'features',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewFeatures },
        component: FeaturesComponent,
      },
      {
        path: 'permissions',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewPermissions },
        component: PermissionsComponent,
      },
      {
        path: 'login-audits',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewLoginAudits },
        component: LoginAuditsComponent,
      },
      {
        path: 'global-admin',
        canActivate: [PermissionGuard],
        data: { permission: Permission.ViewGlobalAdmin },
        component: GlobalAdminComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountsRoutingModule {}
