import { Component, inject, OnInit } from '@angular/core';
import { ReportService } from '@core/services/report.service';
import { IReportSearch } from '@views/user/reports';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-class-planning',
  templateUrl: './class-planning.component.html',
  styleUrls: ['./class-planning.component.scss'],
})
export class ClassPlanningComponent implements OnInit {
  private reportService = inject(ReportService);

  reportData: string | undefined;
  loading: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  showReport(searchValues: IReportSearch) {
    this.loading = true;
    if (!searchValues.locale) {
      searchValues.locale = 'en';
    }

    this.reportService
      .generateClassPlanningReport(searchValues)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.reportData = res;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  downloadReport(searchValues: IReportSearch) {
    this.loading = true;
    this.reportService
      .generateClassPlanningReportPdf(searchValues)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          const file = new Blob([res], { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileUrl;
          let fileName = `class_planning_report`;
          link.download = `${fileName.replace(/ /g, '_')}.pdf`;
          link.click();
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  handleClear(): void {
    this.reportData = undefined;
  }
}
