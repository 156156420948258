import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import {
  IStudent,
  IStudentClassEnrollmentDetails,
  IStudentDetails,
  IStudentDuplicateValidationDto,
  IStudentSearchRequestDto,
  IStudentUpdateDto,
  IStudentSharedProviderDto,
  IUserSpecialEdPowerDto,
  IAddStudentSharedProviderDto,
  IStudentSearchResponseDto,
  IStudentDualEnrollment,
  IUpdateSpedDatesRequest,
} from './../interfaces/istudent';
import { ICustomFieldValue } from '@core/interfaces/icustom-field-value';
import { getSearchUrl } from './helper.service';
import { NumberLiteralType } from 'typescript';

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  path: string = 'student';

  constructor(private dataService: DataService) {}

  getStudents(): Observable<IStudent[]> {
    return this.dataService.get(`${this.path}/recent`);
  }

  getStudentById(studentId: number): Observable<IStudent> {
    return this.dataService.get(`${this.path}/${studentId}`);
  }

  getStudentCustomFields(studentId: number): Observable<ICustomFieldValue[]> {
    return this.dataService.get(`${this.path}/custom-fields/${studentId}`);
  }

  addStudent(payload: IStudent): Observable<boolean> {
    return this.dataService.post(`${this.path}`, payload);
  }

  updateStudent(
    payload: IStudentUpdateDto,
    message?: string
  ): Observable<boolean> {
    return this.dataService.put(`${this.path}`, payload, message);
  }

  updateStudentGeneric(
    payload: IStudent,
    message?: string
  ): Observable<boolean> {
    return this.dataService.put(`${this.path}/update`, payload, message);
  }

  deleteStudent(studentId: string): Observable<boolean> {
    return this.dataService.delete(`${this.path}`, studentId);
  }

  addAndEnrollStudent(payload: IStudent): Observable<boolean> {
    return this.dataService.post(`${this.path}`, payload);
  }

  search(payload: IStudentSearchRequestDto): Observable<any> {
    return this.dataService.post(`student/search?`, payload);
  }

  addSearch(payload: any): Observable<any> {
    return this.dataService.post(`student/add-search?`, payload);
  }

  getStudentDetails(childId: number): Observable<IStudentDetails> {
    return this.dataService.get(`student/view-details/${childId}`);
  }

  getStudentAssessments(childId: number): Observable<any> {
    return this.dataService.get(`student/assessments/${childId}`);
  }

  getClassroomEnrollmentDetails(
    childId: number,
    message?: string
  ): Observable<IStudentClassEnrollmentDetails> {
    return this.dataService.get(
      `student/view-class-enrollments/${childId}`,
      message
    );
  }

  searchDroppedChildren(params: any): Observable<any> {
    const url = getSearchUrl('student/dropped-children?', params);
    return this.dataService.get(url);
  }

  validateStudent(
    payload: IStudentDuplicateValidationDto
  ): Observable<boolean> {
    return this.dataService.post('student/validate', payload);
  }

  getDuplicateOrDroppedChildren(params: any): Observable<any> {
    const url = getSearchUrl('student/duplicate-or-dropped?', params);
    return this.dataService.get(url);
  }

  getStudentSharedProviders(
    studentId: number
  ): Observable<IStudentSharedProviderDto[]> {
    return this.dataService.get(`${this.path}/shared-provider/${studentId}`);
  }

  getStudentSharedProviderUsers(): Observable<IUserSpecialEdPowerDto[]> {
    return this.dataService.get(`${this.path}/shared-provider/user`);
  }

  addStudentSharedProvider(
    payload: IAddStudentSharedProviderDto
  ): Observable<boolean> {
    return this.dataService.post(`${this.path}/shared-provider`, payload);
  }

  deleteStudentSharedProvider(id: number): Observable<boolean> {
    return this.dataService.delete(`${this.path}/shared-provider?id=${id}`);
  }

  updateStudentPilotRole(payload: any): Observable<boolean> {
    return this.dataService.put(
      `${this.path}/assign-pilot-test`,
      payload,
      'Student Pilot Test Role Updated.'
    );
  }

  checkDuplicateStringSSID(ssid: string, stateId: number): Observable<boolean> {
    return this.dataService.get(
      `${this.path}/check-string-ssid/${ssid}/stateId/${stateId}`
    );
  }

  checkDuplicateLongSSID(ssid: number): Observable<boolean> {
    return this.dataService.get(`${this.path}/check-long-ssid/${ssid}`);
  }

  getStudentBatch(
    payload: IStudentSearchRequestDto
  ): Observable<IStudentSearchResponseDto[]> {
    return this.dataService.post(`${this.path}/student-batch`, payload);
  }

  saveDualEnrollment(
    payload: IStudentDualEnrollment
  ): Observable<boolean> {
    return this.dataService.post(`${this.path}/dual-enroll`, payload);
  }

  updateStudentSpedDates(
    payload: IUpdateSpedDatesRequest
  ): Observable<boolean> {
    return this.dataService.post(`${this.path}/sped-dates`, payload);
  }
}
