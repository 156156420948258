import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { PathInterceptorService } from '@core/interceptors/path-interceptor.service';
import { AuthInterceptorService } from '@core/interceptors/auth-interceptor.service';
import { CoreModule } from '@core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserModule } from '@views/user/user.module';
import { ConfigurationModule } from '@views/user/configuration/configuration.module';
import { AccountsModule } from '@views/user/accounts/accounts.module';
import { ResourceModule } from '@views/resource/resource.module';
import { UploadModule } from '@views/user/upload/upload.module';
import { DownloadsModule } from '@views/user/downloads/downloads.module';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '@env/environment';

const recaptchaSettings: RecaptchaSettings = { siteKey: environment.recaptcha };

@NgModule({
  declarations: [AppComponent],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UserModule,
    ConfigurationModule,
    AccountsModule,
    ResourceModule,
    UploadModule,
    DownloadsModule,
    RecaptchaV3Module
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PathInterceptorService,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: recaptchaSettings.siteKey,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
