import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILockingStatus } from '@core/interfaces/ilockingstatus';

@Component({
  selector: 'drdp-add-edit-locking-status',
  templateUrl: './add-edit-locking-status.component.html',
  styleUrls: ['./add-edit-locking-status.component.scss']
})
export class AddEditLockingStatusComponent implements OnInit {
  lockingStatusForm: FormGroup | any;

  get isActive() { return this.lockingStatusForm.get('active'); }

  constructor(public dialogRef: MatDialogRef<AddEditLockingStatusComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public editData: any) { dialogRef.disableClose = true }

  ngOnInit(): void {
    this.initializeForms();
  }

  initializeForms(): void {
    this.lockingStatusForm = this.fb.group({
      lockType: ["Lock Rating View"],
      lockDate: [this.editData ? this.editData.lockDate: null, 
                  [Validators.required]],
      active: [this.editData ? this.editData.active : false]
    })
  }

  handleDate(event: any): void {
    this.lockingStatusForm.get('lockDate').setValue(event);
  }

  onSubmit(): any {
    let payload: ILockingStatus = this.lockingStatusForm.value;
    if (this.editData) {
      payload.id = this.editData.id;
    }
    let updateTableView: ILockingStatus = Object.assign({}, this.lockingStatusForm.value);
    updateTableView.active = payload.active;
    updateTableView.lockType = payload.lockType
    updateTableView.lockDate = payload.lockDate;

    return { formValid: true, payload: payload, updateTableView: updateTableView}
  }
}
