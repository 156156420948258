import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "@core/guards/auth.guard";
import { DrdpLayoutComponent } from "@views/user/drdp-layout/drdp-layout.component";
import { PermissionGuard } from "@core/guards/permission.guard";
import { Permission } from "@core/enums/permissions";
import { ParentProgressComponent } from "@views/user/reports/parent-progress/parent-progress.component";
import { ClassPlanningComponent } from './class-planning/class-planning.component';
import { GroupDetailComponent } from './group-detail/group-detail.component';
import { CohortProgressComponent } from './cohort-progress/cohort-progress.component';
import { ChildProgressComponent } from '@views/user/reports/child-progress/child-progress.component';
import { GroupComponent } from './group/group.component';
import { RatingCompletionComponent } from './rating-completion/rating-completion.component';
import { ReportTrackingComponent } from './report-tracking/report-tracking.component';
import { DistrictOsepComponent } from './district-osep/district-osep.component';
import { StateOsepComponent } from './state-osep/state-osep.component';
import { TeacherSurveyComponent } from './teacher-survey/teacher-survey.component';

const routes: Routes = [
  {
    path: 'reports',
    canActivateChild: [AuthGuard],
    component: DrdpLayoutComponent,
    children: [
      {
        path: 'child-progress',
        canActivate: [PermissionGuard],
        data: {permission: Permission.ChildProgressReport},
        component: ChildProgressComponent,
      },
      {
        path: 'class-planning',
        canActivate: [PermissionGuard],
        data: {permission: Permission.ClassPlanningReport},
        component: ClassPlanningComponent,
      },
      {
        path: 'parent-progress',
        canActivate: [PermissionGuard],
        data: {permission: Permission.ParentProgressReport},
        component: ParentProgressComponent,
      },
      {
        path: 'group-detail',
        canActivate: [PermissionGuard],
        data: {permission: Permission.GroupDetailsReport},
        component: GroupDetailComponent,
      },
      {
        path: 'cohort-progress',
        canActivate: [PermissionGuard],
        data: {permission: Permission.CohortProgressReport},
        component: CohortProgressComponent,
      },
      {
        path: 'group',
        canActivate: [PermissionGuard],
        data: {permission: Permission.GroupReport},
        component: GroupComponent,
      },
      {
        path: 'rating-completion',
        canActivate: [PermissionGuard],
        data: {permission: Permission.RatingCompletion},
        component: RatingCompletionComponent,
      },
      {
        path: 'report-tracking',
        canActivate: [PermissionGuard],
        data: {permission: Permission.AccessToReportTracking},
        component: ReportTrackingComponent,
      },
      {
        path: 'district-osep',
        canActivate: [PermissionGuard],
        data: {permission: Permission.DistrictOSEPReport},
        component: DistrictOsepComponent,
      },
      {
        path: 'state-osep',
        canActivate: [PermissionGuard],
        data: {permission: Permission.StateOSEPReport},
        component: StateOsepComponent,
      },
      {
        path: 'teacher-survey',
        canActivate: [PermissionGuard],
        data: {permission: Permission.PilotIncludeRatings},
        component: TeacherSurveyComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule {
}
