<div class=" rounded-3xl bg-white p-3 drop-shadow-md">
  <div *ngIf="!isUncategorized">
    <div class="flex justify-between items-center cursor-pointer" (click)="changeShow()">
      <div class=" pl-1.5">
        <p class="text-lg font-semibold">{{group?.superPermission?.permissionName}}</p>
      </div>

      <i [ngClass]="{'uil uil-angle-down text-xl': !show,
                     'uil uil-angle-right text-xl': show}"></i>
    </div>

    <div *ngIf="show" class="flex flex-col">
      <div *ngIf="!group?.subPermissions?.length">
        <p class=" text-center">No Permissions Available</p>
      </div>

      <div *ngIf="group?.subPermissions?.length"
        class="overflow-x-auto w-full scrollbar scrollbar-thumb-white scrollbar-track-gray-100 py-4">
        <div class=" relative">

          <table class="table-fixed whitespace-nowrap">
            <!-- Table Headers -->
            <thead class="sticky top-0">
              <tr>
                <th scope="col" class=" sticky bg-white left-0 p-4"></th>
                <th scope="col"
                  class="py-3 px-6 text-xs font-medium tracking-wider text-left text-black bg-white divide-x"
                  *ngFor="let role of roles">
                  {{role.roleName}}
                </th>
              </tr>
            </thead>

            <tbody>
              <!-- Build rows for each permission in specific group -->
              <tr *ngFor="let permission of group?.subPermissions">
                <td class="sticky left-0 bg-white py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{permission.permissionName}}
                </td>

                <!-- Build columns for row, for each user role -->
                <td *ngFor="let role of roles"
                  class="py-4 px-6 text-sm font-medium whitespace-nowrap bg-white border-r border-slate-200 text-center">

                  <input type="checkbox"
                    class="text-blue-600 focus:ring-blue-500 dark:focus:ring-blue-600 text-sm cursor-pointer"
                    [checked]="checkAssignment(permission.id, role.id)"
                    (click)="emitPermissionAssignment(permission.id, role.id)">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isUncategorized">
    <div class="flex justify-between items-center cursor-pointer" (click)="changeShow()">
      <div class=" pl-1.5">
        <p class="text-lg font-semibold">Non Categorized Features</p>
      </div>

      <i [ngClass]="{'uil uil-angle-down text-xl': !show,
                       'uil uil-angle-right text-xl': show}"></i>
    </div>

    <div *ngIf="show" class="flex flex-col">
      <div *ngIf="!uncategorizedList?.length">
        <p class=" text-center">No Permissions Available</p>
      </div>

      <div class="overflow-x-auto w-full scrollbar scrollbar-thumb-white scrollbar-track-gray-100 py-4">
        <div class=" relative">

          <table class="table-fixed whitespace-nowrap">
            <!-- Table Headers -->
            <thead class="sticky top-0">
              <tr>
                <th scope="col" class=" sticky bg-white left-0 p-4"></th>
                <th scope="col"
                  class="py-3 px-6 text-xs font-medium tracking-wider text-left text-black bg-white divide-x"
                  *ngFor="let role of roles">
                  {{role.roleName}}
                </th>
              </tr>
            </thead>

            <tbody>
              <!-- Build rows for each permission in specific group -->
              <tr *ngFor="let permission of uncategorizedList">
                <td class="sticky left-0 bg-white py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                  {{permission.permissionName}}
                </td>

                <!-- Build columns for row, for each user role -->
                <td *ngFor="let role of roles"
                  class="py-4 px-6 text-sm font-medium whitespace-nowrap bg-white border-r border-slate-200 text-center">

                  <input type="checkbox"
                    class="text-blue-600 focus:ring-blue-500 dark:focus:ring-blue-600 text-sm cursor-pointer"
                    [checked]="checkAssignment(permission.id, role.id)"
                    (click)="emitPermissionAssignment(permission.id, role.id)">
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</div>