import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { UploadRoutingModule } from './upload-routing.module'
import { SharedModule } from '@shared/shared.module';
import { UploadCenterComponent } from './upload-center/upload-center.component';
import { UploadCenterAllInstrumentChildComponent } from './upload-center/upload-center-all-instrument-child/upload-center-all-instrument-child.component';
import { UploadCenterClassComponent } from './upload-center/upload-center-class/upload-center-class.component';
import { UploadCenterUserComponent } from './upload-center/upload-center-user/upload-center-user.component';

@NgModule({
  declarations: [UploadCenterComponent, UploadCenterAllInstrumentChildComponent, UploadCenterClassComponent, UploadCenterUserComponent],
  imports: [CommonModule, UploadRoutingModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UploadModule {}
