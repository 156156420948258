import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IAgency,
  IAgencyCodeType,
  IAgencyType,
} from '@core/interfaces/iagency';
import { IVendor } from '@core/interfaces/ivendor';
import { AgencyService } from '@core/services/agency.service';
import { IState } from '@core/interfaces/istate';
import { ICounty } from '@core/interfaces/icounty';
import { Observable, Subscription, take } from 'rxjs';
import { ToastService } from '@core/services/toast.service';
import { SelectType } from '@core/enums/select';
import { HeadStart } from '@core/enums/head-start';
import { IRegionType } from '@core/interfaces/iregion-type';
import { StateService } from '@core/services/state.service';
import { IHeadStartOption } from '@core/interfaces/ihead-start';
import { StatePreschool } from '@core/enums/state-preschool';

@Component({
  selector: 'drdp-add-edit-agency',
  templateUrl: './add-edit-agency.component.html',
  styleUrls: ['./add-edit-agency.component.scss'],
})
export class AddEditAgencyComponent implements OnInit {
  private subscriptions = new Subscription();
  isProcessing = false;
  agencyForm: FormGroup | any;
  selectedState?: IState;
  selectedCodeType?: IAgencyCodeType;
  selectedType?: IAgencyType;
  selectedCounty?: ICounty;
  selectedVendor?: IVendor[];
  selectedRegion?: number[];
  title = 'Add New Agency';
  isAgencyDelegate = false;

  public get HeadStart() {
    return HeadStart;
  }
  public get StatePreschool() {
    return StatePreschool;
  }

  getSelectData$: Observable<any> | undefined;

  public get selectType() {
    return SelectType;
  }
  get agencyName() {
    return this.agencyForm.get('agencyName');
  }
  get cdsCode() {
    return this.agencyForm.get('cdsCode');
  }
  get codeType() {
    return this.agencyForm.get('codeType');
  }
  get stateId() {
    return this.agencyForm.get('stateId');
  }
  get typeId() {
    return this.agencyForm.get('typeId');
  }
  get footer() {
    return this.agencyForm.get('footer');
  }
  get agencyAddress() {
    return this.agencyForm.get('agencyAddress');
  }
  get agencyCity() {
    return this.agencyForm.get('agencyCity');
  }
  get agencyZipCode() {
    return this.agencyForm.get('agencyZipCode');
  }
  get countyId() {
    return this.agencyForm.get('countyId');
  }

  get headStartId() {
    return this.agencyForm.get('headStartId');
  }
  get granteeAgencyId() {
    return this.agencyForm.get('granteeAgencyId');
  }

  get vendorIds() {
    return this.agencyForm.get('vendorIds');
  }
  get regionIds() {
    return this.agencyForm.get('regionIds');
  }

  get statePreschoolId() {
    return this.agencyForm.get('statePreschoolId');
  }
  get contractorAgencyId() {
    return this.agencyForm.get('contractorAgencyId');
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddEditAgencyComponent>,
    private agencyService: AgencyService,
    private toast: ToastService,
    public stateService: StateService,
    @Inject(MAT_DIALOG_DATA) public editData: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.initializeForms();
  }

  initializeForms(): void {
    this.agencyForm = this.fb.group({
      agencyName: [null, [Validators.required, Validators.maxLength(100)]],
      cdsCode: [null, [Validators.required, Validators.maxLength(25)]],
      codeType: [null, [Validators.required]],
      stateId: [null, [Validators.required]],
      typeId: [null, [Validators.required]],
      agencyAddress: [null, [Validators.required, Validators.maxLength(100)]],
      agencyCity: [null, [Validators.required, Validators.maxLength(25)]],
      agencyZipCode: [null, [Validators.required, Validators.maxLength(10)]],
      countyId: [
        null,
        [Validators.maxLength(50), Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      ],
      footer: [null, Validators.maxLength(250)],
      headStartId: [null],
      granteeAgencyId: [null],
      vendorIds: [null, Validators.required],
      regionIds: [null],
      statePreschoolId: [null],
      contractorAgencyId: [null]
    });

    this.headStartId.valueChanges.subscribe((val: any) => {
      if (val == HeadStart.HeadStartDelegate) {
        this.granteeAgencyId.setValidators([Validators.required]);
      } else {
        this.granteeAgencyId.clearValidators();
      }
      this.granteeAgencyId.updateValueAndValidity();
    });

    this.statePreschoolId.valueChanges.subscribe((val: any) => {
      if (val == StatePreschool.StatePreschoolSubcontractor) {
        this.contractorAgencyId.setValidators([Validators.required]);
      } else {
        this.contractorAgencyId.clearValidators();
      }
      this.contractorAgencyId.updateValueAndValidity();
    });

    if (this.editData && this.editData.data && this.editData.data.id) {
      const edit = this.editData.data;

      this.title =
        edit.headStartId == HeadStart.HeadStartGrantee
          ? 'Edit Head Start Grantee Agency'
          : 'Edit Agency';

      this.agencyForm.patchValue({
        ...edit,
        agencyName: edit.agencyName,
        countyID: edit.countyID,
        codeType: edit.codeType,
        stateId: edit.stateId,
        typeId: edit.siteTypeId,
        headStartId: edit.headStartId,
        granteeAgencyId: edit.granteeAgencyId,
        statePreschoolId: edit.statePreschoolId,
        contractorAgencyId: edit.contractorAgencyId,
        vendorIds: edit.vendorIds?.split(',').map(Number),
        regionIds: edit.regionIds?.split(',').map(Number),
      });
      this.isAgencyDelegate = edit.headStartId == HeadStart.HeadStartDelegate;

      this.selectedState = edit.state;
      this.selectedCodeType = edit.siteCodeType;
      this.selectedType = edit.siteType;
      this.selectedCounty = edit.county;
      this.selectedVendor = edit.vendors;
      this.selectedRegion = edit.regionIds?.split(',').map(Number);
    } else if (this.editData && this.editData.isHeadStartGrantee) {
      this.title = 'Add New Head Start Grantee Agency';
      this.headStartId.setValue(HeadStart.HeadStartGrantee);
    }
  }

  onDropDownSelect(event: any, selectType: string) {
    switch (selectType) {
      case SelectType.States:
        this.selectedState = event;
        this.stateId.setValue(event?.id);
        this.stateId.markAsTouched();
        this.regionIds.setValue([]);
        break;
      case SelectType.CodeType:
        this.codeType.setValue(event?.id);
        this.codeType.markAsTouched();
        if (this.selectedCodeType) {
          this.selectedCodeType = event;
        }
        break;
      case SelectType.SiteType:
        this.typeId.setValue(event?.id);
        this.typeId.markAsTouched();
        if (this.selectedType) {
          this.selectedType = event;
        }
        break;
      case SelectType.County:
        this.selectedCounty = event;
        this.countyId.setValue(event?.id);
        this.countyId.markAsTouched();
        break;
      case SelectType.RegionRef:
        this.selectedRegion = event;
        this.regionIds.setValue(event);
        this.regionIds.markAsTouched();
        break;
      case SelectType.HeadStartRef:
        this.headStartId.setValue(event?.id);
        this.headStartId.markAsTouched();
        break;
      case SelectType.HeadStartGranteeRef:
        this.granteeAgencyId.setValue(event?.id);
        this.granteeAgencyId.markAsTouched();
        break;
      case SelectType.StatePreschoolContractorRef:
        this.contractorAgencyId.setValue(event?.id);
        this.contractorAgencyId.markAsTouched();
        break;
      case SelectType.VendorRef:
        this.selectedVendor = event;
        this.vendorIds.setValue(
          event?.map((vendorIds: IVendor) => vendorIds.id)
        );
        this.vendorIds.markAsTouched();
        break;
      default:
        break;
    }
  }

  onSubmit(): any {
    if (this.isProcessing) return;
    if (this.agencyForm.invalid) {
      this.toast.error('Please enter all required fields.');
      return;
    }
    // Sends Payload obj to parent
    let payload: IAgency = this.agencyForm.value;
    if (payload.headStartId != HeadStart.HeadStartDelegate)
      payload.granteeAgencyId = null;

    if (payload.statePreschoolId != StatePreschool.StatePreschoolSubcontractor)
      payload.contractorAgencyId = null;

    if (this.editData && this.editData?.data?.id) {
      payload.id = this.editData.data.id;
    }

    // Send new Agency back to table for Re-rendering
    let updateTableView: IAgency = Object.assign({}, this.agencyForm.value);
    updateTableView.agencyName = this.agencyName;
    updateTableView.state = this.selectedState;
    updateTableView.stateCode = this.selectedState?.stateCode;
    updateTableView.siteCodeType = this.selectedCodeType;
    updateTableView.siteType = this.selectedType;
    updateTableView.county = this.selectedCounty;
    updateTableView.vendors = this.selectedVendor;
    updateTableView.vendorNames = this.selectedVendor?.map(
      (vendor: any) => vendor.name
    );
    updateTableView.regionIds = this.selectedRegion;

    this.isProcessing = true;
    if (payload.id) {
      this.agencyService
        .updateAgency(payload, 'Agency has been successfully updated.')
        .pipe(take(1))
        .subscribe((success: boolean) => {
          this.isProcessing = false;
          if (success) {
            this.dialogRef.close({
              success: true,
              updateTableView: updateTableView,
            });
          }
        });
    } else {
      this.agencyService
        .addNewAgency(payload, 'Agency has been successfully added.')
        .pipe(take(1))
        .subscribe((success: any) => {
          this.isProcessing = false;
          if (success) {
            this.dialogRef.close({
              success: true,
              updateTableView: updateTableView,
            });
          }
        });
    }
  }

  onHeadStartSelect(selectedValue: IHeadStartOption): void {
    if (selectedValue?.id == HeadStart.HeadStartDelegate) {
      this.headStartId.setValue(HeadStart.HeadStartDelegate)
      this.granteeAgencyId.setValue(null);
    } else if (selectedValue?.id == HeadStart.HeadStartGrantee) {
      this.headStartId.setValue(HeadStart.HeadStartGrantee)
    }
    else
      this.headStartId.setValue(null);
  }

  cancel(): void {
    this.agencyForm.reset();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onStatePreschoolSelect(selectedValue: any): void {
    if (selectedValue?.id == StatePreschool.StatePreschoolContractor) {
      this.statePreschoolId.setValue(StatePreschool.StatePreschoolContractor)
      this.contractorAgencyId.setValue(null);
    } else if(selectedValue?.id == StatePreschool.StatePreschoolSubcontractor){
      this.statePreschoolId.setValue(StatePreschool.StatePreschoolSubcontractor)
    }
    else
      this.statePreschoolId.setValue(null);
  }
}
