import { Injectable } from '@angular/core';
import { IState } from '@core/interfaces/istate';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from './data.service';
import { IStateSchoolYearTarget } from '@core/interfaces/istateSchoolYearTarget';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor(private data: DataService) { }

  getAllStates():Observable<IState[]>{
    return this.data.get('state/all')
  }

  getStatesById(index: number):Observable<any>{
    return this.data.get(`state/${index}`)
  }

  getStateConfigurations(stateId: number): Observable<any> {
    return this.data.get(`state/demographic/${stateId}`);
  }

  getCustomStateConfigurations(stateId: number): Observable<any> {
    return this.data.get(`state/demographic-custom/${stateId}`);
  }

  getLinkedStates(userId: number): Observable<any> {
    return this.data.get(`state/linked-states/${userId}`);
  }

  updateStateConfigurations(payload: any): Observable<any> {
    return this.data.post(`state/demographic`, payload, 'Updated State Configurations');
  }

  editStates(changedState:IState):Observable<any>{
    return this.data.put('state', changedState, 'Updated State')
  }

  getStateSchoolYearTargets(stateId: number): Observable<IStateSchoolYearTarget[]> {
    return this.data.get(`state/targets/${stateId}`);
  }

  saveStateSchoolYearTarget(payload: IStateSchoolYearTarget): Observable<boolean> {
    return this.data.post('state/target', payload, 'School Year OSEP Target added successfully.');
  }

  updateStateSchoolYearTarget(payload: IStateSchoolYearTarget, message?: string): Observable<boolean> {
    return this.data.put('state/target', payload, message);
  }

  deleteStateSchoolYearTarget(id: number, message?: string): Observable<boolean> {
    return this.data.delete(`state/target/${id}`, message);
  }
}
