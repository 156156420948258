import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Permission } from '@core/enums/permissions';
import { IPortfolioConversationRequestDto, IPortfolioMessageResponse } from '@core/interfaces/iportfolio-message';
import { PermissionService } from '@core/services/permission.service';
import { MessageStatus } from '@core/enums/message-status';
@Component({
  selector: 'drdp-message-tile',
  templateUrl: './message-tile.component.html',
  styleUrls: ['./message-tile.component.scss'],
})
export class MessageTileComponent {
  @Input() message!: IPortfolioMessageResponse;
  @Output() selectedMessage = new EventEmitter();
  public get MessageStatus() { return MessageStatus }
  canAccessAllMessages: boolean = this.permissionService.checkPermission(
    Permission.AccessToAllPortfolioMessageBoards
  )

  constructor(private permissionService: PermissionService) {}
  
  emitSelectedMessage(message: IPortfolioMessageResponse) {
    const payload: IPortfolioConversationRequestDto = {
      sceId: message.studentClassEnrollmentId,
      studentId: message.studentId,
      createdById: message.createdBy,
      isArchived: false,
      recipientId: message.recipientId
    }
    this.selectedMessage.emit(payload);
  }

}
