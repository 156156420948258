<div class="mx-auto fix-action-row modal-body">
    <div class="flex items-center py-2">
        <drdp-pill
            [pillClass]="newsData.newsItemType.newsItemTypeName">
            {{ newsData.newsItemType.newsItemTypeName ?? '' }}
        </drdp-pill>
        <drdp-btn *ngIf="canEdit" class="mx-2" (click)="editNewsItem()" cdkFocusInitial><i class="uil uil-pen cursor-pointer"></i> Edit News Item</drdp-btn>
        <i mat-button mat-dialog-close class="text-drdpblue-300 uil uil-times-circle ml-auto"></i>
    </div>
    <div class="mb-2 border-b border-gray-100">
        <span class="text-[25px] font-bold">
            {{this.newsData.newsItemTitle}}
        </span>
        <span class="text-gray-500">{{this.newsData.newsItemDate | date: 'longDate'}}</span>
    </div>

    <div id="news-body-div" mat-dialog-content>
        <p>{{ this.newsData.newsItemBody }}</p>
    </div>

</div>

