import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StudentService } from '@core/services/student.service';
import { CustomValidators } from '@core/validators/custom.validator';
import { ToastService } from '@core/services/toast.service';
import { IDroppedChild } from '@core/interfaces/idropped-children';
import { MatTableDataSource } from '@angular/material/table';
import { setIconDisplayObject } from '@core/services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EnrollDroppedChildrenComponent } from './enroll-dropped-children/enroll-dropped-children.component';

@Component({
  selector: 'drdp-search-dropped-children',
  templateUrl: './search-dropped-children.component.html',
  styleUrls: ['./search-dropped-children.component.scss']
})
export class SearchDroppedChildrenComponent implements OnInit {

  private subscriptions = new Subscription();

  title = 'Search Dropped Children';
  droppedChildren: IDroppedChild[] = [];
  searchForm: FormGroup | any;
  tableData?: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  addIcon: string = 'user-plus'

  tableColumns = [
    { columnDef: 'firstName', header: 'First Name', type: 'text' },
    { columnDef: 'lastName', header: 'Last Name', type: 'text' },
    { columnDef: 'drdpId', header: 'DRDP ID', type: 'text' },
    { columnDef: 'dob', header: 'Date of Birth', type: 'date'},
    { columnDef: 'icon',  header: 'Add', icon: 'user-plus', type: 'icon', colorClass: 'text-drdpblue-300', cursorClass: 'cursor-pointer' }
  ]

  get drdpId() { return this.searchForm.get('drdpId'); }
  get dob() { return this.searchForm.get('dob'); }

  constructor(
    private fb: FormBuilder,
    private student: StudentService,
    private toast: ToastService,
    public modal: MatDialog
  ) { }

  ngOnInit(): void {
    this.initializeForms();
  }

  initializeForms(): void {
    this.searchForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      drdpId: [null, [Validators.min(1), CustomValidators.isNumber]],
      dob: [null, [Validators.required]]
    });
  }

  search(): void {
    if (this.searchForm.valid) {
      const params = this.searchForm.value;
      if (params.drdpId == null) {
        delete params.drdpId;
      }

      this.subscriptions.add(this.student.searchDroppedChildren(params).subscribe((res: any) => {
        res = res.map((data: any) => ({
          ...data,
          displayIcons: setIconDisplayObject(this.tableColumns, data)
        }));

        this.dataSource = new MatTableDataSource(res);
        this.tableData = res;
      }));
    }
  }

  handleDate(event: any): void {
    this.dob.setValue(event);
  }

  clear(): void {
    this.searchForm.reset();
  }

  openEnrollModal(event: any): void {
    const modalRef = this.modal.open(EnrollDroppedChildrenComponent, { data: event ? event : undefined });
    this.subscriptions.add(modalRef.afterClosed().subscribe((success: boolean) => {
      if (success) {
        var clearTable: any[] = []
        this.dataSource = new MatTableDataSource(clearTable);
        this.tableData = clearTable;
      }
    }));
  }

  onDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
