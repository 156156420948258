export enum StudentRequestReason {
  Transfer = 1,
  Drop = 2,
  ReEnroll = 3,
  Release = 4,
  ReEnrollStudent = 5,
}

export enum RequestAction {
  Decline = 0,
  Accept = 1,
  Pending = 2,
}

export enum ClassReleaseReason {
  ReleaseNeededToEnrollChild = 1,
  Other = 2,
}

export enum ClassDeclineReleaseReason {
  ChildIsStillEnrolledPartTime = 1,
  ChildIsStillEnrolledFullTime = 2,
  Other = 3,
}

export enum RequestStatusType {
  Approved = 1,
  Declined = 2,
  Pending = 3,
}

export enum ReleaseReason {
  Other = 1,
  MovedOutofState = 2,
  MovedOutofAgency = 3,
}

export enum StudentStatus {
  StillEnrolled = 'Still Enrolled'
}