import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { IHeadStart } from '@core/interfaces/ihead-start';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { SelectComponent } from '../select/select.component';

@Component({
  selector: 'drdp-select-head-start',
  templateUrl: './select-head-start.component.html',
  styleUrls: ['./select-head-start.component.scss']
})
export class SelectHeadStartComponent implements OnInit {
  @ViewChild('headStartSelect') headStartSelect: FilterSelectComponent | undefined;
  @Output() headStart = new EventEmitter<any>();
  @Input() initialheadStartId?: number | null;
  @Input() initialheadStartIds?: number | null;
  @Input() multiSelectInitVals: boolean = false;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  headStartOptions?: IHeadStart[] = [];
  public get select() {return SelectType }
  constructor(private lookupService: LookupService) { }

  ngOnInit(): void {
    this.getHeadStart();
  }

  getHeadStart(): void {
    this.lookupService.getHeadStart()
      .pipe(take(1))
      .subscribe((headStart: IHeadStart[]) => {
        this.headStartOptions = headStart;
      });
  }

  onHeadStartSelect(event: IHeadStart): void {
    this.headStart.emit(event);
  }

}