export enum TableColumnType {
  view = 'view',
  icon = 'icon',
  actionIcon = 'actionIcon',
  text = 'text',
  date = 'date',
  fullDate = 'fullDate',
  bulletList = 'bulletList',
  checkBox = 'checkBox',
  select = 'select',
  datepicker = 'datepicker',
  add = 'add',
  edit = 'edit',
  toggleSwitch = 'toggleSwitch',
  delete = 'delete',
  textToSelect = 'textToSelect',
  textToInput = 'textToInput',
  textInput = 'textInput',
  textToDrdpSelect = 'textToDrdpSelect',
  action = 'action',
  boolIcon = 'boolIcon',
}

export enum FeatureTable {
  Feature = 1,
  FeatureHierarchy = 2,
}

export enum ViewChildTabs {
  Demographics = 1,
  ClassEnrollment = 2,
  CompletedAssessments = 3,
  ShareChild = 4,
  Evidence = 5
}

export enum ComponentTypeTable {
  Add = 1,
  Edit = 2,
  View = 3,
}

export enum TableInputAction {
  toggleEdit = 'toggleEdit',
  dateSelect = 'dateSelect',
  optionSelect = 'optionSelect',
  inputChange = 'inputChange',
  drdpDropdown = 'drdpDropdown',
}

export enum SearchStudentAction {
  AddChild = 1,
  RequestRelease = 2,
  ViewStudent = 3
}
