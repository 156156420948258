<div *ngIf="agencyItems">
  <div
    class="flex justify-between items-center py-1.5"
    role="button"
    (click)="dropMenuToggle()"
  >
    <div class="pl-1.5">
      <p class="text-m">{{ agencyItems.label }}</p>
      <p
        *ngIf="agencyItems.labelTwo != undefined"
        class="text-xs text-drdpgrey-200"
      >
        {{ agencyItems.labelTwo }}
      </p>
    </div>
    <i *ngIf="!show" class="uil uil-angle-down text-xl"></i>
    <i *ngIf="show" class="uil uil-angle-up text-xl"></i>
  </div>

  <div *ngIf="show" class="dropdown-item-holder">
    <ul>
      <li *ngFor="let sublink of sites; let i = index">
        <div>
          <a class="text-xs pl-2" (click)="closeMenu()">
            <span>{{ sublink.site.siteName }}</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</div>

<div *ngIf="dropdownItems">
  <div
    class="flex justify-between items-center py-1.5 border-t"
    role="button"
    (click)="dropMenuToggle()"
    *drdpCheckPermission="dropdownItems.permissionId"
  >
    <div class="pl-1.5">
      <p class="text-m">{{ dropdownItems.item }}</p>
    </div>
    <i
      class="uil text-xl"
      [ngClass]="!show ? 'uil-angle-down' : 'uil-angle-up'"
    ></i>
  </div>
  <div *ngIf="show" class="dropdown-item-holder">
    <ul>
      <li *ngFor="let sublink of dropdownItems.childItems; let i = index">
        <div *drdpCheckPermission="sublink.permissionId">
          <a
            *ngIf="sublink.linkRoute"
            class="text-xs text-drdpblue-300 pl-2"
            [routerLink]="sublink.linkRoute"
            (click)="closeMenu()"
          >
            <span>{{ sublink.item }}</span>
          </a>
          <a
            *ngIf="sublink.linkUrl"
            target="_blank"
            class="text-xs text-drdpblue-300 pl-2"
            [href]="sublink.linkUrl"
            (click)="closeMenu()"
          >
            <span>{{ sublink.item }}</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</div>
