import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { IClassStudent } from '@core/interfaces/iclasses';
import { StudentService } from '@core/services/student.service';
import { ToastService } from '@core/services/toast.service';
import { take } from 'rxjs';
import { isDateBetween } from '@core/services/helper.service';

@Component({
  selector: 'drdp-confirmation-sped-exit-date',
  templateUrl: './confirmation-sped-exit-date.component.html',
  styleUrls: ['./confirmation-sped-exit-date.component.scss']
})
export class ConfirmationSpedExitDateComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<ConfirmationSpedExitDateComponent>,
    @Inject(MAT_DIALOG_DATA) public editData: any) {
  dialogRef.disableClose = true;
}
  batchResults: any;

  ngOnInit(): void {
    this.batchResults = this.editData;
  }

  onSubmit() {

  }
}
