import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { ReportService } from '@core/services/report.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth.service';
import { SelectType } from '@core/enums/select';
import { IAgeGradeInstrument } from '@core/interfaces/iage-grade-instrument';
import { IAgencyRatingReviewsRequest } from '../../interfaces/iadmin-report-tracking';

@Component({
  selector: 'drdp-agency-rating-views',
  templateUrl: './agency-rating-views.component.html',
  styleUrls: ['./agency-rating-views.component.scss']
})

export class AgencyRatingViewsComponent implements OnInit {
  user = this.authService.getCurrentUser();
  agencyRatingViewsSearch: FormGroup | any;
  clearDropdowns: boolean = false;
  allAgencies: boolean = false;
  btnClass: string = 'lg:col-span-1';

  get stateId() { return this.agencyRatingViewsSearch.get('stateId'); }
  get agencyId() { return this.agencyRatingViewsSearch.get('agencyId'); }
  get ageGradeInstrumentIds() { return this.agencyRatingViewsSearch.get('ageGradeInstrumentIds'); }
  get isCustomView() { return this.agencyRatingViewsSearch.get('isCustomView'); }
  public get selectType() { return SelectType; }

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private reportService: ReportService) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.agencyRatingViewsSearch = this.fb.group({
      stateId: [this.authService.getUserStateId(), Validators.required],
      agencyId: [this.user.agencyId ?? null],
      ageGradeInstrumentIds: [null, Validators.required],
      isCustomView: [null, Validators.required]
    });
  }

  handleDownload(): void {
    let payload: IAgencyRatingReviewsRequest = this.agencyRatingViewsSearch.value;
    this.reportService.getAgencyRatingViews(payload).pipe(take(1)).subscribe((res: any) => {
      const fileName = 'AgencyRatingViews.xlsx';
      const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    });
  }

  handleDropdown(event: any, type: string): void {
    switch (type) {
      case SelectType.States:
        this.stateId.setValue(event?.id);
        this.agencyId.setValue(null);
        break;
      case SelectType.Agencies:
        this.agencyId.setValue(event?.id);
        break;
      case SelectType.AgeInstrument:
        let ageGradeInstrumentIds = event?.map((ageInstrument: IAgeGradeInstrument) => ageInstrument.id);
        this.ageGradeInstrumentIds.setValue(ageGradeInstrumentIds.toString());
        break;
      case SelectType.YesNo:
        this.isCustomView.setValue(event?.id);
        break;
    }
  }

  handleGlobal(): void {
    this.allAgencies = !this.allAgencies;
    this.btnClass = this.allAgencies ? 'lg:col-span-2 items-end' : 'lg:col-span-1';
    this.agencyId.setValue(this.allAgencies ? null : this.user.agencyId ?? null);
  }

}
