import { Component, OnInit, ViewChild } from '@angular/core';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { AuthService } from '@core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { take, tap } from 'rxjs';
import { IUser } from '@core/interfaces/iuser';
import { UserService } from '@core/services/user.service';
import { ILoginResponse } from '@core/interfaces/ilogin-response';

@Component({
  selector: 'drdp-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  user?: ILoginResponse;
  userInfo? : IUser; 

  constructor(
    private authService: AuthService,
    private userService: UserService,
    public modal: MatDialog
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getCurrentUser();
    this.userService.getUserById(this.user.userId).pipe(
      take(1),
      tap((res: IUser) => {
        this.userInfo = res;
      })
    ).subscribe();
  }
  
}
