import { FormGroup } from "@angular/forms";

export function matchPassword(newPassword: string, confirmPassword: string) {
    return (resetPwForm: FormGroup) => {
        const password = resetPwForm.controls[newPassword];
        const confirmedPassword = resetPwForm.controls[confirmPassword];
        if (password.value !== confirmedPassword.value) {
        return confirmedPassword.setErrors({ passwordMismatch: true });
        } else {
          return confirmedPassword.setErrors(null);
        }
      }
}