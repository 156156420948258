import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { IEthnicity } from '@core/interfaces/iethnicity';
import { SelectType } from '@core/enums/select';
import { LookupService } from '@core/services/lookup.service';
import { PermissionService } from '@core/services/permission.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-select-race',
  templateUrl: './select-race.component.html',
  styleUrls: ['./select-race.component.scss']
})
export class SelectRaceComponent implements OnInit {
  @ViewChild('raceSelect') raceSelect: FilterSelectComponent | undefined;
  @Output() race = new EventEmitter<any>();
  @Input() initialRaceId?: number | null;
  @Input() initialRaceIds?: number[] | null;
  @Input() stateId?: number | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() multiSelectInitVals: boolean = false;
  @Input() isValid = true;
  @Input() required = false;
  @Input() label = 'Race';
  @Input() reloadRace = false;
  raceOptions?: IEthnicity[] = [];
  public get select() {
    return SelectType;
  }

  constructor(
    private lookupService: LookupService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      if (this.multiSelect) {
        this.raceSelect?.clearAll();
      } else {
        this.raceSelect?.clearSearch(true);
      }
    }

    if (this.reloadRace) {
      if (this.stateId) {
        this.getRace(this.stateId);
      }
    }

    if (changes['stateId']) {
      if (this.stateId) {
        this.raceSelect?.clearSearch(true);
        this.getRace(this.stateId);
      }
      else
        this.raceOptions = [];

    }

    if (changes['initialRaceId'] && this.initialRaceId) {
      this.initialRaceIds = [];
      this.initialRaceIds.push(this.initialRaceId);
    }
  }

  clearSelected() {
    this.raceSelect?.clearSearch(true);
  }

  getRace(stateId: number): void {

    this.lookupService.getEthnicities(stateId).pipe(take(1)).subscribe((race: IEthnicity[]) => {
      this.raceOptions = race
      if (this.initialRaceIds) {
        const selected = this.raceOptions!.filter((race: IEthnicity) =>
          this.initialRaceIds?.includes(race.id)
        );
        this.race.emit(selected);
      }
    });
  }

  onRaceSelect(event: IEthnicity): void {
    this.race.emit(event);
  }
}
