import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@core/services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { ISites } from '@core/interfaces/isites';
import { IAgency } from '@core/interfaces/iagency';
import { IRatingPeriod, ISchoolYear } from '@core/interfaces/iratingperiod';
import { IClassroom } from '@core/interfaces/iclasses';
import { SelectType } from '@core/enums/select';
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';

@Component({
  selector: 'drdp-enroll-dropped-children',
  templateUrl: './enroll-dropped-children.component.html',
  styleUrls: ['./enroll-dropped-children.component.scss']
})
export class EnrollDroppedChildrenComponent implements OnInit {
  private subscriptions = new Subscription();
  
  user = this.auth.getCurrentUser();
  enrollForm: FormGroup | any;
  agencyList: IAgency[] = [];
  siteList: ISites [] = [];
  classroomList: IClassroom[] = [];
  ratingPeriodList: IRatingPeriod[] = [];
  select = SelectType;

  getAgencyAndSiteData$: Observable<any> | undefined;
  getClassroomData$: Observable<any> | undefined;
  updateSiteClassroomSelections$: Observable<any> | undefined;

  get ratingPeriodId() { return this.enrollForm.get('ratingPeriodId') }; 
  get classroomId() { return this.enrollForm.get('classroomId') };

  constructor(
    private auth: AuthService,
    private studentClassEnrollment: StudentClassEnrollmentService,
    public dialogRef: MatDialogRef<EnrollDroppedChildrenComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public child: any
  ) { 
    dialogRef.disableClose = true 
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.enrollForm = this.fb.group({
      drdpId: [this.child.data.drdpId],
      classroomId: [null, [Validators.required]],
      ratingPeriodId: [null, [Validators.required]]
    });
  }

  handleDropdownSelection(event: any): void {
    this.classroomId.setValue(event.classroomId);
    this.ratingPeriodId.setValue(event.ratingPeriodId);
  }

  submit(): void {
    const payload = this.enrollForm.value;
    this.studentClassEnrollment.enrollDroppedChild(payload, 'Child has been enrolled').subscribe((success: boolean) => {
      if (success) {
        this.dialogRef.close(true);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
