import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageStatus } from '@core/enums/message-status';
import { Permission } from '@core/enums/permissions';
import { IPortfolioMessage, IPortfolioMessageResponse } from '@core/interfaces/iportfolio-message';
import { PermissionService } from '@core/services/permission.service';

@Component({
  selector: 'drdp-message-board-tile',
  templateUrl: './message-board-tile.component.html',
  styleUrls: ['./message-board-tile.component.scss']
})
export class MessageBoardTileComponent implements OnInit {
  @Input() message!: IPortfolioMessage;
  @Input() senderName = '';
  @Input() isArchived = false;
  @Output() archiveMessage = new EventEmitter();
  public get MessageStatus() { return MessageStatus }

  constructor() {}

  ngOnInit(): void {}

  emitArchiveMessage() {
    this.archiveMessage.emit(this.message);
  }
}
