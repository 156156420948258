import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Permission } from '@core/enums/permissions';
import { SelectType } from '@core/enums/select';
import { IAgency } from '@core/interfaces/iagency';
import { AgencyService } from '@core/services/agency.service';
import { AuthService } from '@core/services/auth.service';
import { PermissionService } from '@core/services/permission.service';
import { BehaviorSubject, Subject, filter, switchMap, take, takeUntil, tap } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';

@Component({
  selector: 'drdp-select-agency',
  templateUrl: './select-agency.component.html',
  styleUrls: ['./select-agency.component.scss'],
})
export class SelectAgencyComponent implements OnInit, OnChanges {
  private destroy$ = new Subject<void>();
  private stateId$ = new BehaviorSubject<number>(this.stateId!);

  @ViewChild('agencySelect') agencySelect: FilterSelectComponent | undefined;
  @Output() agency = new EventEmitter<any>();
  @Input() initialAgencyId?: number | null;
  @Input() initialAgencyIds?: number | null;
  @Input() multiSelectInitVals: boolean = false;
  @Input() stateId?: number | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() isEditDropdown: boolean = false;
  @Input() isPilot: boolean = false;
  agencyOptions?: IAgency[] = [];
  selectedState?: number;
  selectedAgency?: number;
  hasAccessToAll = this.permissionService.checkPermission(
    Permission.AccessAllAgencies
  );
  hasAccessToAssigned = this.permissionService.checkPermission(
    Permission.AccessAssignedStateAgencies
  );
  public get select() {
    return SelectType;
  }
  constructor(
    private agencyService: AgencyService,
    private permissionService: PermissionService,
    private authService: AuthService
  ) {
    this.stateId$
    .pipe(
      filter((stateId) => !!stateId),
      switchMap((stateId) => {
        return this.agencyService.getAgenciesByState(stateId, this.isPilot);
      }),
      tap((_) => {
        if (this.hasAccessToAssigned) {
          if (this.isEditDropdown) this.disabled = false;
        }
      }),
      takeUntil(this.destroy$)
    )
    .subscribe((agencies: IAgency[]) => {
      this.agencyOptions = agencies;
      this.disabled = (this.agencyOptions.length <= 1 && !this.selectedAgency && this.hasAccessToAssigned) && !this.multiSelect; //disable if there's no option or only one option
      this.handleStateChange();
    });
  }

  ngOnInit(): void {
    if (!this.hasAccessToAll && !this.hasAccessToAssigned) {
      const user = this.authService.getCurrentUser();
      this.getLinkedAgencies(user.userId);
    }
  }

  getAgencyByState(stateId: number) {
    this.stateId$.next(stateId);
  }

  clearSelected() {
    if (!this.disabled) this.agencySelect?.clearSelected();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear && !this.disabled) {
      this.agencySelect?.clearSearch(true);
    }

    // if (this.hasAccessToAll || this.hasAccessToAssigned) {
    if (changes['stateId']) {
      this.getAgencyOptions(changes);
    }
  }

  refreshList() {
    if (this.stateId) this.getAgencyByState(this.stateId);
  }

  getAllAgencies() {
    this.agencyService
      .getAllAgencies()
      .pipe(
        take(1),
        tap((_) => {
          if (this.hasAccessToAssigned) {
            if (this.isEditDropdown) this.disabled = false;
          }
        })
      )
      .subscribe((agencies: IAgency[]) => {
        this.agencyOptions = agencies;
        this.disabled = (this.agencyOptions.length <= 1 && !this.selectedAgency && this.hasAccessToAssigned) && !this.multiSelect; //disable if there's no option or only one option
      });
  }

  getLinkedAgencies(userId: number): void {
    this.agencyService
      .getLinkedAgencies(userId)
      .pipe(take(1))
      .subscribe((agencies: IAgency[]) => {
        this.agencyOptions = agencies;
        this.disabled = (this.agencyOptions.length <= 1 && !this.selectedAgency) && !this.multiSelect; //disable if there's no option or only one option
      });
  }

  getAgencyOptions(changes: SimpleChanges): void {
    if (
      (changes['stateId'] ||
        changes['initialAgencyId'] ||
        changes['initialAgencyIds']) &&
      this.stateId &&
      (this.hasAccessToAssigned || this.hasAccessToAll)
    ) {
      this.getAgencyByState(this.stateId);
    } else if (this.stateId == 0 && this.hasAccessToAll) {
      this.getAllAgencies();
    } else {
      this.agencyOptions = [];
      this.disabled = true;
    }
  }

  handleStateChange(): void {
    const agency = this.agencyOptions?.find(
      (a: IAgency) => a.id == this.initialAgencyId
    );
    if (agency !== null) {
      this.agency.emit(agency);
    }
  }

  onAgencySelect(event: IAgency): void {
    this.agency.emit(event);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
