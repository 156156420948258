<div class="rounded-lg fix-action-row modal-body">
  <div class="flex justify-between border-b pb-5">
    <h1 class="text-[25px] font-bold mr-8">{{title}}</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>
  <div mat-dialog-content>
    <form class="grid grid-cols-2 gap-4 gap-x-10" [formGroup]="targetForm" (ngSubmit)="onSubmit()">
      <div class="col-span-2 items-center w-[100%]">
        <div class="col-span-1 items-center w-[50%]">
          <drdp-select-school-year label="School Year" [initialYearId]="schoolYearId.value"
           [editConfigs]=true (schoolYear)="onDropDownSelect($event)"></drdp-select-school-year>
        </div>
      </div>
      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal required"for="7a1">7A1</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput id="7a1" type="number" formControlName="indicator7a1">
        </mat-form-field>
      </div>
      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal required"for="7a2">7A2</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput id="7a2" type="number" formControlName="indicator7a2">
        </mat-form-field>
      </div>
      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal required"for="7b1">7B1</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput id="7b1" type="number" formControlName="indicator7b1">
        </mat-form-field>
      </div>
      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal required"for="7b2">7B2</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput id="7b2" type="number" formControlName="indicator7b2">
        </mat-form-field>
      </div>
      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal required"for="7c1">7C1</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput id="7c1" type="number" formControlName="indicator7c1">
        </mat-form-field>
      </div>
      <div class="col-span-1 items-center w-[100%]">
        <label class="font-normal required"for="7c2">7C2</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput id="7c2" type="number" formControlName="indicator7c2">
        </mat-form-field>
      </div>
      <mat-error *ngIf="invalidDecimal" class="text-sm mt-3">Invalid decimal value.</mat-error>
    </form>
  </div>
  <div class="spacer"></div>
  <div mat-dialog-actions class="flex justify-end align-bottom">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <drdp-btn [disabled]="targetForm.invalid" mat-button type="submit" (click)="onSubmit()">Save</drdp-btn> 
  </div>
</div>

