<div class="h-full flex flex-col justify-between">
  <drdp-filter-select
    #reportType
    [label]="label"
    [multiSelect]="true"
    [options]="reportTypeOptions"
    [selectType]="select.ReportType"
    [required]="required"
    [disabled]="disabled"
    [isValid]="isValid"
    (onSelected)="emitSelectedReportType($event)">
  </drdp-filter-select>
</div>
