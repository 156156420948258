import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { formatDatesToString } from '@core/services/helper.service';
import { CustomValidators } from '@core/validators/custom.validator';

@Component({
  selector: 'drdp-add-edit-rating-period',
  templateUrl: './add-edit-rating-period.component.html',
  styleUrls: ['./add-edit-rating-period.component.scss'],
  providers: [RatingPeriodService],
})
export class AddEditRatingPeriodComponent implements OnInit {
  ratingPeriodForm: FormGroup | any;
  minStart: Date;
  maxEnd: Date;

  constructor(
    private ratingPeriod: RatingPeriodService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.minStart = new Date(this.data.data.globalRatingPeriod.minStartDate);
    this.maxEnd = new Date(this.data.data.globalRatingPeriod.maxEndDate);
  }

  get StartDate() {
    return this.ratingPeriodForm.get('StartDate');
  }
  get EndDate() {
    return this.ratingPeriodForm.get('EndDate');
  }

  ngOnInit(): void {
    this.initializeForms();
  }

  dateChange(event: any) {
    this.minStart =
      event.targetElement.name == 'minDatePicker' ? event.value : this.minStart;
    this.maxEnd =
      event.targetElement.name == 'maxDatePicker' ? event.value : this.maxEnd;
  }

  initializeForms(): void {
    this.ratingPeriodForm = this.fb.group(
      {
        StartDate: [null, [Validators.required]],
        EndDate: [null, [Validators.required]],
      },
      {
        validators: CustomValidators.dateValidator('StartDate', 'EndDate'),
      }
    );

    if (this.data) {
      const edit = this.data.data;
      this.StartDate.setValue(edit.startDate);
      this.EndDate.setValue(edit.endDate);
    }
  }

  handleDate(event: any, controlName: string): void {
    if (event === '' || !event) {
      this.ratingPeriodForm
        .get(controlName)
        .setValue(formatDatesToString(null));
    } else {
      this.ratingPeriodForm
        .get(controlName)
        .setValue(formatDatesToString(event));
      if (controlName === 'StartDate') this.minStart = event;
      else this.maxEnd = event;
    }
  }

  onSubmit() {
    let payload = this.ratingPeriodForm.value;

    if (this.data) {
      payload.id = this.data.data.id;
      payload.agencyId = this.data.data.agencyId;
      payload.ratingPeriodName = this.data.data.ratingPeriodName;
      payload.ratingPeriodDescription = this.data.data.ratingPeriodDescription;
      payload.schoolYearId = this.data.data.schoolYearId;
      payload.globalRatingPeriodId = this.data.data.globalRatingPeriodId;
      payload.isActive = this.data.data.isActive;
    }

    let updateTableView: IRatingPeriod = this.ratingPeriodForm.value;
    return {
      formValid: true,
      payload: payload,
      updateTableView: updateTableView,
    };
  }
}
