import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IPortfolioStudentInformation } from '@core/interfaces/iportfolio';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-future-evidence-student',
  templateUrl: './future-evidence-student.component.html',
  styleUrls: ['./future-evidence-student.component.css']
})
export class FutureEvidenceStudentComponent implements OnInit {
  studentList: IPortfolioStudentInformation[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private dialogRef: MatDialogRef<FutureEvidenceStudentComponent>) { }

  ngOnInit() {
    this.studentList = this.data.studentList;
  }

  redirectToEvidence(student: IPortfolioStudentInformation) {
    this.router.navigate([
      `portfolio/review-evidence/future-evidence/${student.futureEvidenceId}/student/${student.studentId}/class/${student.classId}/age-grade-instrument/${student.ageGradeInstrumentId}`,
    ]);
    this.dialogRef.close();
  }

  goToNextPage() {
    this.dialogRef.close(true);
  }

}
