import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'drdp-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements OnInit {
    @Input() url!: string | ArrayBuffer | SafeResourceUrl;

  constructor() { }

  ngOnInit() {
  }
}
