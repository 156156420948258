import { Injectable } from '@angular/core';
import { IRoles } from '@core/interfaces/iroles';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { getSearchUrl } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private data: DataService) {}

  getAllByOrder(): Observable<any> {
    return this.data.get('role/allbyorder');
  }

  getAllRoles(): Observable<any> {
    return this.data.get('role/all');
  }

  getRolesByUserAccess(): Observable<any> {
    return this.data.get('role/user-access');
  }

  getRoles(pageIndex: number, itemsPerPage: number): Observable<any> {
    return this.data.get(
      `role/search?PageIndex=${pageIndex}&ItemsPerPage=${itemsPerPage}`
    );
  }

  searchRoles(payload: any): Observable<any> {
    const url = getSearchUrl('role/search?', payload);
    return this.data.get(url);
  }

  getRoleGroups(): Observable<any> {
    return this.data.get(`role/all-role-groups`);
  }

  deleteRole(id: number, message?: string | any) {
    return this.data.delete(`role?id=${id}`, message);
  }
  updateRole(payload: IRoles, message?: string): Observable<boolean> {
    return this.data.put('role', payload, message);
  }
  addRole(payload: IRoles, message?: string): Observable<boolean> {
    return this.data.post('role', payload, message);
  }

  filterRoles(ids: number[], message?: string): Observable<IRoles[]> {
    let buildQuery: string = '';

    for (let id of ids) {
      if (buildQuery) {
        buildQuery += `&ids=${id}`;
      } else {
        buildQuery += `ids=${id}`;
      }
    }

    return this.data.get(`role/filter?${buildQuery}`);
  }

  downloadRolesReport(roles: IRoles[]): Observable<any> {
    return this.data.postWithOptions('role/download', roles, {
      observe: 'response',
      responseType: 'blob',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  }
}
