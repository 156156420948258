import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { SelectType } from '@core/enums/select';
import { ClassService } from '@core/services/class.service';
import { take } from 'rxjs';
import { mapClassResToDRDPSelect } from '@core/services/helper.service';
import { IClassSearchRequest, IClassroomAssignment } from '@core/interfaces/iclasses';

@Component({
  selector: 'drdp-select-class',
  templateUrl: './select-class.component.html',
  styleUrls: ['./select-class.component.scss'],
})
export class SelectClassComponent implements OnInit {
  @ViewChild('classroomSelect') classroomSelect:
    | FilterSelectComponent
    | undefined;
  @Output() classroom = new EventEmitter<any>();
  @Input() initialClassroomIds?: number[] | null;
  @Input() siteIds?: number[] | null;
  @Input() ratingPeriodId?: number | null;
  @Input() globalRatingPeriodId?: number | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = true;
  @Input() multiSelectInitVals: boolean = true;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() label: string = 'Classroom';
  @Input() useClassroomId?: boolean = false;
  @Input() showPilotClasses?: boolean =  false;
  classroomOptions: any[] = [];
  public get select() {
    return SelectType;
  }
  constructor(private classService: ClassService) {}

  ngOnInit(): void {
    if (this.siteIds && this.siteIds.length > 0 && (this.ratingPeriodId || this.globalRatingPeriodId)) 
      this.getClasses(this.siteIds);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      if (this.multiSelect) {
        this.classroomSelect?.clearAll();
      } else {
        this.classroomSelect?.clearSelected();
      }
    }

    if (changes['siteIds'] && this.siteIds) {
      this.siteIds = Array.isArray(this.siteIds)
        ? this.siteIds
        : [Number(this.siteIds)];
    }

    if (this.siteIds && this.siteIds.length > 0 && (this.ratingPeriodId || this.globalRatingPeriodId)) {
      this.getClasses(this.siteIds);
    } else {
      this.classroomOptions = [];
    }
  }

  clearSelected() {
    if (!this.disabled) this.classroomSelect?.clearSelected();
  }

  getClasses(sites: number[]): void {
  const classesObservable = this.globalRatingPeriodId 
    ? this.classService.getClassesBySitesAndGlobalRatingPeriods(this.createClassSearchPayload(true)) 
    : this.classService.getClassesBySitesAndRatingPeriod(this.createClassSearchPayload());

  classesObservable.pipe(take(1)).subscribe((res: any) => {
    if (this.globalRatingPeriodId) {
      res = res.filter(
        (value: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.classroomId === value.classroomId) 
      );
    };
    res = this.showPilotClasses ? res.filter((c: IClassroomAssignment) => c.isPilot) : res;
    this.classroomOptions = mapClassResToDRDPSelect(res, this.useClassroomId);
  });
  }

  createClassSearchPayload(globalRatingPeriod: boolean = false): IClassSearchRequest {
    const requestPayload: IClassSearchRequest = {
      siteIds: this.siteIds ?? null,
      itemsPerPage: 10,
    };
    if (globalRatingPeriod) requestPayload.globalRatingPeriodId = this.globalRatingPeriodId ?? null;
    else requestPayload.ratingPeriodId = this.ratingPeriodId ?? null;
    return requestPayload;
  }

  onClassroomSelect(event: number[]): void {
    this.classroom.emit(event);
  }
}
