import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { LockSettingService } from '@core/services/lock-setting.service';
import { AddEditLockingStatusComponent } from './add-edit-locking-status/add-edit-locking-status.component';
import { ToastService } from '@core/services/toast.service';
import { ILockingStatus } from '@core/interfaces/ilockingstatus';
import { AnySchema } from 'ajv';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { IModalEmitData } from '@core/interfaces/imodal';

@Component({
  selector: 'drdp-locking-status',
  templateUrl: './locking-status.component.html',
  styleUrls: ['./locking-status.component.scss']
})
export class LockingStatusComponent implements OnInit {
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  isSuperUser: boolean = true;
  tableData: any[] = [];
  dataSource: MatTableDataSource<AnySchema> = new MatTableDataSource();
  formTableColumns = [
    { columnDef: 'lockType', header: 'Lock Type', type: 'text'},
    { columnDef: 'lockDate', header: 'Lock Date', type: 'date'},
    { columnDef: 'isActive', header: 'Active', type: 'text'}
  ]

  constructor(private lockSetting : LockSettingService,
              public modal: MatDialog,
              public toast: ToastService) { }

  ngOnInit(): void {
    if (this.isSuperUser) {
      this.formTableColumns.push({ columnDef: 'edit', header: 'Edit', type: 'edit' });
    }
    this.lockSetting.getAllLockSettings().subscribe((res) => {
      res = res.map((s : any) => ({...s, isActive: s.active ? 'Yes' : 'No'}));
      this.dataSource.data = res;
      this.tableData = res;
    })
  }

  openModal(data?: IModalEmitData): void {
    const modalRef = this.modal.open(AddEditLockingStatusComponent, {data: data ? data.data : undefined});

    modalRef.afterClosed().subscribe((res: any) => {
      if (data && res.formValid) {
        this.lockSetting.updateLockSetting(res.payload).subscribe((success) => {
          if (success) {
            this.tableData[data.index] = res.updateTableView;
            this.tableData[data.index].isActive = this.tableData[data.index].active ? 'Yes' : 'No';
            this.table?.reRender(this.tableData);
            this.toast.success("Lock Status updated");
          } else {
            this.toast.success("Could not update Lock Status");
          }
        })
      }
    })
  }

}
