import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'drdp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(){}

  ngOnInit(): void {
    
  }
}
