export enum AgeGradeInstrument {
  infantToddler = 1,
  preschool = 2,
  kindergarten = 4,
  schoolAge = 5,
  tnKindergarten = 6,
  nhInfantToddler = 7,
  transitionalKinder = 10,
  pilotInfantToddler = 11,
  pilotPreschool = 12,
  pilotPreschool3 = 13,
}

export enum AgeGradeInstrumentCode {
  infantToddler = 'IT',
  preschool = 'PS',
  kindergarten = 'K',
  schoolAge = 'SA',
  transitionalKinder = 'TK',
  pilotPreschool = 'PTK',
  pilotPreschool3 = 'PTK-3',
}
