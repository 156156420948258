<div class="h-full flex flex-col justify-between">
    <drdp-filter-select
      #requestStatusType
      [label]="label"
      [multiSelect]="false"
      [options]="requestStatusTypeOptions"
      [selectType]="select.RequestStatusType"
      [required]="false"
      [disabled]="disabled"
      [isValid]="isValid"
      (onSelected)="emitSelectedRequestStatusType($event)"
      [initialValues]="initialRequestStatusTypeId">
    </drdp-filter-select>
  </div>
