import { Injectable } from '@angular/core';
import { getSearchUrl } from './helper.service';
import { DataService } from './data.service';
import { IPortfolioConversationRequestDto, IPortfolioMessage, IPortfolioMessageRecipient, IPortfolioMessageResponse, IPortfolioMessageSearchRequest } from '@core/interfaces/iportfolio-message';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PortfolioMessageService {
  constructor(private dataService: DataService) { }

  getPortfolioMessagesByUserId(payload: IPortfolioMessageSearchRequest, message?: any) : Observable<IPortfolioMessageResponse[]>{
     const url = getSearchUrl('portfolioMessage/userId?', payload);
    return this.dataService.get(url, message);
  }

  searchPortfolioMessages(payload: IPortfolioMessageSearchRequest, message?: any): Observable<IPortfolioMessageResponse[]> {
    const url = getSearchUrl('portfolioMessage/search?', payload);
    return this.dataService.get(url, message);
  }

  addNewMessage(payload: IPortfolioMessage, message?: string) : Observable<boolean> {
    return this.dataService.post('portfolioMessage/new-message', payload, message);
  }

  getPortfolioConversation(payload: IPortfolioConversationRequestDto, message?: string) : Observable<IPortfolioMessage[]> {
    return this.dataService.post('portfolioMessage/conversation', payload, message);
  }

  archivePortfolioMessage(payload: IPortfolioMessageRecipient, message?: string) : Observable<boolean> {
    return this.dataService.put(`PortfolioMessageRecipient/archive`, payload, message);
  }

  updateReadPortfolioMessages(payload: IPortfolioConversationRequestDto, message?: string) : Observable<boolean> {
    return this.dataService.put(`portfolioMessageRecipient/update-read`, payload, message);
  }

}
