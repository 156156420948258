<div *ngIf="enrollForm">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">Add Child</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>
  <div mat-dialog-content>
    <div class="grid grid-cols-2 gap-4 gap-x-10 mt-5">
      <div class="mb-5">
        <h3 class="font-bold text-[18px] mb-2">First Name</h3>
        <p>{{ child.data.firstName }}</p>
      </div>
      <div class="mb-5">
        <h3 class="font-bold text-[18px] mb-2">Last Name</h3>
        <p>{{ child.data.lastName }}</p>
      </div>
      <div class="mb-5">
        <h3 class="font-bold text-[18px] mb-2">Date of Birth</h3>
        <p>{{ child.data.dob | date: 'MM/dd/yyyy' }}</p>
      </div>
      <div class="mb-5">
        <h3 class="font-bold text-[18px] mb-2">DRDP ID</h3>
        <p>{{ child.data.drdpId }}</p>
      </div>
      <drdp-classroom-rating-period-params (formValues)="handleDropdownSelection($event)" class="contents">
      </drdp-classroom-rating-period-params>
    </div>
  </div>
  <div class="flex flex-row justify-end">
    <drdp-btn mat-button mat-dialog-close [color]="'cancel'">Cancel</drdp-btn>
    <drdp-btn [disabled]="!enrollForm.valid" class="ml-3" (click)="submit()">Save and Submit</drdp-btn>
  </div>
</div>