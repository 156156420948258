<div class="h-full flex flex-col justify-between">
  <drdp-filter-select
    #siteSelect
    [label]="label"
    [multiSelect]="multiSelect"
    [options]="siteOptions"
    [selectType]="select.Sites"
    [required]="required"
    [isValid]="isValid"
    (onSelected)="onSiteSelect($event)"
    [initialValues]="initialSiteId || initialSiteIds"
  >
  </drdp-filter-select>
</div>
