import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SelectType } from '@core/enums/select';
import { ISelpa } from '@core/interfaces/iselpa';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';

@Component({
  selector: 'drdp-select-selpa',
  templateUrl: './select-selpa.component.html',
  styleUrls: ['./select-selpa.component.scss'],
})
export class SelectSelpaComponent implements OnInit {
  @ViewChild('selpaSelect') selpaSelect: FilterSelectComponent | undefined;
  @Output() selpa = new EventEmitter<any>();
  @Input() initialSelpaId?: number | null;
  @Input() multiSelectInitVals: boolean = false;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() additionalClasses: string = 'pb-11';
  selpaOptions?: ISelpa[] = [];
  public get select() {
    return SelectType;
  }
  constructor(private lookupService: LookupService) {}

  ngOnInit(): void {
    this.getSelpas();
  }

  getSelpas(): void {
    this.lookupService
      .getSelpas()
      .pipe(take(1))
      .subscribe((selpas: ISelpa[]) => {
        this.selpaOptions = selpas;
      });
  }

  onSelpaSelect(event: ISelpa): void {
    this.selpa.emit(event);
  }
}
