import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FeaturesComponent } from './features/features.component'
import { UserRolesComponent } from './user-roles/user-roles.component'
import { PermissionsComponent } from './permissions/permissions.component'
import { PermissionsManagmentComponent } from './permissions/permissions-management/permissions-management.component'
import { AccountsRoutingModule } from './accounts-routing.module'
import { SharedModule } from '@shared/shared.module'
import { FormsModule,ReactiveFormsModule } from '@angular/forms'
import { CoreModule } from '@core/core.module'
import { AddEditRolesComponent } from './user-roles/add-edit-roles/add-edit-roles.component'
import { DeleteRolesComponent } from './user-roles/delete-roles/delete-roles.component'
import { FeaturesTabsComponent } from './features/features-tabs-add-edit/features-tabs.component'
import { LoginAuditsComponent } from './login-audits/login-audits.component'
import { PermissionsFilterComponent } from './permissions/permissions-filter/permissions-filter.component';
import { UserAccountComponent } from './user-account/user-account.component';
import { AddEditUserAccountsComponent } from './user-account/add-edit-user-accounts/add-edit-user-accounts.component';
import { ViewUserComponent } from './user-account/view-user/view-user.component';
import { FeaturesAddEditComponent } from './features/features-add-edit/features-add-edit.component';
import { SuperFeaturesEditComponent } from './features/super-features-edit/super-features-edit.component';
import { SuperFeaturesAddComponent } from './features/super-features-add/super-features-add.component';
import { GlobalAdminComponent } from './global-admin/global-admin.component';
import { EditUserComponent } from './global-admin/edit-user/edit-user.component'
import { PipesModule } from '@core/pipes/pipes.module';
import { ResetPasswordComponent } from './global-admin/reset-password/reset-password.component'

@NgModule({
  declarations: [
    FeaturesComponent,
    UserRolesComponent,
    PermissionsComponent,
    PermissionsManagmentComponent,
    AddEditRolesComponent,
    DeleteRolesComponent,
    PermissionsFilterComponent,
    LoginAuditsComponent,
    FeaturesTabsComponent,
    UserAccountComponent,
    AddEditUserAccountsComponent,
    ViewUserComponent,
    FeaturesAddEditComponent,
    SuperFeaturesEditComponent,
    SuperFeaturesAddComponent,
    GlobalAdminComponent,
    EditUserComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    AccountsRoutingModule,
    SharedModule,
    FormsModule,
    CoreModule,
    ReactiveFormsModule,
    PipesModule.forRoot()
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccountsModule {}
