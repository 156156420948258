<div class="h-full flex flex-col justify-between">
  <drdp-filter-select
    #initialAssessmentToolTypeSelect
    [label]="label"
    [multiSelect]="multiSelect"
    [options]="selectOptions"
    [selectType]="select.InitialAssessment"
    [required]="required"
    [disabled]="disabled"
    (onSelected)="onSelect($event)"
    [isValid]="isValid"
    overrideLabel="initial assessment tool type"
    [initialValues]="initialId || initialIds"
    [additionalClasses]="additionalClasses"
    [tooltip]="tooltip">
  </drdp-filter-select>
</div>
