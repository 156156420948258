export enum DataType {
    number = 'Number',
    text = 'Text'
}

export enum FieldType {
    text = 2,
    number = 3,
    date = 4,
    dropdownSingle = 5,
    dropdownMultiple = 6
}

export enum RegionType {
    Region = 'Region',
    SAU = 'SAU'
}