import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoginAuditService {
  constructor(private dataService: DataService){}

  search(request: any, message?: string): Observable<any> {
    return this.dataService.post('auth/audit', request, message);
  }

  downloadFile(payload:any, message?: string): Observable<any> {
    return this.dataService.postWithOptions('auth/search/download', payload, {
      observe: 'response',
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
      }
    })
  }
}
