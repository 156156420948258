import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
  })
  export class LockSettingService {

    constructor(private data: DataService) { }

    getAllLockSettings(): Observable<any> {
        return this.data.get(`lockSetting/all`);
    }

    updateLockSetting(lockSetting: any, message?: string): Observable<boolean> {
        return this.data.put(`locksetting`, lockSetting, message);
    }
  }