export enum MeasureCondition {
  Conditional = 1,
  Emerging = 2,
  NotYet = 3,
  Unratable = 4,
}

export enum MeasureConditionName {
  Conditional = 'Conditional',
  Emerging = 'Emerging',
  NotYet = 'Not Yet',
  Unratable = 'Unratable',
}
