export enum SelectType {
  States = 'stateName',
  Agencies = 'agencyName',
  Grantee = 'granteeName',
  Sites = 'siteName',
  RatingPeriods = 'ratingPeriodName',
  GlobalRatingPeriods = 'globalRatingPeriodName',
  AgeInstrument = 'ageGradeInstrumentName',
  Class = 'classroomName',
  Roles = 'roleName',
  NewsItemTypes = 'newsItemTypeName',
  Boolean = 'boolean',
  Ethnicities = 'ethnicityName',
  SchoolYear = 'schoolYearName',
  Teacher = 'fullName',
  TeacherOfRecord = 'teacherOfRecord',
  County = 'countyName',
  Default = 'name',
  Suffix = 'suffixName',
  Status = 'statusTypeName',
  SiteType = 'siteTypeName',
  CodeType = 'siteCodeTypeName',
  Vendor = 'name',
  Region = 'name',
  HeadStart = 'name',
  VendorRef = 'vendorName',
  RegionRef = 'regionName',
  HeadStartRef = 'headStartName',
  HeadStartGranteeRef = 'headStartGranteeName',
  Selpa = 'selpaName',
  UserRoleNames = 'userRoleNames',
  YesNo = 'name',
  IepPlan = 'iepPlanName',
  Gender = 'genderName',
  EnrollmentProgram = 'enrollmentProgramName',
  Language = 'languageName',
  PortfolioUser = 'userName',
  StudentRequest = 'name',
  RequestStatusType = 'statusTypeName',
  ReportType = 'reportTypeName',
  ReportAction = 'reportActionName',
  StatePreschool = 'statePreschool',
  StatePreschoolContractorRef = 'statePreschoolContractorName',
  ActiveEnrollmentClass = 'classInfo',
  Adaptation = 'adaptationName',
  Outcome = 'outcome',
  InitialAssessment = 'initialAssessmentToolType',
  Outcome1 = 'outcome1',
  Outcome2 = 'outcome2',
  Outcome3 = 'outcome3'
}
