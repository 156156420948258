import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadRequestService {
  constructor(private dataService: DataService) {}

  downloadDemographics(payload: any) {
    return this.dataService.postWithOptions(
      `downloadrequest/demographics`,
      payload,
      {
        responseType: 'blob',
      }
    );
  }

  saveRequestForRatings(payload: any) {
    return this.dataService.post(
      `downloadrequest/demographics-ratings`,
      payload
    );
  }
  
  saveRequestForRatings2025(payload: any) {
    return this.dataService.post(
      `downloadrequest/demographics-ratings-2025`,
      payload
    );
  }

  saveRequestForScores(payload: any) {
    return this.dataService.post(
      `downloadrequest/demographics-scores`,
      payload
    );
  }

  saveRequestForRatingsAndScore(payload: any) {
    return this.dataService.post(`downloadrequest/demographics-all`, payload);
  }

  saveRequestForSPHDRatingsAndScore(payload: any) {
    return this.dataService.post(`downloadrequest/demographics-all-sp-hd`, payload);
  }
}
